/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { looseMatch } from "@/modules/string";
import {
  aliases,
  ToleranceType,
  toleranceTypes,
} from "@centralwebteam/narwhal/lib";

export const toleranceTypeMapper = (
  toleranceType: string | undefined
): ToleranceType => {
  return (
    toleranceTypes.find((t) => looseMatch(toleranceType, t)) ??
    toleranceTypes.find((a) =>
      aliases[a].some((alias: string) => looseMatch(toleranceType, alias))
    ) ??
    "Unknown"
  );
};
