/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
/*
 * Thunks: https://redux.js.org/usage/writing-logic-thunks
 */
import i18n from "@/i18n";
import { ACResultAsync, ACResult } from "@/store";
import { actions } from "./actions";
import { getGlobalConfigs } from "@/index";
import {
  NotificationType,
  notificationTypes,
  uniqueID,
} from "@centralwebteam/jellyfish";

export function setNotification(
  header: string,
  body: string[],
  type: NotificationType = notificationTypes.error
): ACResultAsync {
  const config = getGlobalConfigs();
  return async function (dispatch, getState) {
    const id = uniqueID();
    dispatch(
      actions.setNotification({
        show: true,
        header,
        body,
        id,
        type,
      })
    );
    setTimeout(() => {
      const notification = getState().global.notification;
      if (notification.show && notification.id === id)
        dispatch(
          actions.setNotification({
            show: false,
            header: "",
            body: [],
            id: "",
            type,
          })
        );
    }, config.notificationTimeoutInMS);
  };
}

export function setNotificationOversizeData(): ACResult {
  let warning = "",
    largeData = "",
    reduceRange = "";
  i18n.then((t) => {
    warning = t("message-Performance warning");
    largeData = t("message-largeData");
    reduceRange = t("message-reduceTimeRange");
  });
  return function (dispatch) {
    dispatch(
      actions.setNotification({
        header: warning,
        body: [largeData, reduceRange],
        show: true,
        id: "OVERSIZE_DATA",
        type: "warning",
      })
    );
  };
}

export function dismissNotification(): ACResult {
  return function (dispatch) {
    dispatch(
      actions.setNotification({
        id: "",
        body: [],
        header: "",
        show: false,
        type: "error",
      })
    );
  };
}
