/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { select, put } from "typed-redux-saga";
import { selectFocusedMachine } from "@/store/selectors";
import { rootActions } from "..";
import { getMachines } from "../global/selectors";

export function* pickDefaultMachine() {
  const focusedMachine = yield* select(selectFocusedMachine);
  const machines = yield* select(getMachines);
  if (machines.length === 0) return;

  // Focused machine found
  if (focusedMachine && machines.find(({ id }) => id === focusedMachine.id))
    return;
  // No machines to use so we can't default to one.
  // Select default
  else yield* put(rootActions.session.focusedMachineIdUpdated(machines[0].id));
}
