/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { call, put, race, take, takeLatest } from "typed-redux-saga";
import { liveRefreshWatcher } from "./liveRefreshSaga";
import {
  allStaticData,
  seriesTypesStaticData,
  timeSeriesLimitsStaticData,
  measurementSeriesValuesStaticData,
  timeSeriesValuesStaticData,
} from "./staticSaga";
import { pickDefaultMachine } from "./pickDefaultMachineSaga";
import { getType } from "typesafe-actions";
import { rootActions } from "../rootActions";
import { getGlobalConfigs } from "@/index";
import { latestJobFlow } from "./latestJobFlow";
import {
  fetchFilesDetailsForFileInfoIds,
  downloadFileForSelectedFileId,
} from "../global/sagas";

export function* machineAnalysisRootSaga() {
  const config = getGlobalConfigs();
  yield* call(pickDefaultMachine);
  while (true) {
    try {
      yield* call(allStaticData);
      yield* race({
        latestJobFlow: call(latestJobFlow),
        liveRefreshWatcher: call(liveRefreshWatcher, {
          delayDuration: config.refreshRateLiveInMS,
        }),
        seriesStaticDataWatcher: takeLatest(
          [getType(rootActions.machineAnalysis.jobsListCheckboxClicked)],
          seriesTypesStaticData
        ),
        measurementTypesStaticValuesWatcher: takeLatest(
          [
            getType(
              rootActions.machineAnalysis.measurementTypeSeriesListItemClicked
            ),
            getType(
              rootActions.machineAnalysis.measurementTableMeasurementRowClicked
            ),
            getType(
              rootActions.machineAnalysis.multiJobTableMeasurementTypeClicked
            ),
            getType(rootActions.machineAnalysis.jobsListCheckboxClicked),
          ],
          measurementSeriesValuesStaticData
        ),
        timeSeriesStaticValueWatcher: takeLatest(
          [
            getType(
              rootActions.machineAnalysis.timeSeriesTypesSeriesListItemClicked
            ),
          ],
          timeSeriesValuesStaticData
        ),
        timeSeriesStaticLimitWatcher: takeLatest(
          [
            getType(
              rootActions.machineAnalysis.timeSeriesTypesSeriesListItemClicked
            ),
          ],
          timeSeriesLimitsStaticData
        ),
        jobSummaryPopupFilesTabClickedWatcher: takeLatest(
          getType(rootActions.global.jobSummaryPopupFilesTabClicked),
          fetchFilesDetailsForFileInfoIds
        ),
        downloadFileWatcher: takeLatest(
          getType(rootActions.global.jobSummaryPopupDownloadFileButtonClicked),
          downloadFileForSelectedFileId
        ),
        cancel: take([
          getType(rootActions.session.startEndUpdated),
          getType(rootActions.session.focusedMachineIdUpdated),
          getType(
            rootActions.machineAnalysis.machineSelectorSelectButtonClicked
          ),
        ]),
      });
    } catch (error) {
      console.warn(`Machine Analysis root saga: ${error}`);
    }
    // Clear time series data, we have new parameters
    yield* put(rootActions.machineAnalysis.dataInvalidated());
  }
}
