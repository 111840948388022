/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  getActiveNotClearedAlerts,
  getAlertsByLevel,
} from "@/presentation/Alert/functions";

import { createSelector } from "reselect";
import { RootState } from "..";
import { t } from "i18next";

export const makeSelectLatestCompletedJob = (machineId: string) =>
  createSelector(
    (state: RootState) => state.currentStatus.machineJobSummaries,
    (jobs) => {
      const machineJobs = jobs[machineId];
      return machineJobs
        ? machineJobs.find((job) => job.end !== undefined)
        : undefined;
    }
  );

export const makeSelectLatestJob = (machineId: string) =>
  createSelector(
    (state: RootState) => state.currentStatus.machineJobSummaries,
    (jobs) => {
      const machineJobs = jobs[machineId];
      return machineJobs ? machineJobs[0] : undefined;
    }
  );

export const makeSelectAlertsForDisplayByMachineId = (machineId: string) =>
  createSelector(
    (state: RootState) => state.currentStatus.machineAlerts,
    (state: RootState) => state.filter.active.alertLevel,
    (alerts, activeAlertLevels) =>
      getActiveNotClearedAlerts(
        getAlertsByLevel(alerts[machineId] || [], activeAlertLevels)
      )
  );

export const selectAlertsForActivePopup = createSelector(
  (state: RootState) => state.currentStatus.selectedMachineAlerts,
  (state: RootState) => state.currentStatus.machineAlerts,
  (state: RootState) => state.filter.active.alertLevel,
  (machineId, alerts, activeAlertLevels) =>
    machineId
      ? getActiveNotClearedAlerts(
          getAlertsByLevel(alerts[machineId] || [], activeAlertLevels)
        )
      : []
);

export const makeSelectSparklinesForDisplayByMachineId = (machineId: string) =>
  createSelector(
    (state: RootState) => state.currentStatus.machineCarouselData,
    (carouselData) =>
      carouselData[machineId]
        ?.slice()
        .sort((a, b) => a.sensor.name.localeCompare(b.sensor.name)) ?? []
  );

export const selectOpenMachineAlertLogPopup = createSelector(
  (state: RootState) => state.currentStatus.selectedMachineAlerts,
  (state: RootState) => state.global.machines,
  (machine, machines) =>
    machine ? machines.find((_machine) => _machine.id === machine) : undefined
);

export const selectIsMicroView = createSelector(
  (state: RootState) => state.currentStatus.viewMode,
  (viewMode) => viewMode === "MICRO"
);

export const selectMachinesToDisplay = createSelector(
  (state: RootState) => state.filter.active.machineType,
  (state: RootState) => state.filter.active.machineStatus,
  (state: RootState) => state.global.machines,
  (activeMachineTypes, activeMachineStatuses, machines) =>
    machines
      .filter((machine) =>
        activeMachineTypes.length === 0
          ? true
          : activeMachineTypes.includes(machine.type)
      )
      .filter((machine) =>
        activeMachineStatuses.length === 0
          ? true
          : machine.machineStatus &&
            activeMachineStatuses.includes(machine.machineStatus)
      )
);

export const selectLocationsToDisplay = createSelector(
  (state: RootState) => state.filter.active.locationId,
  (state: RootState) => state.global.locations,
  selectMachinesToDisplay,
  (activeLocationIds, locations, machines) => {
    const filteredLocationsByName = locations.filter((location) =>
      activeLocationIds.length === 0
        ? true
        : activeLocationIds.includes(location.id)
    );
    return filteredLocationsByName
      .map((location) => ({
        ...location,
        machines: machines.filter((m) =>
          location.name === t(`label-Unassigned Machines`)
            ? !m.locationId
            : m.locationId === location.id
        ),
      }))
      .filter((location) => {
        return location.machines.length > 0;
      });
  }
);

export const selectMachinesWithSparklines = createSelector(
  selectMachinesToDisplay,
  (state: RootState) => state.currentStatus.machineCarouselData,
  (machines, machineCarouselData) =>
    machines
      .filter(
        (machine) =>
          machineCarouselData[machine.id] &&
          machineCarouselData[machine.id]!.length > 0
      )
      .map(({ id }) => id)
);

export const selectMachinesWithAlerts = createSelector(
  selectMachinesToDisplay,
  (state: RootState) => state.currentStatus.machineAlerts,
  (state: RootState) => state.filter.active.alertLevel,
  (machines, alertData, activeAlertLevels) =>
    machines
      .filter(
        (machine) =>
          alertData[machine.id] &&
          getActiveNotClearedAlerts(
            getAlertsByLevel(alertData[machine.id] || [], activeAlertLevels)
          ).length > 0
      )
      .map(({ id }) => id)
);

export const selectLastFetchedCarouselData = createSelector(
  (state: RootState) => state.currentStatus.lastFetchedCarouselData,
  (x) => x
);

/** The last fetched cycle time is the last time results were received,
 * because there are no created dates in the cycle time data,
 * and it can't be concatenated.
 */
export const selectLastFetchedCycleTime = createSelector(
  (state: RootState) => state.currentStatus.lastFetchedCycleTime,
  (x) => x
);
