/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { RootState } from "@/store";
import {
  call,
  all,
  takeEvery,
  getContext,
  select,
  put,
  cancelled,
  cancel,
  take,
  race,
  takeLatest,
  delay,
  fork,
} from "typed-redux-saga";
import i18n from "@/i18n";
import { getGlobalConfigs } from "@/index";
import uniqBy from "lodash/fp/uniqBy";
import { orderBy } from "lodash/fp";
import { rootActions } from "..";
import { getType } from "typesafe-actions";
import { CMSClientType } from "@/cms-api";
import { getSelectedNode, getMultiSelectedNodes } from "./selectors";
import { getLocations, getMachines } from "../global/selectors";
import { LocationUI } from "@/presentation/Location";
import {
  Machine,
  MachineConfiguration,
  machineConfigurationDefaults,
  getMachineTypeAsString,
  MachinePatch,
  MachineType,
} from "@centralwebteam/narwhal";
import { setNotification } from "../global/thunks";
import { RootActions } from "../rootActions";
import { selectLicenceSummary, selectAssignedLicences } from "../selectors";
import { getErrorMessage } from "@/axios/errors";
import { t } from "i18next";

let locationName = "New Location",
  serviceNotRunningHeader = "",
  serviceNotRunningBody = "",
  licenceTransferLimitReached = "",
  licenceConnectionLimitReached = "",
  licenceRemoved = "",
  licenceRemoveSuccess = "",
  licenceNotRemoved = "",
  licenceRemoveFailed = "",
  licenceAssigned = "",
  licenceAssignedSuccess = "",
  licenceApplyFailed = "",
  conflict = "",
  updateFailed = "",
  noMatch = "",
  noId = "",
  updated = "",
  noResponse = "",
  exists = "";
i18n.then((t) => {
  locationName = t("placeholder-LocationName");
  serviceNotRunningHeader = t("heading-licence Server Error");
  serviceNotRunningBody = t("message-theLicenceServiceIsNotRunning");
  licenceTransferLimitReached = t("message-theLicenceTransferLimitReached");
  licenceConnectionLimitReached = t("message-theLicenceConnectionLimitReached");
  licenceRemoved = t("message-Licence Removed");
  licenceRemoveSuccess = t("message-licenceRemoveSuccess");
  licenceNotRemoved = t("message-Licence not removed");
  licenceRemoveFailed = t("message-licenceRemoveFailed");
  licenceAssigned = t("message-Licence Assigned");
  licenceAssignedSuccess = t("message-licenceAssignedSuccess");
  licenceApplyFailed = t("message-licenceApplyFailed");
  conflict = t("message-Machine update conflict");
  updateFailed = t("message-updateMachineFailed");
  noMatch = t("message-noMatchingMachine");
  noId = t("message-No machine ID");
  exists = t("message-machineExistsDetailNoInterpolation");
  updated = t("message-Machine updated");
  noResponse = t("message-No response from server");
});

export function* rootSaga() {
  yield* fork(licenceRefreshLoop);
  yield* all([
    takeLatest(
      getType(rootActions.manageAssets.deleteLocationOrMachineButtonClicked),
      deleteLocationOrMachineSaga
    ),
    takeEvery(
      getType(rootActions.manageAssets.addChildButtonClicked),
      addChildLocationSaga
    ),
    takeEvery(
      getType(rootActions.manageAssets.addSiblingButtonClicked),
      addSiblingLocationSaga
    ),
    takeEvery(
      getType(rootActions.manageAssets.rootButtonClicked),
      rootLocationSaga
    ),
    takeEvery(getType(rootActions.manageAssets.nodeDropped), nodeDroppedSaga),
    takeEvery(
      getType(rootActions.manageAssets.locationEditorSaveButtonClicked),
      updateLocationSaga
    ),
    takeEvery(
      // TODO: what is this error?
      //@ts-ignore
      getType(rootActions.manageAssets.machineEditorSaveButtonClicked),
      updateMachineSaga
    ),
    takeLatest(
      getType(rootActions.manageAssets.machineEditorLicenceButtonClicked),
      machineEditorLicenceButtonClicked
    ),
    takeEvery(
      getType(rootActions.manageAssets.refreshButtonClicked),
      refreshLicences
    ),
    takeEvery(
      getType(rootActions.manageAssets.machineEditorLoadingMachine),
      fetchMachineConfiguration
    ),
    takeLatest(
      getType(rootActions.manageAssets.newMachineCreateMachineButtonClicked),
      createMachineSaga
    ),
    takeLatest(
      getType(rootActions.manageAssets.testConnectionButtonClicked),
      testConnectionSaga
    ),
  ]);
}

function* licenceRefreshLoop() {
  const config = yield* call(() => getGlobalConfigs());
  while (true) {
    yield* call(fetchAllLicences);
    yield* delay(config.refreshRateLiveInMS);
  }
}

function* deleteLocationOrMachineSaga({ payload }: any) {
  const client: CMSClientType = yield getContext("client");
  const selectedNode = yield* select(getSelectedNode);
  const locations = yield* select(getLocations);
  const machines = yield* select(getMachines);
  let machineDeleted = "",
    unlicenseFailed = "",
    machineDeleteFailed = "",
    locationDeleted = "",
    locationDeleteFailed = "";
  i18n.then((t) => {
    machineDeleted = t("message-Machine deleted");
    unlicenseFailed = t("message-licenceRemoveFailed");
    machineDeleteFailed = t("message-machineDeleteFailed");
    locationDeleted = t("message-Location deleted");
    locationDeleteFailed = t("message-locationDeleteFailed");
  });
  try {
    if (payload.type === "machine") {
      const machine = machines.find(({ id }) => id === selectedNode);
      if (!selectedNode || !machine) return;

      yield* put(rootActions.manageAssets.confirmDeletion({ type: "machine" }));
      const { cancelled } = yield* race({
        cancelled: take(getType(rootActions.manageAssets.cancelButtonClicked)),
        confirmed: take(getType(rootActions.manageAssets.confirmButtonClicked)),
      });
      if (cancelled) {
        yield* cancel();
      }
      const assignedLicences = yield* select(selectAssignedLicences);
      const assignedLicence = assignedLicences.find(
        (assigned) => assigned.machineId === machine.id
      );
      try {
        yield* call((id) => client.machines.delete(id).promise, selectedNode);
        try {
          if (assignedLicence)
            yield* call(
              (licenceId) =>
                client.machines.licences.unassignLicence(licenceId).promise,
              assignedLicence.id
            );
          yield* put(
            // @ts-ignore
            setNotification(machineDeleted, [machine.name], "success")
          );
          yield* put(rootActions.manageAssets.deleteSuccessful(selectedNode!));
        } catch (error) {
          const message = getErrorMessage(error);
          yield* put(
            // @ts-ignore
            setNotification(unlicenseFailed, [message], "error")
          );
        }
      } catch (error) {
        const message = getErrorMessage(error);
        yield* put(
          // @ts-ignore
          setNotification(machineDeleteFailed, [message], "error")
        );
      }
    }
    if (payload.type === "location") {
      const location = locations.find(({ id }) => id === selectedNode);
      try {
        if (!selectedNode || !location) return;
        yield* put(
          rootActions.manageAssets.confirmDeletion({ type: "location" })
        );
        const { cancelled } = yield* race({
          cancelled: take(
            getType(rootActions.manageAssets.cancelButtonClicked)
          ),
          confirmed: take(
            getType(rootActions.manageAssets.confirmButtonClicked)
          ),
        });
        if (cancelled) {
          yield* cancel();
        }
        yield* call((id) => client.locations.delete(id).promise, selectedNode);
        yield* put(
          // @ts-ignore
          setNotification(locationDeleted, [location.name], "success")
        );
        yield* put(rootActions.manageAssets.deleteSuccessful(selectedNode!));
      } catch (error) {
        const message = getErrorMessage(error);
        yield* put(
          // @ts-ignore
          setNotification(locationDeleteFailed, [message], "error")
        );
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    if (!(yield* cancelled())) {
      yield* put(rootActions.global.refreshCoreData());
      yield* call(fetchAllLicences);
    }
  }
}

function* addSiblingLocationSaga() {
  const client: CMSClientType = yield getContext("client");
  const config = yield* call(() => getGlobalConfigs());
  const selectedNode: string | null = yield* select(getSelectedNode);
  const locations: LocationUI[] = yield* select(getLocations);
  const selectedNodeFull: LocationUI | undefined = locations.find(
    ({ id }) => id === selectedNode
  );
  try {
    // add a root location
    yield* call(
      () =>
        client.locations.add({
          name: locationName,
          parentId: selectedNodeFull?.parentId,
          timeZone: config.timeZone,
        }).promise
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield* put(rootActions.global.refreshCoreData());
  }
}

function* addChildLocationSaga() {
  const client: CMSClientType = yield getContext("client");
  const config = yield* call(() => getGlobalConfigs());
  const selectedNode: string | null = yield* select(getSelectedNode);
  const locations: LocationUI[] = yield* select(getLocations);
  const selectedNodeFull = locations.find(({ id }) => id === selectedNode);

  try {
    // add a root location
    const [newLocation] = yield* call(
      () =>
        client.locations.add({
          name: locationName,
          parentId:
            !selectedNode || !selectedNodeFull ? undefined : selectedNode,
          timeZone: config.timeZone,
        }).promise
    );
    yield* put(
      rootActions.manageAssets.addChildLocationSuccessful({
        parentId: selectedNode,
        id: newLocation.id,
      })
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield* put(rootActions.global.refreshCoreData());
  }
}

function* rootLocationSaga() {
  const client: CMSClientType = yield getContext("client");
  const config = yield* call(() => getGlobalConfigs());
  const selectedNode: string | null = yield* select(getSelectedNode);
  const locations: LocationUI[] = yield* select(getLocations);
  const selectedNodeFull = locations.find(({ id }) => id === selectedNode);
  if (!selectedNodeFull || !selectedNodeFull.parentId) return;
  try {
    yield* call((props) => client.locations.update(props).promise, {
      parentId: null,
      // rest to satisfy the api
      id: selectedNodeFull.id,
      name: selectedNodeFull.name,
      timeZone: config.timeZone,
    });
  } catch (error) {
    console.log(error);
  } finally {
    yield* put(rootActions.global.refreshCoreData());
  }
}

function* nodeDroppedSaga({ payload }: any) {
  const selectedNodes: string[] = yield* select(getMultiSelectedNodes);
  const locations: LocationUI[] = yield* select(getLocations);
  const machines: Machine[] = yield* select(getMachines);
  let machineWarning = "",
    noLocation = "";
  i18n.then((t) => {
    machineWarning = t("message-machineNotLocation");
    noLocation = t("message-noLocation");
  });
  try {
    if (machines.find(({ id }) => id === payload.drop)) {
      yield* put(
        // @ts-ignore
        setNotification(machineWarning, [], "warning")
      );
      return;
    }

    const dropLocation = locations.find(({ id }) => id === payload.drop);

    if (!dropLocation) {
      yield* put(
        // @ts-ignore
        setNotification(noLocation, [], "warning")
      );
      return;
    }

    const updateEffects = selectedNodes.map((nodeId) => {
      if (machines.find(({ id }) => id === nodeId)) {
        return call(updateMachineParentSaga, nodeId, payload.drop);
      } else {
        const dragLocation = locations.find(({ id }) => id === nodeId);
        // if the dragged node includes the drop node below it do not update as it's an illegal operation.
        if (dragLocation?.descendants.includes(dropLocation.id)) {
          let locationMoveFailed = "",
            recursion = "";
          i18n.then((t) => {
            locationMoveFailed = t("message-locationMoveFailed", dragLocation);
            recursion = t("message-recursiveNode");
          });
          return put(
            // @ts-ignore
            setNotification(locationMoveFailed, [recursion], "warning")
          );
        } else return call(updateLocationParentSaga, nodeId, payload.drop);
      }
    });
    yield* all(updateEffects);
    yield* put(
      rootActions.manageAssets.updateMultipleNodesSuccessful({
        parentId: payload.drop,
        nodeIds: selectedNodes,
      })
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield* put(rootActions.global.refreshCoreData());
  }
}

function* updateLocationSaga({ payload }: any) {
  const client: CMSClientType = yield getContext("client");
  const selectedNode: string | null = yield* select(getSelectedNode);
  let message = "";
  i18n.then((t) => {
    message = t("message-Location updated");
  });
  try {
    // Update the base properties.
    yield* call((props) => client.locations.update(props).promise, {
      ...payload,
      id: selectedNode,
    });

    yield* put(
      // @ts-ignore
      setNotification(message, [], "success")
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield* put(rootActions.global.refreshCoreData());
  }
}

function* updateMachineSaga({
  payload,
}: {
  payload: {
    machine: Partial<Machine> & { selectedLocation: string };
    configuration: MachineConfiguration | undefined;
  };
}) {
  try {
    const selectedNode: string | null = yield* select(getSelectedNode);
    const machines: Machine[] = yield* select(getMachines);
    const { make, model, serialNumber } = payload.machine;
    const machineExists = machines.some(
      (m) =>
        m.make.toLowerCase() === make!.toLowerCase() &&
        m.model.toLowerCase() === model!.toLowerCase() &&
        m.serialNumber.toLowerCase() === serialNumber!.toLowerCase() &&
        // Allow updating other details of this machine
        m.id !== selectedNode
    );

    if (machineExists) {
      yield* put(
        // @ts-ignore
        setNotification(conflict, [exists], "warning")
      );
      return;
    }
    const { selectedLocation, isLicensed, ...newDetails } = payload.machine;
    const newConfig = payload.configuration;
    const client: CMSClientType = yield getContext("client");
    const selectedNodeMachine: Machine | undefined = machines.find(
      ({ id }) => id === selectedNode
    );
    if (!selectedNodeMachine) {
      yield* put(
        // @ts-ignore
        setNotification(
          updateFailed,
          [noMatch, selectedNode ?? noId],
          "warning"
        )
      );
    } else {
      try {
        // Update the base properties.
        yield* call(
          (props) =>
            client.machines.update(
              props as MachinePatch & { type: MachineType }
            ).promise,
          {
            ...newDetails,
            id: selectedNode!,
          }
        );
        // We've updated the base properties so now we check if the location has changed.
        if (selectedLocation !== selectedNodeMachine.locationId) {
          yield* call(
            updateMachineParentSaga,
            selectedNodeMachine.id,
            selectedLocation
          );
        }
        // Update IPC settings if present
        if (newConfig && isLicensed) {
          yield* call(() => {
            client.events.machineConfiguration.update(
              selectedNodeMachine.id,
              newConfig
            );
          });
          yield* put(
            rootActions.manageAssets.machineEditorSaveButtonClickedSuccess({
              machine: newDetails,
              configuration: {
                ...newConfig,
                machineId: selectedNodeMachine.id,
              },
            })
          );
        }

        yield* put(
          // @ts-ignore
          setNotification(updated, [selectedNodeMachine.name], "success")
        );
      } catch (error: any) {
        if (error?.response?.status >= 400) {
          yield* put(
            // @ts-ignore
            setNotification(
              updateFailed,
              error?.response?.data ?? noResponse,
              "error"
            )
          );
          return;
        }
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield* put(rootActions.global.refreshCoreData());
  }
}

function* updateMachineParentSaga(
  machineId: string,
  locationId: string | null
) {
  const client: CMSClientType = yield getContext("client");
  if (!locationId || !machineId) return;

  yield* call(
    (locationId, machineId) =>
      client.machines.linkMachine(locationId, machineId).promise,
    locationId,
    machineId
  );
}

function* updateLocationParentSaga(
  locationId: string,
  parentId: string | undefined
) {
  const client: CMSClientType = yield getContext("client");
  yield* call(
    (parentId, locationId) =>
      client.locations.update({
        id: locationId,
        parentId: parentId ?? undefined,
      }).promise,
    parentId,
    locationId
  );
}

function* machineEditorLicenceButtonClicked(action: RootActions) {
  try {
    if (
      action.type !==
      getType(rootActions.manageAssets.machineEditorLicenceButtonClicked)
    )
      return;
    const {
      payload: { machine },
    } = action;
    const licenceSummary = yield* select(selectLicenceSummary);
    const machineTypeLicenceSummary =
      licenceSummary[getMachineTypeAsString(machine.type)];
    if (!machineTypeLicenceSummary) {
      // we don't know
      throw new Error("no machine type licence summary found!");
    }
    const assignedLicences = yield* select(selectAssignedLicences);
    const assignedLicence = assignedLicences.find(
      (licence) => licence.machineId === machine.id
    );
    if (assignedLicence) {
      // currently licensed
      yield* call(unassignLicence, assignedLicence.id);
    } else {
      let assignedLicenceStatus = false;

      // not currently licensed
      for (let i = 0; i < machineTypeLicenceSummary.licences.length; i++) {
        const { id } = machineTypeLicenceSummary.licences[i];
        if (assignedLicenceStatus) break;
        try {
          const client: CMSClientType = yield getContext("client");
          yield* call(
            (id, machine) =>
              client.machines.licences.assignLicenceToMachine({
                licenceId: id,
                machineId: machine.id,
              }).promise,
            id,
            machine
          );
          yield* put(
            // @ts-ignore
            setNotification(
              licenceAssigned,
              [licenceAssignedSuccess],
              "success"
            )
          );
          assignedLicenceStatus = true;
        } catch (error: any) {
          let body = getErrorMessage(error);
          if (error?.response?.data.includes("The transfer limit")) {
            body = licenceTransferLimitReached;
          }
          if (
            error?.response?.data.includes(
              "Licence connection limit has been reached"
            )
          ) {
            body = licenceConnectionLimitReached;
          }
          yield* put(
            // @ts-ignore
            setNotification(
              licenceApplyFailed,
              body === "" ? [] : [body],
              "error"
            )
          );
        }
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield* put(rootActions.global.refreshCoreData());
    yield* call(fetchAllLicences);
  }
}

function* unassignLicence(licenceId: string) {
  const client: CMSClientType = yield getContext("client");
  try {
    yield* call(
      (licenceId) =>
        client.machines.licences.unassignLicence(licenceId).promise,
      licenceId
    );
    yield* put(
      // @ts-ignore
      setNotification(licenceRemoved, [licenceRemoveSuccess], "success")
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(licenceNotRemoved, [licenceRemoveFailed], "error")
    );
  }
}

function* fetchAllLicences() {
  const client: CMSClientType = yield getContext("client");
  const [licensedMachines, availableLicensesForMachines] = yield* all([
    call(() =>
      client.machines.licences
        .getAllAssignedLicences()
        .promise.then((res) => uniqBy("id", res))
    ),
    call(() =>
      client.machines.licences
        .getAllUnassignedLicences()
        .promise.then((res) => uniqBy("id", res))
    ),
  ]);

  yield* all([
    put(
      rootActions.manageAssets.assignedLicencesFetched(
        orderBy("name", "asc", licensedMachines)
      )
    ),
    put(
      rootActions.manageAssets.unassignedLicencesFetched(
        orderBy("name", "asc", availableLicensesForMachines)
      )
    ),
  ]);
}

/** Triggers the API server to refresh its information from the Flexera licence server */
function* refreshLicences(action: RootActions) {
  if (action.type !== getType(rootActions.manageAssets.refreshButtonClicked))
    return;
  const client: CMSClientType = yield getContext("client");

  try {
    yield* call(() => client.machines.licences.refreshLicences().promise);
  } catch (error: any) {
    if (error?.response?.status == 601 || error?.response?.status == 602) {
      yield* put(
        // @ts-ignore
        setNotification(
          serviceNotRunningHeader,
          [serviceNotRunningBody],
          "error"
        )
      );
    }
  }

  yield call(fetchAllLicences);
}

/** The MachineConfiguration comes from querying the Events endpoint, so it is more expensive
 *  to fetch than basic machine data, and can only be done one machine at a time. */
function* fetchMachineConfiguration(action: RootActions) {
  if (
    action.type !==
    getType(rootActions.manageAssets.machineEditorLoadingMachine)
  )
    return;
  const { machineId } = action.payload;
  const client: CMSClientType = yield getContext("client");
  const config: MachineConfiguration = yield call(() =>
    client.events.machineConfiguration.byMachineId(machineId).promise.then(
      (r) => (r?.length ? r[0] : machineConfigurationDefaults),
      (_) => machineConfigurationDefaults
    )
  );
  yield put(
    rootActions.manageAssets.machineConfigurationFetched({
      configuration: { ...config, machineId },
    })
  );
}
let machineExists = "",
  machineExistsDetail = "";
i18n.then((t) => {
  machineExists = t("message-machineExists");
});
function* createMachineSaga({ payload }: any) {
  const client: CMSClientType = yield getContext("client");
  let createMachine = "",
    machineCreateSuccess = "",
    machineCreateFailed = "";
  i18n.then((t) => {
    createMachine = t("label-Create Machine");
    machineCreateSuccess = t("message-machineCreateSuccess");
    machineCreateFailed = t("message-machineCreateFailed");
  });
  machineExistsDetail = t("message-machineExistsDetail", payload) as string;
  try {
    const machines = yield* select((state: RootState) => state.global.machines);
    const { selectedLocation, ...newMachine } = payload;
    const doesMachineExist = machines.find(
      (m) =>
        m.make.toLowerCase() === newMachine.make.toLowerCase() &&
        m.model.toLowerCase() === newMachine.model.toLowerCase() &&
        m.serialNumber.toLowerCase() === newMachine.serialNumber.toLowerCase()
    );
    if (doesMachineExist) {
      yield* put(
        // @ts-ignore
        setNotification(machineExists, [machineExistsDetail], "warning")
      );
      return;
    }
    const responseMachine = yield* call(
      (props) =>
        client.machines.add(props as MachinePatch & { type: MachineType })
          .promise,
      newMachine
    );
    if (selectedLocation) {
      // @ts-ignore
      const machineId = responseMachine[0].id;
      yield* call(updateMachineParentSaga, machineId, selectedLocation);
    }
    yield* put(
      // @ts-ignore
      setNotification(createMachine, [machineCreateSuccess], "success")
    );
  } catch (error) {
    const message = getErrorMessage(error);
    yield* put(
      // @ts-ignore
      setNotification(machineCreateFailed, [message], "error")
    );
  } finally {
    yield* put(rootActions.global.refreshCoreData());
  }
}

function* testConnectionSaga({ payload }: any) {
  const IPCMachineID = payload.machineId;
  const client: CMSClientType = yield getContext("client");
  let testConnectionFailed = "";
  i18n.then((t) => {
    testConnectionFailed = t("message-testConnectionFailed");
  });
  try {
    yield* put(
      rootActions.manageAssets.setStatusForTestConnection({
        connectionStatus: "started",
        connectionStatusBody: "",
      })
    );
    const config = yield* call(() => getGlobalConfigs());
    let pollResponse = null;
    try {
      yield* call(
        (machineID, data) => client.events.add(machineID, data).promise,
        IPCMachineID,
        payload.connectionData
      );
      let pollResult = "Failed";
      for (let i = 0; i < 5; i++) {
        const connectedState = yield* select(
          (state) => state.manageAssets.connectionStatus
        );
        if (connectedState === "cancelled") return;
        pollResponse = yield* call(
          () =>
            client.events.query<any>(
              `$filter=type eq ServiceResponse and subtype eq ConnectivityCheck and requestId eq ${payload.connectionData[0].requestId} and machineId eq ${IPCMachineID}&$orderby created desc&$top=1`
            ).promise
        );
        if (
          pollResponse &&
          Array.isArray(pollResponse) &&
          pollResponse.length > 0 &&
          pollResponse[0].details.machines[0].status === "Succeeded"
        ) {
          pollResult = "Succeeded";
          yield* put(
            rootActions.manageAssets.setStatusForTestConnection({
              connectionStatus: "connected",
              connectionStatusBody: "",
            })
          );
          yield* put(
            rootActions.manageAssets.setMachineInformation(
              pollResponse[0].details.machines[0].machineInfo
            )
          );
          break;
        }
        yield* delay(config.delayConnectivityCheckInMS);
      }
      if (pollResult === "Failed") {
        let messageBody = testConnectionFailed;
        if (pollResponse && pollResponse.length > 0)
          messageBody =
            pollResponse[0].details.machines[0].errorDetails.AdvisedAction;

        yield* put(
          rootActions.manageAssets.setStatusForTestConnection({
            connectionStatus: "failedToConnect",
            connectionStatusBody: messageBody,
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  } catch (error) {
    const message = getErrorMessage(error);
    yield* put(
      rootActions.manageAssets.setStatusForTestConnection({
        connectionStatus: "failedToConnect",
        connectionStatusBody: message,
      })
    );
  }
}
