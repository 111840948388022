/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { createGlobalStyle } from "@/styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    flex-shrink: 1;
  }
  :focus {
    outline-style: solid;
    outline-width: 1px;
    outline-color: #fff;
    outline-offset: -1px;
  }
  ::-moz-focus-inner {
    border: 0;
  }
  html,
  body,
  #app {
    min-height: 100%;
    height: 100%;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Segoe UI", "Segoe Symbol", "SF Pro Text", -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif;
    margin: 0;
    padding: 0;
    font-size: 13px;
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.bg};
    color: ${(props) => props.theme.fg};
    display: flex;
    flex-direction: column;
    /* W3C working draft standard - FF only as of Oct. 2019 */
    scrollbar-color: #555 #333;
    scrollbar-width: thin;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: lighter;
  }
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.3em;
  }
  h3 {
    font-size: 1.1em;
    font-weight: normal;
  }

  a {
    text-decoration: none;
    color: white;
  }

  a.router-link-exact-active {
    color: ${(props) => props.theme.highlight};
  }

  input, button, select {
    font-family: inherit;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    padding: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4.5px;
    background: #555;
    box-shadow: 1px 0px #000, inset 1px 0px rgba(255, 255, 255, 0.2);
  }
`;
