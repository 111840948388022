/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import { SortDirection } from "@centralwebteam/narwhal";
import {
  JobPerfSortUnit,
  JobType,
  JobPerfSortColumn,
  JobPerformance,
  JobPerfDetailSortColumnAdditive,
  JobPerfDetailSortColumnMetrology,
} from "@/store/state";
import { JobPresentation } from "@/presentation/Job";

const fetchJobSummariesAndCalculatePerformance = createStandardAction(
  "[job performance] fetch job summaries and calculate performance"
)();

const jobsLoaded = createStandardAction("[jobs performance] jobs loaded")<{
  jobTypes: JobType[];
  jobSummaries: JobPresentation[];
}>();

const jobTypePerformanceCalculated = createStandardAction(
  "[jobs performance] job type performance calculated"
)<{ performance: MappyAbsolute<JobPerformance> }>();

const machinePerformanceCalculated = createStandardAction(
  "[jobs performance] machine performance calculated"
)<{ performance: MappyAbsolute<MappyAbsolute<JobPerformance>> }>();

const changeSortUnit = createStandardAction(
  "[jobs performance] change sort"
)<JobPerfSortUnit>();

const changeSortColumn = createStandardAction(
  "[jobs performance] change sort column"
)<JobPerfSortColumn>();

const changeSortDirection = createStandardAction(
  "[jobs performance] change sort direction"
)<SortDirection>();

const setDetailSort = createStandardAction(
  "[jobs performance] change detail table sort"
)<
  [
    JobPerfDetailSortColumnAdditive | JobPerfDetailSortColumnMetrology,
    SortDirection,
  ]
>();

const changeDate = createStandardAction("[jobs performance] change date")<
  [Date, Date]
>();

const jobTypeClicked = createStandardAction(
  "[jobs performance] job type clicked"
)<JobType>();

const machineClicked = createStandardAction(
  "[jobs performance] machine clicked"
)<{ machineId: string }>();

const expandAllClicked = createStandardAction(
  "[jobs performance] expand all clicked"
)<void>();

const collapseAllClicked = createStandardAction(
  "[jobs performance] collapse all clicked"
)<void>();

const workingOnData = createStandardAction(
  "[jobs performance] working on data"
)<void>();

const workCompleted = createStandardAction(
  "[jobs performance] work completed"
)<{
  lastFetchedRange: [string, string];
}>();

const fetchingData = createStandardAction(
  "[jobs performance] fetching data"
)<void>();

const focusedJobPopupMachineAnalysisButtonClicked = createStandardAction(
  "[job performance] focused job popup machine analysis button clicked"
)<{ startDate: string; endDate: string | null; job: JobPresentation }>();
const jobSummariesDataWarningLimitBreached = createStandardAction(
  "[device][saga] timeline static data warning limit breached"
)();

const machineAggregateIconClicked = createStandardAction(
  "[job performance] machine aggregate icon clicked"
)<{ machineId: string }>();

const jobRunClicked = createStandardAction("[job perfomance] job run clicked")<{
  job: JobPresentation;
}>();

export const actions = {
  changeDate,
  changeSortColumn,
  changeSortDirection,
  changeSortUnit,
  collapseAllClicked,
  expandAllClicked,
  fetchingData,
  fetchJobSummariesAndCalculatePerformance,
  jobsLoaded,
  jobTypeClicked,
  machineClicked,
  jobTypePerformanceCalculated,
  machinePerformanceCalculated,
  setDetailSort,
  workCompleted,
  workingOnData,
  focusedJobPopupMachineAnalysisButtonClicked,
  jobSummariesDataWarningLimitBreached,
  machineAggregateIconClicked,
  jobRunClicked,
};

export type Actions = ActionType<typeof actions>;
