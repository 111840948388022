/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { rootActions, RootState } from "..";
import {
  all,
  takeLatest,
  call,
  put,
  getContext,
  select,
  delay,
  fork,
} from "typed-redux-saga";
import i18n from "@/i18n";
import { getType } from "typesafe-actions";
import { setNotification } from "@/store/global/thunks";
import { CMSClientType } from "@/cms-api";
import { getGlobalConfigs } from "@/index";
import { ProvisionTabs } from "@/store/state";
import { ServiceType } from "@centralwebteam/narwhal";
import { getErrorMessage } from "@/axios/errors";
import { t } from "i18next";

let machineExists = "",
  machineExistsDetail = "",
  machineExistsHint = "";

i18n.then((t) => {
  machineExists = t("message-machineExists");
  machineExistsDetail = t("message-machineExistsDetail");
  machineExistsHint = t("message-machineExistsHint");
});
export function* rootSaga() {
  yield* fork(fetchProvisionRequestsSaga);
  yield* fork(dataLoopSaga);
  yield* all([
    yield* takeLatest(
      getType(rootActions.manageProvisioning.approveMachineButtonClicked),
      approveMachineSaga
    ),
    yield* takeLatest(
      getType(rootActions.manageProvisioning.deleteMachineButtonClicked),
      deleteMachineRequestSaga
    ),
  ]);
}

function* dataLoopSaga() {
  const config = yield* call(() => getGlobalConfigs());
  while (true) {
    yield* delay(config.refreshRateLiveInMS);
    yield* call(fetchProvisionRequestsSaga);
  }
}

function* approveMachineSaga({ payload }: any) {
  const client: CMSClientType = yield getContext("client");
  const registrationId = yield* select(
    (state: RootState) => state.manageProvisioning.selectedRegistrationId
  );
  const machines = yield* select((state: RootState) => state.global.machines);
  const {
    make,
    model,
    serialNumber,
    name,
    type,
    locationId,
    matchedMachine,
    machine,
  } = payload.provisionRequest;

  const { createNewMachine, selectExistingMachine, foundMatchedMachine } =
    ProvisionTabs;

  let approved = "",
    machineApproveFailed = "";
  i18n.then((t) => {
    approved = t("message-Machine approved");
    machineApproveFailed = t("message-machineApproveFailed");
  });
  try {
    let data = undefined;
    machineExistsDetail = t("message-machineExistsDetail", {
      serialNumber: payload.notificationMessage.serialNumber,
      make: payload.notificationMessage.make,
      model: payload.notificationMessage.model,
    });

    switch (payload.selectedOption) {
      case createNewMachine: {
        const existingMachine = machines.find(
          (machine) =>
            machine.name.toLowerCase() === name.toLowerCase() &&
            machine.serialNumber.toLowerCase() === serialNumber.toLowerCase() &&
            machine.make.toLowerCase() === make.toLowerCase() &&
            machine.model.toLowerCase() === model.toLowerCase()
        );
        if (existingMachine) {
          yield* put(
            // @ts-ignore
            setNotification(
              machineExists,
              [machineExistsDetail, machineExistsHint],
              "warning"
            )
          );
          return;
        }
        data = {
          machine,
          locationId: locationId ?? "",
        };
        break;
      }
      case selectExistingMachine: {
        const existingMachine = machines.find(
          (machine) => machine.id === payload.selectedMachineId
        );
        if (existingMachine) {
          data = {
            machine: {
              ...existingMachine,
              ...machine,
            },
            locationId: locationId ?? "",
          };
          if (data.machine.locationId) delete data.machine.locationId;
        } else {
          console.warn(
            `Existing machine not found: ${payload.selectedMachineId}`
          );
          yield* put(
            // @ts-ignore
            setNotification(
              machineApproveFailed,
              [payload.selectedMachineId],
              "error"
            )
          );
        }
        break;
      }
      case foundMatchedMachine:
        data = {
          machine: {
            ...matchedMachine,
            ...machine,
          },
          locationId: locationId ?? "",
        };
        if (data.machine.locationId) delete data.machine.locationId;
        break;
      default:
        console.warn(
          `Unsupported provisioning option: ${payload.selectedOption}`
        );
        break;
    }
    if (data) {
      yield* call(
        (id, data) => client.machineProvisioning.approve(id, data).promise,
        registrationId,
        data
      );
      yield* put(
        rootActions.manageProvisioning.machineApproved(registrationId)
      );
      yield* put(
        // @ts-ignore
        setNotification(approved, [], "success")
      );
      if (type === ServiceType.Service) {
        const allClients = yield* call(() => client.clients.all().promise);
        // @ts-ignore
        const clientIds = allClients
          .filter(
            (c: any) => c.name === name + "_client" && !c.permissions?.dataAdmin
          )
          .map((item) => item.id);
        for (const clientId of clientIds) {
          try {
            const clientDetail = {
              clientId: clientId,
              data: { dataAdmin: true },
            };
            yield* call(
              (clientDetail) =>
                client.clients.updatePermissions(
                  clientDetail.clientId,
                  clientDetail.data
                ).promise,
              clientDetail
            );
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
    yield* put(rootActions.global.refreshCoreData());
  } catch (error) {
    console.log(error);
    yield* put(
      // @ts-ignore
      setNotification(machineApproveFailed, [getErrorMessage(error)], "error")
    );
  }
}

function* deleteMachineRequestSaga() {
  let requestDeleted = "",
    requestDeleteFailed = "";
  i18n.then((t) => {
    requestDeleted = t("message-requestDeleted");
    requestDeleteFailed = t("message-requestDeleteFailed");
  });
  try {
    const client: CMSClientType = yield getContext("client");
    const registrationId = yield* select(
      (state: RootState) => state.manageProvisioning.selectedRegistrationId
    );
    yield* call(
      (id) => client.machineProvisioning.delete(id).promise,
      registrationId
    );
    yield* put(
      rootActions.manageProvisioning.machineApprovalDeleted(registrationId)
    );
    yield* put(
      // @ts-ignore
      setNotification(requestDeleted, [], "success")
    );
    yield* put(rootActions.global.refreshCoreData());
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(requestDeleteFailed, [getErrorMessage(error)], "error")
    );
  }
}

function* fetchProvisionRequestsSaga() {
  const client: CMSClientType = yield getContext("client");
  const requests = yield* call(() => client.machineProvisioning.get().promise);
  if (!requests) return;
  yield* put(rootActions.manageProvisioning.provisionRequestsFetched(requests));
}
