/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { assertUnreachable } from "@/@types/assertUnreachable";
import { JobPresentation } from ".";
import { useTranslation } from "react-i18next";

export const verdictGroups = ["Pass", "Fail", "No Verdict"] as const;
export type VerdictGroup = (typeof verdictGroups)[number];

export const verdicts = [
  "Pass",
  "Warning",
  "Fail",
  "NotToleranced",
  "NotApplicable",
  "No Verdict",
] as const;
export type Verdict = (typeof verdicts)[number];

function validateStringAsVerdict(
  verdictString: string
): verdictString is Verdict {
  return verdicts.includes(verdictString as any);
}
export function mapStringToVerdict(verdictString?: string): Verdict {
  if (verdictString && validateStringAsVerdict(verdictString))
    return verdictString;
  return "No Verdict";
}

/**
 * Completed jobs are sorted into Fail, Warning, Pass and Other. All incomplete jobs are grouped together.
 */
export const mapVerdictToGroup = ({
  verdict,
}: JobPresentation): VerdictGroup => {
  switch (verdict) {
    case "Pass":
    case "Warning": {
      return "Pass";
    }
    case "Fail": {
      return "Fail";
    }
    case "No Verdict":
    case "NotApplicable":
    case "NotToleranced": {
      return "No Verdict";
    }
    default: {
      assertUnreachable(verdict);
    }
  }
};

export const MapVerdictTodisplayString = (job: JobPresentation): string => {
  const { t } = useTranslation();
  const group = mapVerdictToGroup(job);

  switch (group) {
    case "Pass": {
      return t`label-Pass`;
    }
    case "Fail": {
      return t`label-Fail`;
    }
    case "No Verdict": {
      return t`label-No Verdict`;
    }
    default: {
      assertUnreachable(group);
    }
  }
};

// Return list of verdicts for a verdict group
export const mapGroupToVerdicts = (verdict: string): any => {
  switch (verdict) {
    case "Pass": {
      return ["Pass", "Warning"];
    }
    case "Fail": {
      return ["Fail"];
    }
    case "NoVerdict": {
      return ["NoVerdict", "NotToleranced"];
    }
  }
};

// Convert verdict label into verdict group
export const mapVerdictLabelToGroup = (verdict: string): any => {
  switch (verdict) {
    case "Pass": {
      return "Pass";
    }
    case "Fail": {
      return "Fail";
    }
    case "No Verdict": {
      return "NoVerdict";
    }
  }
};
