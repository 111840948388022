/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { put, select, takeLatest } from "typed-redux-saga";
import { rootActions } from "@/store";
import {
  isValidStartToken,
  getStartDate,
  selectStartAndEndRaw,
  selectPreviousLiveModeToken,
} from "../selectors";
import { knownStartValues, KnownStartValues } from "@/store/state";
import { getType } from "typesafe-actions";

export function* dateModeWatcherSaga() {
  try {
    yield* takeLatest(
      getType(rootActions.session.userToggledDateMode),
      toggleDateModeSaga
    );
  } catch (error) {
    console.warn(`Date mode watcher: ${error}`);
  }
}

// Type inference fails on generator returns
function* toggleDateModeSaga(action: any) {
  const { payload } = action;
  const [currentStart, currentEnd] = yield* select(selectStartAndEndRaw);
  const previousLiveModeToken = yield* select(selectPreviousLiveModeToken);
  let nextEnd: null | string = null;
  let nextStart: null | string = null;
  // if end is null we are in live mode
  if (!currentEnd) {
    nextStart = isValidStartToken(currentStart)
      ? currentStart
      : knownStartValues[0];
    nextStart = getStartDate(nextStart as KnownStartValues).toISOString();
    nextEnd = payload;
  } else {
    nextStart = previousLiveModeToken;
    nextEnd = null;
  }
  yield* put(rootActions.session.startEndUpdated([nextStart!, nextEnd]));
}
