import { call, getContext } from "typed-redux-saga";
import { CMSClientType } from "@/cms-api";
import { FileInfoForSelectedJob } from "@centralwebteam/narwhal";

export function* fetchJobMoreDetails({ jobId }: { jobId: string }) {
  try {
    const client = yield* getContext<CMSClientType>("client");
    const events = yield* call(
      (jobId) => client.events.byId<any>(jobId).promise,
      jobId
    );
    return events[0] as any | undefined;
  } catch (error) {
    console.error(error);
  }
}

export function* fetchFiles({ jobId }: { jobId: string }) {
  try {
    const client: CMSClientType = yield getContext("client");
    const fileDataForSelectedJob: FileInfoForSelectedJob[] = yield* call(
      (jobId) => client.files.byJobId(jobId).promise,
      jobId
    );
    return fileDataForSelectedJob;
  } catch (error) {
    console.error(error);
  }
}

export function* fetchJobMetaData({
  machineId,
  startDate,
  endDate,
}: {
  machineId: string;
  startDate: string;
  endDate: string;
}) {
  try {
    const client = yield* getContext<CMSClientType>("client");
    const JobMetadata: any = yield* call(
      (str) => client.events.query(str).promise,
      `$filter=machineId eq ${machineId} and type eq JobProperty and subType eq Metadata and created ge ${startDate} and created le ${endDate}`
    );
    const metadata = JobMetadata.filter(
      (p: any) => p.displayHints.userVisible
    ).map((item: any) => {
      return { [item.name]: item.value };
    });
    return Object.assign({}, ...metadata);
  } catch (error) {
    console.error(error);
  }
}
