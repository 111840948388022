/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { actions } from "./actions";
import { wrap } from "@/modules/comlink";
import { call, select, put, getContext, delay } from "typed-redux-saga";
import isEqual from "lodash/fp/isEqual";
import { selectStartAndEnd } from "@/store/selectors";
import { getGlobalConfigs } from "@/index";
import { CMSClientType } from "@/cms-api";
import { Exposed } from "./index.worker";
import { setNotification } from "@/store/global/thunks";
import { notificationTypes } from "@centralwebteam/jellyfish";

/**
 * Fetches Jobs from the specified time period, dispatching actions to add them to the store.
 * @param from start date
 * @param to end date
 */
export function* calculateJobPerformanceSaga() {
  const worker = new Worker("./index.worker.ts", { type: "module" });
  try {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    const proxy = wrap<Exposed>(worker);
    const [from, to] = yield* select(selectStartAndEnd);
    const {
      jobPerformance: { lastFetchedRange },
    } = yield* select();

    if (lastFetchedRange && isEqual(lastFetchedRange, [from, to])) {
      return;
    }
    yield* put(actions.fetchingData());
    let jobSummaries: any = [];
    try {
      jobSummaries = yield* call((req) => client.jobs.summary(req).promise, {
        query: {
          from,
          to,
          take: config.maxTake,
        },
      });
    } catch (error) {
      // @ts-ignore
      yield* put(setNotification("", [error.message], notificationTypes.error));
    }
    if (jobSummaries.length >= config.warningLimit) {
      yield* put(actions.jobSummariesDataWarningLimitBreached());
    }
    yield* put(actions.workingOnData());

    const { jobTypes, jobTypePerformances, machinePerformances } = yield* call(
      proxy.processJobs,
      jobSummaries
    );

    yield* put(
      actions.jobsLoaded({
        jobTypes,
        jobSummaries,
      })
    );

    yield* put(
      actions.jobTypePerformanceCalculated({
        performance: jobTypePerformances,
      })
    );

    yield* put(
      actions.machinePerformanceCalculated({
        performance: machinePerformances,
      })
    );

    if (!jobSummaries.length) {
      yield* delay(2000);
    }
    yield* put(actions.workCompleted({ lastFetchedRange: [from, to] }));
  } finally {
    worker.terminate();
  }
}
