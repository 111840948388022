/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { MeasurementCharacteristicVerdict } from "@centralwebteam/narwhal";
import { Verdict, mapStringToVerdict } from "./Job";

export type MeasurementCharacteristicVerdictPresentation = {
  machineId: string;
  name: string;
  featureName?: string;
  verdict: Verdict;
};

export function createMeasurementCharacteristicVerdictPresentation(
  verdict: MeasurementCharacteristicVerdict
): MeasurementCharacteristicVerdictPresentation {
  return {
    ...verdict,
    verdict: mapStringToVerdict(verdict.verdict),
  };
}
