/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { Routes } from "@/store/session/routeDefinitions";

export const getRouteFromPreferences = (
  pages: string[] | undefined,
  defaultRoute: Routes
) => {
  let route = defaultRoute;
  if (
    pages === undefined ||
    pages.filter((f) => typeof f !== "string").length > 0 ||
    pages.some((page) => page.toLowerCase() === "current status")
  ) {
    route = "current status";
  } else if (pages.some((page) => page.toLowerCase() === "job performance")) {
    route = "job performance";
  } else if (
    pages.some((page) => page.toLowerCase() === "machine performance")
  ) {
    route = "machine performance";
  }
  return route;
};
