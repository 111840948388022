/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { MeasurementCharacteristicPresentation } from ".";

/** Sorting function that ranks MeasurementCharacteristicPresentations in the following order:
 *
 * • Fails, highest error first;
 *
 * • Warnings, highest deviation first;
 *
 * • All other verdicts, alphabetically by feature and measurement name.
 */
export function sortWorstToBest(
  a: MeasurementCharacteristicPresentation,
  b: MeasurementCharacteristicPresentation
) {
  if (a.verdict && b.verdict) {
    // Fails first, then warnings
    if (
      (a.verdict === "Fail" && b.verdict !== "Fail") ||
      (a.verdict === "Warning" && !["Fail", "Warning"].includes(b.verdict))
    )
      return -1;
    if (
      (a.verdict !== "Fail" && b.verdict === "Fail") ||
      (!["Fail", "Warning"].includes(a.verdict) && b.verdict === "Warning")
    )
      return 1;
    // Sort by error or deviation within fails and warnings
    if (a.verdict === b.verdict && ["Fail", "Warning"].includes(a.verdict))
      return (
        Math.abs(b.error || 0) - Math.abs(a.error || 0) ||
        Math.abs(b.deviation || 0) - Math.abs(a.deviation || 0)
      );
  }
  // For everything that is not a fail or warning, sort A-Z by feature + measurement name
  return ((a.featureName ?? "") + a.name).localeCompare(
    (b.featureName ?? "") + b.name
  );
}
