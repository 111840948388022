/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import { LocationUI } from "@/presentation/Location";
import {
  GUID,
  Licence,
  Machine,
  MachineConfiguration,
} from "@centralwebteam/narwhal";
import type { MachineConfigurationPatch } from "../state";

const sectionToggled = createStandardAction(
  "[manage assets] toggle section expansion"
)<string>();

const activeTabChanged = createStandardAction(
  "[manage assets] active tab changed"
)<string>();

export const actions = {
  assignedLicencesFetched: createStandardAction(
    "[manage assets][saga] assigned licenses fetched"
  )<Licence[]>(),
  unassignedLicencesFetched: createStandardAction(
    "[manage assets][saga] unassigned licenses fetched"
  )<Licence[]>(),
  machineEditorLicenceButtonClicked: createStandardAction(
    "[manage assets] machine editor licence button clicked"
  )<{ machine: Machine }>(),
  machineEditorLoadingMachine: createStandardAction(
    "[manage assets] machine editor loading machine"
  )<{ machineId: GUID }>(),
  machineConfigurationFetched: createStandardAction(
    "[manage assets] machine configuration fetched"
  )<{ configuration: MachineConfiguration }>(),
  sectionToggled,
  activeTabChanged,
  treeviewNodeClicked: createStandardAction(
    "[manage assets] treeview node clicked"
  )<string>(),
  addChildButtonClicked: createStandardAction(
    "[manage assets] add child location button clicked"
  )(),
  addChildLocationSuccessful: createStandardAction(
    "[manage assets][saga] add child location successful"
  )<{ parentId: string | null; id: string }>(),
  addSiblingButtonClicked: createStandardAction(
    "[manage assets] add sibling location button clicked"
  )(),
  deleteLocationOrMachineButtonClicked: createStandardAction(
    "[manage assets] delete location or machine button clicked"
  )<{ type: "location" | "machine" }>(),
  deleteSuccessful: createStandardAction("[saga] delete successful")<string>(),
  rootButtonClicked: createStandardAction(
    "[manage assets] root Location Button Clicked"
  )(),
  nodeDropped: createStandardAction("[manage assets] node dropped")<{
    drag: string;
    drop: string;
  }>(),
  locationEditorSaveButtonClicked: createStandardAction(
    "[manage assets] location editor save button clicked"
  )<Partial<LocationUI>>(),
  machineEditorSaveButtonClicked: createStandardAction(
    "[manage assets] machine editor save button clicked"
  )<{
    machine: Partial<Machine>;
    configuration: MachineConfiguration | undefined;
  }>(),
  machineEditorSaveButtonClickedSuccess: createStandardAction(
    "[manage assets] machine editor save button clicked success"
  )<{
    machine: Partial<Machine>;
    configuration: MachineConfiguration | undefined;
  }>(),
  machineEditorFormUpdated: createStandardAction(
    "[manage assets] machine editor form updated"
  )<Partial<Machine> & { id: string } & MachineConfigurationPatch>(),
  setFormDataInvalidated: createStandardAction(
    "[manage assets] machine editor set formDataInvalidated"
  )<boolean>(),
  controlButtonPressStateChanged: createStandardAction(
    "[manage assets] control button press state changed"
  )<"down" | "up">(),
  updateMultipleNodesSuccessful: createStandardAction(
    "[manage assets][saga] update multiple nodes successful"
  )<{
    parentId: string;
    nodeIds: string[];
  }>(),
  deleteMachineButtonClicked: createStandardAction(
    "[manage assets] delete machine button clicked"
  )(),
  confirmDeletion: createStandardAction(
    "[manage assets] delete confirmation requested (saga)"
  )<{ type: "location" | "machine" }>(),
  confirmButtonClicked: createStandardAction(
    "[manage assets] delete confirmation box confirm button clicked"
  )(),
  cancelButtonClicked: createStandardAction(
    "[manage assets] delete confirmation box cancel button clicked"
  )(),
  refreshButtonClicked: createStandardAction(
    "[manage assets] licence refresh button clicked"
  )(),
  addNewDeviceButtonClicked: createStandardAction(
    "[manage assets] add new device button clicked"
  )<void>(),
  newMachineCreateMachineButtonClicked: createStandardAction(
    "[manage assets] new machine create machine button clicked"
  )<Partial<Machine>>(),
  testConnectionButtonClicked: createStandardAction(
    "[manage assets] test connection button clicked"
  )<{ machineId: GUID; connectionData: any }>(),
  setStatusForTestConnection: createStandardAction(
    "[manage assets] set status for test connection"
  )<{
    connectionStatus: "none" | "started" | "connected" | "failedToConnect";
    connectionStatusBody: string;
  }>(),
  setMachineInformation: createStandardAction(
    "[manage assets] set details of machine information"
  )<any>(),
};

export type Actions = ActionType<typeof actions>;
