/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import * as styledComponents from "styled-components";
import { JellyFishTheme } from "@centralwebteam/jellyfish";

const {
  default: styled,
  css,
  ThemeProvider,
  createGlobalStyle,
  // @ts-ignore
  useTheme,
  keyframes,
} = styledComponents as unknown as styledComponents.ThemedStyledComponentsModule<JellyFishTheme>;

const _useThemeTypeOverriden = useTheme as () => JellyFishTheme;

export {
  styled,
  css,
  ThemeProvider,
  createGlobalStyle,
  _useThemeTypeOverriden as useTheme,
  keyframes,
};
export default styled;
