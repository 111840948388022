/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { rootActions } from "@/store";
import { create } from "@/presentation/Location";
import {
  all,
  put,
  call,
  delay,
  getContext,
  take,
  select,
} from "typed-redux-saga";
import constants from "@/constants";
import { getGlobalConfigs } from "@/index";
import orderBy from "lodash/fp/orderBy";
import uniqBy from "lodash/fp/uniqBy";
import { getType } from "typesafe-actions";
import { CMSClientType } from "@/cms-api";
import { t } from "i18next";
import { SystemPermissions } from "@centralwebteam/narwhal";
import { getUsersSaga } from "@/store/manageUsers/saga";
import { selectUsername } from "@/store/selectors";

export function* coreDataLoopSaga() {
  const config = yield* call(() => getGlobalConfigs());
  while (true) {
    try {
      if (!window.location.href.includes("process-updates"))
        yield* getCoreDataSaga();
    } catch (error) {
      console.log(error);
    }
    yield* delay(config.refreshRateCoreInMS);
  }
}

export function* getCoreDataSaga() {
  try {
    yield* all([
      getMachinesSaga(),
      getMachineTypesSaga(),
      getLocationsSaga(),
      getPreferencesSaga(),
      getUsersSaga(),
      setSystemPermissionSaga(),
    ]);
  } catch (error) {
    console.warn(`Core data: ${error}`);
  }
}

export function* dataLoadingPerformanceNotificationWatcher() {
  const config = yield* call(() => getGlobalConfigs());
  while (
    yield* take([
      getType(
        rootActions.machineAnalysis.timelineStaticDataWarningLimitBreached
      ),
      getType(rootActions.jobPerformance.jobSummariesDataWarningLimitBreached),
    ])
  ) {
    yield* put(rootActions.global.performanceNotificationRaised());
    yield* delay(config.performanceNotificationLockedTimeInMS);
  }
}

function* getMachinesSaga() {
  const config = yield* call(() => getGlobalConfigs());
  const client: CMSClientType = yield getContext("client");
  const machines = yield* call(() =>
    client.machines
      .all({
        query: {
          take: config.maxTake,
        },
      })
      .promise.then((res) => uniqBy("id", res))
  );
  yield* put(rootActions.global.setMachines(orderBy("name", "asc", machines)));
}

function* getMachineTypesSaga() {
  const client: CMSClientType = yield getContext("client");
  const machineTypes = yield* call(() => client.machines.types().promise);
  yield* put(rootActions.global.setMachineTypes(machineTypes));
}

function* getLocationsSaga() {
  const client: CMSClientType = yield getContext("client");
  const locations = yield* call(() =>
    client.locations.flat().promise.then((res) =>
      uniqBy("id", create(res)).concat({
        ...constants.unassignedMachinesLocation,
        name: t("label-Unassigned Machines"),
        trail: t("label-Unassigned Machines"),
      })
    )
  );
  yield* put(rootActions.global.setLocations(locations));
}

function* setSystemPermissionSaga() {
  try {
    const users = yield* select((s) => s.manageUsers.users);
    let username: string | undefined = yield* select(selectUsername);
    username = username.split("\\").pop();
    const user = users.find(
      (u: any) => u.email.toLowerCase() === username?.toLowerCase()
    );
    if (username && user) {
      const client: CMSClientType = yield getContext("client");
      const permissions = yield* call(async (id) => {
        const user = await client.users.byId(id).promise;
        return user && user.permissions
          ? (Object.keys(user.permissions) as SystemPermissions[]).filter(
              (key) => user.permissions![key]
            )
          : [];
      }, user.id);
      yield* put(
        rootActions.session.setSystemPermission({ username, permissions })
      );
    }
  } catch (e) {
    console.log(e);
  }
}

function* getPreferencesSaga() {
  try {
    const client: CMSClientType = yield getContext("client");
    const preferences = yield* call(() => client.preferences.all().promise);
    yield* put(rootActions.manageMyAccount.setPreferences(preferences));
  } catch (e) {
    console.log(e);
  }
}

// Fetch details for file Info Ids
export function* fetchFilesDetailsForFileInfoIds({
  payload: { fileInfoIds },
}: any) {
  const client: CMSClientType = yield getContext("client");
  const results = [];
  let response: any = {};
  try {
    for (const fileInfoId of fileInfoIds) {
      try {
        response = yield* call(
          () => client.files.filesDetailsByFileInfoId(fileInfoId).promise,
          fileInfoId
        );
        results.push(response);
      } catch (error) {
        console.error(error);
      }
    }
    yield* put(
      rootActions.global.setFilesDetailsForSelectedJob(
        results.map((obj) => obj[0])
      )
    );
  } catch (error) {
    console.error(error);
  }
}

// Download the file for selected file Id
export function* downloadFileForSelectedFileId({
  payload: { fileId, fileName, downloadFilename, fileExtension, extension },
}: any) {
  const client: CMSClientType = yield getContext("client");
  yield* put(rootActions.global.setDownloadFileState("downloading"));
  try {
    const response = yield* call(
      () => client.files.downloadFileByFileInfoId(fileId).promise
    );
    const fileNameToDownload = downloadFilename ?? fileName;
    const fileType = fileExtension ?? extension;

    const blob = new Blob([response]);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download =
      fileType !== undefined
        ? fileNameToDownload.replace(/\.[^.]+$/, "") + "." + fileType
        : fileNameToDownload;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    yield* put(rootActions.global.setDownloadFileState("success"));
    return response;
  } catch (error) {
    console.error(error);
    yield* put(rootActions.global.setDownloadFileState("fail"));
  }
}
