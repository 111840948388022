/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { RootState } from "@/store";
import { createSelector } from "reselect";
import { Machine, GUID } from "@centralwebteam/narwhal";
import memo from "memoize-one";
import { uniq, compact, flow, orderBy } from "lodash/fp";
import { Popup } from "@/store/state";

export const getMachines = (state: RootState) => state.global.machines;
export const getLocations = (state: RootState) => state.global.locations;
export const getMachineByIdFactory = (id: string) =>
  createSelector(getMachines, (machines) =>
    machines.find((machine) => machine.id === id)
  );

export const getUniqueMachineStates = memo((machines: Machine[]) => {
  return flow([orderBy([], []), uniq, compact])([
    ...machines.map((machine) => machine.machineStatus),
  ]) as string[];
});

export const getPopup = (id: string, popups: Popup[]) => {
  return popups.find((p) => p.id === id);
};

export const getMachinesWithExtraContent = createSelector(
  (state: RootState) => state.global.machines,
  (state: RootState) => state.currentStatus.machineAlerts,
  (state: RootState) => state.currentStatus.machineCarouselData,
  (state: RootState) => state.filter.active.alertLevel,
  (machines, machineAlerts, machineCarouselData, alertLevel) => {
    return machines.reduce((a: string[], { id }) => {
      if (
        (machineAlerts &&
          machineAlerts[id] !== undefined &&
          machineAlerts[id]!.some(
            (a) => !a.cleared && alertLevel.includes(a.level)
          )) ||
        (machineCarouselData &&
          machineCarouselData[id] !== undefined &&
          machineCarouselData[id]!.length)
      )
        a.push(id);
      return a;
    }, [] as GUID[]);
  }
);
