import i18n from "@/i18n";

/**
 * Translation function for using outside of React components. In components, use the useTranslation hook.
 * @param key translation key
 * @returns translated string, or key if no translation found
 */
export const t = (key: string | TemplateStringsArray, options?: any) => {
  let message = "";
  i18n.then((t) => {
    message = t(key, options) as string;
  });

  return message;
};
