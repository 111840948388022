/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, createAsyncAction, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import {
  TimeSeriesValueCompactValue,
  TimeSeries,
  CycleTime,
  Machine,
  TimeSeriesLimit,
} from "@centralwebteam/narwhal";
import { ViewMode } from "@/store/state";
import { MeasurementCharacteristicPresentation } from "@/presentation/MeasurementCharacteristic";
import { AlertPresentation } from "@/presentation/Alert";
import { JobPresentation } from "@/presentation/Job";

const fetchAlertsForMachine = createAsyncAction(
  "[current status] fetch alerts for machine request",
  "[current status] fetch alerts for machine success",
  "[current status] fetch alerts for machine error"
)<string, { id: string; data: AlertPresentation[] }, string>();

const fetchedMachineCycleData = createStandardAction(
  "[current status] fetch machine cycle data"
)<{ machineId: string; cycleTime: CycleTime }>();

const fetchJobSummariesForMachine = createAsyncAction(
  "[current status] fetch job summaries for machine request",
  "[current status] fetch job summaries for machine success",
  "[current status] fetch job summaries for machine error"
)<string, { id: string; data: JobPresentation[] }, string>();

const fetchMeasurementCharacteristicsForJob = createAsyncAction(
  "[current status] fetch measurementCharacteristics for job request",
  "[current status] fetch measurementCharacteristics for job success",
  "[current status] fetch measurementCharacteristics for job error"
)<
  string,
  { id: string; data: MeasurementCharacteristicPresentation[] },
  string
>();

const fetchMachineCarouselData = createAsyncAction(
  "[current status] fetch machine carousel sensor values for machine request",
  "[current status] fetch machine carousel sensor values for machine success",
  "[current status] fetch machine carousel sensor values for machine error"
)<
  string,
  {
    id: string;
    data: {
      sensor: TimeSeries;
      values: TimeSeriesValueCompactValue[];
      limits: TimeSeriesLimit[];
    }[];
  },
  string
>();

const setViewMode = createStandardAction(
  "[current status] set view mode"
)<ViewMode>();

const selectJobCycleTimes = createStandardAction(
  "[current status] select job cycle times "
)<{
  machine: Machine;
}>();

const alertBadgeClicked = createStandardAction("alert badge clicked")<{
  machineId: string;
}>();

const jobSummaryPopupMachineAnalysisButtonClicked = createStandardAction(
  "[current status] job summary popup machine analysis button clicked"
)<{ job: JobPresentation }>();

const currentStatusMachineNameClicked = createStandardAction(
  "[current status] machine name clicked"
)<{ machineId: string | null }>();

const currentStatusMachineIconClicked = createStandardAction(
  "[current status] machine icon clicked"
)<{ machineId: string | null }>();

const latestJobMarkerClicked = createStandardAction(
  "[current status] latest job marker clicked"
)<{ job: JobPresentation }>();

const jobMarkerClicked = createStandardAction(
  "[current status] job marker clicked"
)<{ job: JobPresentation }>();
jobMarkerClicked;
export const actions = {
  fetchAlertsForMachine,
  selectJobCycleTimes,
  setViewMode,
  fetchJobSummariesForMachine,
  fetchMachineCarouselData,
  fetchMeasurementCharacteristicsForJob,
  fetchedMachineCycleData,
  alertBadgeClicked,
  jobSummaryPopupMachineAnalysisButtonClicked,
  currentStatusMachineNameClicked,
  currentStatusMachineIconClicked,
  latestJobMarkerClicked,
  jobMarkerClicked,
};

export type Actions = ActionType<typeof actions>;
