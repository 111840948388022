/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
export function setMachineAnalysisPageTab(tabName: string) {
  localStorage.setItem("machine-analysis-tab", JSON.stringify(tabName));
}

export function getMachineAnalysisPageTab() {
  return JSON.parse(localStorage.getItem("machine-analysis-tab")!);
}
