/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { differenceInSeconds, max } from "date-fns";
import { MachineStatus, UPTIME_STATES } from "@centralwebteam/narwhal";

/**
 * Calculates the uptime from a collection of machine statuses.
 */
export function calculateUptime(
  states: MachineStatus[],
  startDate: Date,
  endDate: Date
) {
  // Because `states` can include values before `startDate` we want to start at
  // the index of the closest state before start or 0
  let closestStateIndexToStartDate = 0;
  for (let i = 0; i < states.length; i++) {
    const currentState = states[i];
    if (UPTIME_STATES.includes(currentState.status) === false) continue;
    else if (new Date(currentState.created) <= startDate) {
      closestStateIndexToStartDate = i;
    } else {
      break;
    }
  }
  const statesInTimeRange = states.slice(
    closestStateIndexToStartDate,
    states.length
  );
  let uptime = 0;
  for (let i = 0; i < statesInTimeRange.length; i++) {
    const currentState = statesInTimeRange[i];
    const currentStateCreatedDate = new Date(currentState.created);
    if (currentStateCreatedDate > endDate) break;
    if (!UPTIME_STATES.includes(currentState.status)) continue;
    const nextState = statesInTimeRange[i + 1];
    uptime += differenceInSeconds(
      !nextState ? endDate : new Date(nextState.created),
      // If first item and state is uptime then its created property might be before `startDate`
      i === 0
        ? max([startDate, new Date(currentState.created)])
        : new Date(currentState.created)
    );
  }
  return uptime;
}
