/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import { FilterKeys } from "@/store/state";

const addFilter = createStandardAction("[filter] add filter")<{
  key: FilterKeys;
  value: string;
}>();

const addSingleFilter = createStandardAction("[filter] add single filter")<{
  key: FilterKeys;
  value: string;
}>();

const removeFilter = createStandardAction("[filter] remove filter")<{
  key: FilterKeys;
  value?: string;
}>();

const clear = createStandardAction("[filter] clear")();

// empties it
const clearDraft = createStandardAction("[filter] clear draft")<FilterKeys>();

// resets it to equal active
const resetDraft = createStandardAction("[filter] reset draft")<FilterKeys>();

const applyDraft = createStandardAction("[filter] apply draft")<FilterKeys>();

export const actions = {
  addFilter,
  addSingleFilter,
  removeFilter,
  clearDraft,
  resetDraft,
  clear,
  applyDraft,
};

export type Actions = ActionType<typeof actions>;
