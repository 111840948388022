/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
/**
 * Represents whether the event marks the alert being triggered or the alert being cleared.
 * @readonly
 * @enum types
 */
export enum AlertState {
  /** Alert being cleared. */
  unset = "unset",
  /** Alert being triggered. */
  set = "set",
}

/**
 * Maps a boolean to an alert type, as a type is more descriptive and verbose than a boolean.
 *
 * @param {Boolean} type If true or undefined, will return type of 'set', or if false 'unset'.
 */
export const stateMapper = (type: boolean | undefined) =>
  type === undefined
    ? AlertState.set
    : type
      ? AlertState.set
      : AlertState.unset;
