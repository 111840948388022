/*
 * © 2023 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */

import { typedObjectKeys } from "../typedObjectKeys";

/** removeUndefinedProps filters an object to its defined properties */
export const removeUndefinedProps = <
  T extends Record<string, unknown> | undefined,
>(
  o: T
) => {
  let result = {} as Partial<T>;
  if (!o) return o;
  for (const k of typedObjectKeys(o)) {
    const value = o![k];
    if (typeof value === "object") {
      result = {
        ...result,
        [k]: removeUndefinedProps(value as Record<string, unknown>),
      };
    } else if (value !== undefined) {
      result = { ...result, [k]: value };
    }
  }
  return result;
};
