/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  select,
  getContext,
  call,
  put,
  race,
  take,
  cancel,
  cancelled,
} from "typed-redux-saga";
import { getType } from "typesafe-actions";
import i18n from "@/i18n";
import { rootActions } from "@/store";
import { setNotification } from "../global/thunks";
import { selectUsername } from "../selectors";
import { CentralUser, EntityPermission } from "@centralwebteam/narwhal";
import { notificationTypes } from "@centralwebteam/jellyfish";
import { notUndefined } from "@/@types/guards/notUndefined";
import { CMSClientType } from "@/cms-api";
import { getErrorMessage } from "@/axios/errors";

export function* createUserSaga({ payload: newAccount }: any) {
  let userCreated = "",
    permissionFailed = "",
    userCreateFailed = "";
  i18n.then((t) => {
    userCreated = t("message-User created");
    permissionFailed = t("message-userPermissionSetFailed");
    userCreateFailed = t("message-userCreateFailed");
  });
  try {
    const client: CMSClientType = yield getContext("client");
    // returns an array
    const [userId]: any = yield* call(
      (newAccount) => client.users.add(newAccount).promise,
      newAccount
    );
    yield* put(
      // @ts-ignore
      setNotification(
        userCreated,
        [newAccount.email],
        notificationTypes.success
      )
    );
    yield* put(
      rootActions.manageUsers.userCreated({
        userId,
      })
    );
    if (!userId) {
      yield* put(
        // @ts-ignore
        setNotification(permissionFailed, [], notificationTypes.warning)
      );
      return;
    }
    try {
      // if there are location entity permissions set them on the newly created user
      if (newAccount.locationEntityPermissions?.length) {
        // as this is a brand new user with no existing permissions we don't need to deal with "none" (deletes)
        const readAdminUserLocations =
          newAccount.locationEntityPermissions.filter((lep: EntityPermission) =>
            ["Read", "Admin"].includes(lep.permission)
          );
        if (readAdminUserLocations.length > 0)
          yield* call(permission.updateLocationEntityPermission, {
            userId,
            readAdminUserLocations,
          });
      }
    } catch (error: any) {
      yield* put(
        // @ts-ignore
        setNotification(
          permissionFailed,
          [error.message],
          notificationTypes.error
        )
      );
    }
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        userCreateFailed,
        [newAccount.email!],
        notificationTypes.error
      )
    );
  } finally {
    yield* call(getUsersSaga);
  }
}

export function* updateUserSaga({ payload: user }: any) {
  const client: CMSClientType = yield getContext("client");
  let userUpdated = "",
    permissionFailed = "",
    locationPermissionFailed = "";
  i18n.then((t) => {
    userUpdated = t("message-User updated");
    permissionFailed = t("message-userPermissionSetFailed");
    locationPermissionFailed = t("message-userLocationPermissionSetFailed");
  });
  try {
    yield* call(
      (user) =>
        client.users.updatePermissions(user.id, user.permissions).promise,
      user
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(permissionFailed, [user.email], notificationTypes.error)
    );
  }

  try {
    if (user.locationEntityPermissions?.length) {
      const permissions = user.locationEntityPermissions as EntityPermission[];
      // Delete permissions marked as "None"
      // Permission inheritance rules mean we need to delete the existing read/admin permission before adding
      const noPermissionLocationIds = permissions
        .filter(notUndefined)
        .filter(({ permission }) => permission === "None")
        .map((item) => item.entityId);
      if (noPermissionLocationIds.length > 0) {
        yield* call(permission.deleteLocationEntityPermission, {
          userId: user.id,
          locationIds: noPermissionLocationIds,
        });
      }
      // Save all Read and Admin permissions
      const readAdminUserLocations = permissions.filter(({ permission }) =>
        ["Read", "Admin"].includes(permission)
      );
      if (readAdminUserLocations.length > 0)
        yield* call(permission.updateLocationEntityPermission, {
          userId: user.id,
          readAdminUserLocations,
        });
    }

    yield* put(
      // @ts-ignore
      setNotification(userUpdated, [user.email], notificationTypes.success)
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        locationPermissionFailed,
        [user.email],
        notificationTypes.error
      )
    );
  }

  yield* call(getUsersSaga);
}

export function* activateUserSaga({ payload: user }: any) {
  let userActivated = "",
    activated = "",
    userActivateFailed = "";
  i18n.then((t) => {
    userActivated = t("message-User activated");
    activated = t("message-namedObjectActivated", { name: user.email });
    userActivateFailed = t("message-userActivateFailed");
  });
  try {
    const client: CMSClientType = yield getContext("client");
    yield* call((user) => client.users.activate(user.id).promise, user);
    yield* put(
      // @ts-ignore
      setNotification(userActivated, [activated], notificationTypes.success)
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        userActivateFailed,
        [getErrorMessage(error)],
        notificationTypes.error
      )
    );
  } finally {
    yield* call(getUsersSaga);
  }
}

export function* deactivateUserSaga({ payload: user }: any) {
  let userDeactivated = "",
    deactivated = "",
    userDeactivateFailed = "";
  i18n.then((t) => {
    userDeactivated = t("message-User deactivated");
    deactivated = t("message-namedObjectDeactivated", { name: user.email });
    userDeactivateFailed = t("message-userDeactivateFailed");
  });
  try {
    const client: CMSClientType = yield getContext("client");
    yield* call((user) => client.users.deactivate(user.id).promise, user);
    yield* put(
      // @ts-ignore
      setNotification(userDeactivated, [deactivated], notificationTypes.success)
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        userDeactivateFailed,
        [getErrorMessage(error)],
        notificationTypes.error
      )
    );
  } finally {
    yield* call(getUsersSaga);
  }
}

export function* deleteUserSaga({ payload: user }: any) {
  let userDeleted = "",
    deleted = "",
    userDeleteFailed = "";
  i18n.then((t) => {
    userDeleted = t("message-User deleted");
    deleted = t("message-namedObjectDeleted", { name: user.email });
    userDeleteFailed = t("message-userDeleteFailed");
  });
  try {
    yield* put(rootActions.manageUsers.confirmDeletion());
    const { cancelled } = yield* race({
      cancelled: take(getType(rootActions.manageUsers.cancelButtonClicked)),
      confirmed: take(getType(rootActions.manageUsers.confirmButtonClicked)),
    });
    if (cancelled) {
      yield* cancel();
    }

    const client: CMSClientType = yield getContext("client");

    yield* call((user) => client.users.delete(user.id).promise, user);
    yield* put(
      // @ts-ignore
      setNotification(userDeleted, [deleted], notificationTypes.success)
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(userDeleteFailed, [], notificationTypes.error)
    );
  } finally {
    if (!(yield* cancelled())) {
      yield* call(getUsersSaga);
    }
  }
}

export function* getUsersSaga() {
  try {
    const client: CMSClientType = yield getContext("client");
    const users = yield* call(() => client.users.all().promise);
    const username = yield* select(selectUsername);
    if (!users) return;
    const current: string | undefined = username.split("\\").pop(); //gets the current user without tenancy prefix
    yield* put(
      rootActions.manageUsers.usersFetched(
        // @ts-ignore
        users.map((u: CentralUser) => {
          return {
            ...u,
            ...{
              isCurrentUser: u.email.toLowerCase() === current?.toLowerCase(),
            },
          };
        })
      )
    );
  } catch (error) {
    console.error(error);
  }
}

const permission = {
  updateLocationEntityPermission: function* updateLocationEntityPermission({
    userId,
    readAdminUserLocations,
  }: {
    userId: string;
    readAdminUserLocations: EntityPermission[];
  }) {
    const client: CMSClientType = yield getContext("client");

    yield* call(
      (userId) =>
        client.users.updateLocationPermissions(userId, readAdminUserLocations)
          .promise,
      userId
    );
  },
  deleteLocationEntityPermission: function* deleteLocationEntityPermission({
    userId,
    locationIds,
  }: {
    userId: string;
    locationIds: string[];
  }) {
    const client: CMSClientType = yield getContext("client");

    yield* call(
      (userId) =>
        client.users.deleteLocationPermissions(userId, locationIds).promise,
      userId
    );
  },
};
