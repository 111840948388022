/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { all, takeLatest, takeEvery } from "redux-saga/effects";

import { getType } from "typesafe-actions";

import { rootActions } from "..";

import {
  createUserSaga,
  updateUserSaga,
  activateUserSaga,
  deactivateUserSaga,
  deleteUserSaga,
  getUsersSaga,
} from "./saga";

export function* manageUsersRootSaga() {
  // initially load users
  yield getUsersSaga();
  // define takes
  yield all([
    takeLatest(
      getType(rootActions.manageUsers.createNewUserButtonClicked),
      createUserSaga
    ),
    takeLatest(
      getType(rootActions.manageUsers.saveChangesButtonClicked),
      updateUserSaga
    ),
    takeEvery(
      getType(rootActions.manageUsers.activateUserButtonClicked),
      activateUserSaga
    ),
    takeEvery(
      getType(rootActions.manageUsers.deactivateUserButtonClicked),
      deactivateUserSaga
    ),
    takeLatest(
      getType(rootActions.manageUsers.deleteUserButtonClicked),
      deleteUserSaga
    ),
  ]);
}
