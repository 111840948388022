/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { lazy } from "react";
import { SystemPermissions } from "@centralwebteam/narwhal";
import URLPattern from "url-pattern";

export const routes = [
  "reset password",
  "current status",
  "job performance",
  "manage",
  "manage users",
  "manage assets",
  "manage provisioning",
  "manage myaccount",
  "machine analysis",
  "machine performance",
  "manage clients",
  "manage notifications",
  "process updates",
] as const;

export type Routes = (typeof routes)[number];
export const publicRoutes: Routes[] = ["reset password"];

export const mapRouteNameToPath: { [key in Routes]: URLPattern } = {
  "current status": new URLPattern("/current-status"),
  "job performance": new URLPattern("/job-performance"),
  manage: new URLPattern("/manage"),
  "manage assets": new URLPattern("/manage/assets"),
  "reset password": new URLPattern("/reset-password"),
  "manage provisioning": new URLPattern("/manage/provisioning"),
  "manage users": new URLPattern("/manage/users"),
  "manage clients": new URLPattern("/manage/clients"),
  "manage notifications": new URLPattern("/manage/notifications"),
  "manage myaccount": new URLPattern("/manage/myaccount"),
  "machine analysis": new URLPattern("/machine-analysis"),
  "machine performance": new URLPattern("/machine-performance"),
  "process updates": new URLPattern("/process-updates"),
};

export const getRouteNameFromPath = (path: string): Routes | undefined =>
  Object.keys(mapRouteNameToPath).find((key) =>
    mapRouteNameToPath[key as Routes].match(path[0] === "/" ? path : "/" + path)
  ) as Routes;

export const mapRouteNameToComponent: {
  [key in Routes]: React.LazyExoticComponent<any>;
} = {
  "current status": lazy(() => import("@/handlers/CurrentStatus")),
  "job performance": lazy(() => import("@/handlers/JobPerformance")),
  manage: lazy(() => import("@/handlers/Manage")),
  "manage assets": lazy(() => import("@/handlers/Manage")),
  "manage provisioning": lazy(() => import("@/handlers/Manage")),
  "manage users": lazy(() => import("@/handlers/Manage")),
  "manage myaccount": lazy(() => import("@/handlers/Manage")),
  "manage clients": lazy(() => import("@/handlers/Manage")),
  "manage notifications": lazy(() => import("@/handlers/Manage")),
  "reset password": lazy(() => import("@/handlers/ResetPassword")),
  "machine analysis": lazy(() => import("@/handlers/MachineAnalysis")),
  "machine performance": lazy(() => import("@/handlers/MachinePerformance")),
  "process updates": lazy(() => import("@/handlers/ProcessUpdates")),
};

export const mapPathToRouteName: { [key: string]: Routes } = {
  "/current-status": "current status",
  "/job-performance": "job performance",
  "/manage/assets": "manage assets",
  "/reset-password": "reset password",
  "/manage/provisioning": "manage provisioning",
  "/manage/users": "manage users",
  "/manage/clients": "manage clients",
  "/manage/notifications": "manage notifications",
  "/manage/myaccount": "manage myaccount",
  "/machine-analysis": "machine analysis",
  "/machine-performance": "machine performance",
  "/process-updates": "process updates",
};

export const mapRouteNameToSystemPermissions: {
  [key in Routes]: SystemPermissions[];
} = {
  "current status": [],
  "job performance": [],
  manage: [],
  "manage assets": ["dataAdmin", "locationCreator"],
  "manage users": ["tenantAdmin"],
  "manage provisioning": ["dataAdmin"],
  "reset password": [],
  "manage myaccount": [],
  "machine analysis": [],
  "machine performance": [],
  "manage clients": ["tenantAdmin"],
  "manage notifications": [],
  "process updates": [],
};
