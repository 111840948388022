/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getType } from "typesafe-actions";
import { calculateJobPerformanceSaga } from "./sagas";
import { rootActions } from "../";
import { all, race, takeLatest, take } from "typed-redux-saga";
import {
  fetchFilesDetailsForFileInfoIds,
  downloadFileForSelectedFileId,
} from "../global/sagas";

export function* jobPerformanceRootSaga() {
  // Initial work
  yield* all([
    yield* race({
      task: calculateJobPerformanceSaga(),
      jobSummaryPopupFilesTabClickedWatcher: takeLatest(
        getType(rootActions.global.jobSummaryPopupFilesTabClicked),
        fetchFilesDetailsForFileInfoIds
      ),
      downloadFileWatcher: takeLatest(
        getType(rootActions.global.jobSummaryPopupDownloadFileButtonClicked),
        downloadFileForSelectedFileId
      ),
      cancel: take(getType(rootActions.session.startEndUpdated)),
    }),
    yield* takeLatest(
      getType(rootActions.session.startEndUpdated),
      calculateJobPerformanceSaga
    ),
    yield* takeLatest(
      getType(rootActions.global.jobSummaryPopupFilesTabClicked),
      fetchFilesDetailsForFileInfoIds
    ),
    yield* takeLatest(
      getType(rootActions.global.jobSummaryPopupDownloadFileButtonClicked),
      downloadFileForSelectedFileId
    ),
  ]);
}
