/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import Axios, { Canceler } from "axios";
import axios from "./index";
import { QueryOptions } from "@/cms-api/index";

export type RequestConfig = {
  method?: "get" | "patch" | "put" | "post" | "delete";
  url: string;
  params?: QueryOptions | URLSearchParams;
  data?: any;
  headers?: any;
  responseType?: any;
};

const CancelToken = Axios.CancelToken;

export type CancellableAxiosRequest<T> = {
  promise: Promise<T>;
  cancel: Canceler;
};

// Cancelling promises is good practice, this function returns a cancel function along with the promise
export const cancellableAxios = <T = undefined>(
  config: RequestConfig
): CancellableAxiosRequest<T> => {
  const source = CancelToken.source();
  return {
    promise: axios.request<T, T>({
      ...config,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...config.headers,
      },
      cancelToken: source.token,
    }),
    cancel: source.cancel,
  };
};

export function createSwitchFetch(
  existingCancellations?: [string, Canceler][]
) {
  const map = new Map<string, Canceler>(existingCancellations);
  /**
   * @description Switch fetch will execute any CancellableAxios request, but will cancel an existing request with the same id first. The supplied ID will also be used in the cancellation log for reference.
   */
  const switchFetch =
    (id: string) =>
    <T>(request: CancellableAxiosRequest<T>) => {
      const existingFunc = map.get(id);
      if (existingFunc)
        existingFunc(`Cancelled by newer request with same id (${id}).`);
      map.set(id, request.cancel);
      return request.promise;
    };
  return switchFetch;
}
