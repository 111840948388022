/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
/* ESLint incorrectly flags some escaped characters in regular expressions */
/* eslint-disable no-useless-escape */

import i18n from "@/i18n";

/**
 * Make a URI-encoded data URL of an image.
 * Use this for text-based formats (svg).
 * @param data Source data, e.g. SVG markup
 * @param mimeType Type of data, e.g. "image/svg+xml" (default)
 * Chris Coyier says don't base64 SVGs: https://css-tricks.com/probably-dont-base64-svg/
 */
export const toDataUrl = (data: string, mimeType = "image/svg+xml") => {
  return `data:${mimeType},${encodeURIComponent(data)}`;
};

/**
 * Remove non-word characters from both ends of a string, allowing parentheses and some unit symbols
 * @param input string to process
 */
export const trimPunctuation = (input: string) => {
  const trimmer =
    /^[^-\w\(]*((?=[-\w\(]).*[\w\)\.…°℃℉µΩ′″%]+)[^\w\)\.…°℃℉µΩ′″%]*$/;
  return trimmer.exec(input)?.[1] ?? "";
};

const ignoreCamelCaseWords = ["ID", "IP", "API"];
/**
 * Converts "testTest" to "Test Test".
 */
export const camelToSentence = (str: string) => {
  if (ignoreCamelCaseWords.includes(str.toUpperCase()))
    return str.toUpperCase();
  if (/\s/.test(str)) {
    return str
      .split(" ")
      .map((item) =>
        ignoreCamelCaseWords.includes(item.toUpperCase())
          ? item.toUpperCase()
          : item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
      )
      .join(" ");
  }
  const correctExtraCapitals = str.replace(
    new RegExp(ignoreCamelCaseWords.join("|"), "g"),
    function (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
  );
  const wordsWithSpaceAsSentence = correctExtraCapitals
    // insert a space before all caps
    .replace(/\B([A-Z]|\d+)/g, " $1");
  return (
    wordsWithSpaceAsSentence
      .split(" ")
      .map((word) =>
        ignoreCamelCaseWords.includes(word.toUpperCase())
          ? word.toUpperCase()
          : word
      )
      .join(" ")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

/**
 * Searches for `term` as a substring of `corpus`.
 * Returns true if a match was found, and also if `term` is effectively empty.
 * @param term Search query
 * @param corpus String to search
 */
export const looseSearch = (term: string, corpus: string) => {
  const cleanTerm = trimPunctuation(term).toLowerCase();
  if (!cleanTerm.length) return true;
  return trimPunctuation(corpus).toLowerCase().includes(cleanTerm);
};

/**
 * Checks whether two strings match, ignoring punctuation, spaces and case.
 * E.g. "truePosition" would match "true position" or "TRUE_POSITION".
 */
export const looseMatch = (a: string | undefined, b: string | undefined) => {
  if (a === undefined && b === undefined) return true;
  if (a === undefined || b === undefined) return false;
  const nonWord = /[-_\W]+/g;
  return (
    a.replace(nonWord, "").toLowerCase() ===
    b.replace(nonWord, "").toLowerCase()
  );
};

/**
 * Determines whether a string is in YYYY-MM-DDTHH:MN:SS.MSSZ format
 * @param str
 */
export const isIsoDate = (str: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d.toISOString() === str;
};

const prepareSort = (s: string) => {
  let select = "Select";
  let other = "Other";
  i18n.then((t) => {
    select = t`message-selectDefault`;
    other = t`message-Other`;
  });

  // "!" sorts before alphanumeric characters. "μ" after them.
  return s
    .replace(new RegExp(`^${select}\\b`, "i"), "!!")
    .replace(new RegExp(`^${other}\\b`, "i"), "μμ");
};

/** Compare function for `sort`, for use on lists of options.
 * Orders strings as usual, except that "Select" comes first and "Other" comes last. */
export const compareOptions = (
  a: string | null | undefined,
  b: string | null | undefined
) => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return prepareSort(a).localeCompare(prepareSort(b));
};

export const removeIllegalCharacters = (str: string) => {
  return str.replace(/[^\w:.-]+/gi, "");
};

export const copyToClipboard = (str: string) => {
  navigator.clipboard.writeText(str);
};

export function arraysMatch<T extends (number | string | boolean)[]>(
  active: T,
  list: T
) {
  return (
    active &&
    list &&
    active.length === list.length &&
    list.every((x) => active.includes(x))
  );
}
