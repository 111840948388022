/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { format, subDays, isSameDay, startOfDay, addDays } from "date-fns";
import { formatNonNumber } from "../tidyNumber";
import { currentLocaleSettings } from "@centralwebteam/jellyfish";

export const formatLocale = (date: number | Date, formatStr: string) => {
  return format(date, formatStr, currentLocaleSettings);
};

/**
 * Standard set of date and time formats used with date-fns (v2)
 * TODO: source date formats from translation file (abandoned in branch "manatee/extract-strings" because it's tricky)
 */
export enum DateFormat {
  /** Time without seconds only, e.g. "14:15" */
  TimeNoSeconds = "HH:mm",
  /** Time only, e.g. "14:15:16" */
  Time = "HH:mm:ss",
  /** Date with abbreviated day of week, e.g. "Mon 1 Oct 2018" */
  LongDate = "eee d MMM yyyy",
  /** Date without day of week, e.g. "01 Oct 2018" */
  ShortDate = "dd MMM yyyy",
  /** ISO date without milliseconds, e.g. "2018-10-01T14:15:00Z". The API query endpoints require second precision. */
  ShortISOString = "yyyy-MM-dd'T'HH:mm:ssXXX",
  /** Date and time with abbreviated day of week, e.g. "Mon 1 Oct 2018 14:15:16" */
  LongDateTime = "eee d MMM yyyy HH:mm:ss",
  /** Compact date and time, e.g. "01 Oct 14:15" */
  ShortDateTime = "dd MMM HH:mm",
  /** Date without year, e.g. "01 Oct" */
  ShortDayAndMonth = "dd MMM",
  /** Month and year, e.g. "Oct 2018" */
  ShortMonthAndYear = "MMM yyyy",
  /** Day-of-Week Date Month without year, e.g "Tuesday 18 November" */
  LongDayOfWeekAndDateAndMonth = "eeee dd MMMM",
  /** Short day of week only, e.g. "Mo", "Tu" */
  ShortWeekday = "cccccc",
}

/**
 * Converts date to standard time format without seconds, e.g. "09:17"
 * @param {date} date
 * @returns {string}
 */
export const toHoursMinutesColons = (date: Date) => {
  return format(date, DateFormat.TimeNoSeconds, currentLocaleSettings);
};

/**
 * Converts date to standard time format, e.g. "09:17:33"
 * @param {date} date
 * @returns {string}
 */
export const toHoursMinutesSecondsColons = (date: Date) => {
  return format(date, DateFormat.Time, currentLocaleSettings);
};

/**
 * Converts date to short time format, e.g. "01 Oct 18"
 * @param {date} date
 * @returns {string}
 */
export const toShort = (date: Date) => {
  return format(date, DateFormat.ShortDate, currentLocaleSettings);
};

/**
 * Converts date to ISO date without milliseconds, e.g. "2018-10-01T14:15:16Z"
 * @param {date} date
 * @returns {string}
 */
export const toShortISOString = (date: Date) => {
  return format(date, DateFormat.ShortISOString, currentLocaleSettings);
};

/**
 * Converts date to standard format, e.g. "Mon 30 Jan 2022"
 * @param {date} date
 * @returns {string}
 */
export const toDay3DateMonth3Year = (date: Date) => {
  return format(date, DateFormat.LongDate, currentLocaleSettings);
};

/**
 * Converts date to standard format with time, e.g. "Mon 1 Oct 2018 14:15:16"
 * @param date
 */
export const standardDateAndTimeFormat = (date: Date) => {
  return format(date, DateFormat.LongDateTime, currentLocaleSettings);
};

/**
 * Converts date to standard format, e.g. "Tuesday 18 November"
 * @param date
 */
export const standardDayAndMonthFormat = (date: Date) => {
  return format(
    date,
    DateFormat.LongDayOfWeekAndDateAndMonth,
    currentLocaleSettings
  );
};

/**
 * Formats a timespan appropriately for its length.
 * //TODO: translations (we can't just translate "y", "m", "d" etc., these need to be interpolated strings)
 * @param timespan A number of seconds
 */
export const conditionalTimespanFormat = (timespan: number) => {
  if (!timespan || !isFinite(timespan)) return formatNonNumber(timespan);

  const zeroPad = (n: number) => {
    return Math.floor(n).toString().padStart(2, "0");
  };

  const { years, days, hours, minutes, seconds } = millisecondsTo;
  const ms = timespan * seconds;

  if (ms >= years) {
    return `${zeroPad(ms / years)}y ${zeroPad((ms % years) / days)}d`;
  }
  if (ms > days) {
    return `${zeroPad(ms / days)}d ${zeroPad((ms % days) / hours)}h`;
  }
  if (ms > hours) {
    return `${zeroPad(ms / hours)}h ${zeroPad((ms % hours) / minutes)}m`;
  }
  if (ms > minutes) {
    return `${zeroPad(ms / minutes)}m ${zeroPad((ms % minutes) / seconds)}s`;
  }
  return `${(ms / seconds).toPrecision(2)}s`;
};

/**
 * Formats a timespan appropriately for its length.
 * @param timespan A number of seconds
 */
export const fixedTimespanFormat = (timespan: number) => {
  const { hours, minutes, seconds } = millisecondsTo;
  const ms = timespan * seconds;
  const zeroPad = (n: number) => {
    return Math.floor(n).toString().padStart(2, "0");
  };
  // TODO: Translation by interpolated string
  return `${zeroPad(ms / hours)}:${zeroPad((ms % hours) / minutes)}:${zeroPad(
    (ms % minutes) / seconds
  )}`;
};

export const millisecondsTo = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
  months: 1000 * 60 * 60 * 24 * 30,
  years: 1000 * 60 * 60 * 24 * 365,
};

export const conditionalDateFormat = (
  date: Date,
  domainMs: number
): { main: string; sub: string } => {
  if (domainMs < 10 * millisecondsTo.seconds) {
    return {
      main: format(date, "HH:mm:ss.SSS", currentLocaleSettings),
      sub: format(date, "E dd MMM yyyy", currentLocaleSettings),
    };
  } else if (domainMs < millisecondsTo.hours) {
    return {
      main: format(date, "HH:mm:ss", currentLocaleSettings),
      sub: format(date, "E dd MMM yyyy", currentLocaleSettings),
    };
  } else if (domainMs < millisecondsTo.days) {
    return {
      main: format(date, "HH:mm", currentLocaleSettings),
      sub: format(date, "E dd MMM yyyy", currentLocaleSettings),
    };
  } else if (domainMs < 3 * millisecondsTo.days) {
    return {
      main: format(date, "HH:mm", currentLocaleSettings),
      sub: format(date, "dd MMM yyyy", currentLocaleSettings),
    };
  } else if (domainMs < millisecondsTo.months) {
    return {
      main: format(date, "E dd MMM", currentLocaleSettings),
      sub: format(date, "yyyy", currentLocaleSettings),
    };
  } else if (domainMs < millisecondsTo.years) {
    return {
      main: format(date, "dd MMM", currentLocaleSettings),
      sub: format(date, "yyyy", currentLocaleSettings),
    };
  } else {
    return {
      main: format(date, "MMM", currentLocaleSettings),
      sub: format(date, "yyyy", currentLocaleSettings),
    };
  }
};

/**
 * Format a time range
 * @param interval date-fns Interval object, containing 'start' and 'end' dates
 */
export const formatInterval = (interval: Interval | undefined) => {
  if (typeof interval === "undefined") {
    return "";
  }
  if (isSameDay(interval.start, interval.end)) {
    // Start and end are the same day - just print the date once
    return `${format(
      interval.start,
      DateFormat.LongDate,
      currentLocaleSettings
    )}, ${format(
      interval.start,
      DateFormat.Time,
      currentLocaleSettings
    )} – ${format(interval.end, DateFormat.Time, currentLocaleSettings)}`;
  }
  return `${format(
    interval.start,
    DateFormat.LongDateTime,
    currentLocaleSettings
  )} – ${format(interval.end, DateFormat.LongDateTime, currentLocaleSettings)}`;
};

/**
 * Returns the date and time, rounded up to the next minute
 * @param {Date} d date to round, defaulting to the current instant
 */
export const nextMinute = (d: Date = new Date()) => {
  return new Date((((d.valueOf() / 60000) >> 0) + 1) * 60000);
};

/**
 * Returns the date and time, rounded up to the next minute, as an ISO formatted string
 * @param {Date} d date to round, defaulting to the current instant
 * @returns {string} ISO date without milliseconds, e.g. "2018-10-01T14:15:00Z"
 */
export const nextMinuteISOString = (d: Date = new Date()) => {
  return toUTCISOString(nextMinute(d));
};

/**
 * Returns the current date and time as an ISO formatted string
 * @returns {string} ISO date without milliseconds, e.g. "2018-10-01T14:15:00Z"
 */
export const nowISOString = () => {
  return toUTCISOString(new Date());
};

const to2 = (n: number) => n.toString().padStart(2, "0");

/**
 * Returns a date in UTC as an ISO formatted string with second precision and "Z" timezone.
 * @returns {string} ISO date without milliseconds, e.g. "2018-10-01T14:15:00Z"
 */
export const toUTCISOString = (d: Date) => {
  return `${d.getUTCFullYear()}-${to2(d.getUTCMonth() + 1)}-${to2(
    d.getUTCDate()
  )}T${to2(d.getUTCHours())}:${to2(d.getUTCMinutes())}:${to2(
    d.getUTCSeconds()
  )}Z`;
};

/**
 * Returns a date in UTC as an ISO formatted string with millisecond precision and "Z" timezone.
 * @returns {string} ISO date with milliseconds, e.g. "2018-10-01T14:15:00.123Z"
 */
export const toUTCISOStringMs = (d: Date) => {
  return `${d.getUTCFullYear()}-${to2(d.getUTCMonth() + 1)}-${to2(
    d.getUTCDate()
  )}T${to2(d.getUTCHours())}:${to2(d.getUTCMinutes())}:${to2(
    d.getUTCSeconds()
  )}.${d.getUTCMilliseconds()}Z`;
};

export const last24Hours = () => {
  const now = new Date();
  return {
    from: nextMinuteISOString(subDays(now, 1)),
    to: nextMinuteISOString(now),
  };
};

export const last28Days = () => {
  const now = new Date();
  return {
    from: toShortISOString(startOfDay(subDays(now, 27))),
    to: toShortISOString(startOfDay(addDays(now, 1))),
  };
};

/** Adds one millisecond to a date string, returning it as an ISO string */
export const nextMillisecondISOString = (dateString: string) => {
  const d = Date.parse(dateString);
  return toUTCISOStringMs(new Date(d + 1));
};

// TODO: not everyone starts the week on Monday. Use date-fns to get the local preference: https://date-fns.org/v2.28.0/docs/startOfWeek
/** Short weekday names, e.g. "Mo", "Tu"... */
export const weekdays = [5, 6, 7, 8, 9, 10, 11].map((d) =>
  formatLocale(new Date(1970, 0, d), DateFormat.ShortWeekday)
);
