/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getType } from "typesafe-actions";
import { all, takeLatest, call } from "typed-redux-saga";
import { actions } from "./actions";
import {
  fetchMachineJobCycleTimeSaga,
  currentStatusMountedBG,
  watchViewMode,
} from "./saga";
import { rootActions } from "../rootActions";
import {
  fetchFilesDetailsForFileInfoIds,
  downloadFileForSelectedFileId,
} from "../global/sagas";

export function* rootSaga() {
  yield* all([
    call(currentStatusMountedBG),
    takeLatest(
      getType(actions.fetchJobSummariesForMachine.success),
      fetchMachineJobCycleTimeSaga
    ),
    yield* takeLatest(
      getType(rootActions.global.jobSummaryPopupFilesTabClicked),
      fetchFilesDetailsForFileInfoIds
    ),
    yield* takeLatest(
      getType(rootActions.global.jobSummaryPopupDownloadFileButtonClicked),
      downloadFileForSelectedFileId
    ),
    call(watchViewMode),
  ]);
}
