/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { JobSummary, GUID, Job, isJobMetrology } from "@centralwebteam/narwhal";
import { MeasurementCharacteristicPresentation } from "../MeasurementCharacteristic";
import {
  Verdict as VerdictType,
  VerdictGroup as VerdictGroupType,
  mapStringToVerdict,
} from "./verdict";
import {
  Status as StatusType,
  StatusGroup as StatusGroupType,
  mapStringToStatus,
} from "./status";

export {
  verdicts,
  verdictGroups,
  mapVerdictToGroup,
  mapStringToVerdict,
  MapVerdictTodisplayString,
} from "./verdict";
export {
  statuses,
  statusGroups,
  mapStatusToGroup,
  mapStringToStatus,
  MapStatusToDisplayString,
} from "./status";

// Verbose export style to avoid Webpack warnings
export type Verdict = VerdictType;
export type VerdictGroup = VerdictGroupType;
export type Status = StatusType;
export type StatusGroup = StatusGroupType;

export type JobPresentation = {
  id: GUID;
  machineId: GUID;
  name: string;
  start: string;
  end?: string;
  verdict: Verdict;
  status: Status;
  active?: boolean;
} & (
  | {
      type: "metrology" | "mastering";
      passes?: number;
      failures?: number;
      warnings?: number;
      notToleranced?: number;
      measurementCharacteristics?: MeasurementCharacteristicPresentation[];
    }
  | {
      type: "additive";
      lastCompletedLayer: number;
      expectedLayers: number;
    }
);

export function createJobPresentation(
  job: JobSummary | Job,
  measurements?: MeasurementCharacteristicPresentation[]
): JobPresentation {
  if (isJobMetrology(job)) {
    return {
      type: "metrology",
      id: job.id,
      name: job.name,
      start: job.start,
      end: job.end,
      passes: job.passes ?? 0,
      failures: job.failures ?? 0,
      warnings: job.warnings ?? 0,
      notToleranced: job.notToleranced ?? 0,
      machineId: job.machineId,
      status: mapStringToStatus("metrology", job.status),
      verdict: mapStringToVerdict(job.verdict),
      measurementCharacteristics: measurements,
    };
  } else
    return {
      type: "additive",
      id: job.id,
      name: job.name,
      start: job.start,
      end: job.end,
      expectedLayers: job.expectedLayers!,
      lastCompletedLayer: job.lastCompletedLayer!,
      machineId: job.machineId,
      status: mapStringToStatus("additive", job.status),
      verdict: mapStringToVerdict(job.verdict),
    };
}

/**
 * Creates a NEW job presentation with the supplied measurement data.
 */
export function withMeasurementData(
  job: JobPresentation,
  measurements: MeasurementCharacteristicPresentation[]
): JobPresentation {
  if (job.type !== "metrology") return job;
  return {
    ...job,
    measurementCharacteristics: measurements,
  };
}
