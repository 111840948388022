/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
// TODO: integrate with Jellyfish colours

/**
 * Fast hash function to generate an integer under 256 for a string-like input
 */
const hash256 = (s: string) => {
  let r = 0;
  const length = Math.min(s.length, 200);
  for (let i = 0; i < length; i++) {
    r += s.charCodeAt(i) * (i + 1);
  }
  return r % 256;
};

/**
 * Select one of the 256 predefined colours (as hex strings) for the given seed.
 * The colour is static for that seed.
 * Empty string returns the first colour.
 */
export const getColour = (seed: string) => colours[hash256(seed)];

// 79 is a magic number: 256 * 0.618 (Golden Ratio) / 2. This moves around the colour wheel in a way that avoids repeats.
// The +3 is a tweak so that it starts on a nice green.
export const getContrastingColour = (index: number) =>
  colours[(index * 79 + 3) % 256];

// Colour list generated by the method at https://codepen.io/y6nH/pen/wvwZMRX
export const colours = [
  "#e0f891",
  "#a5cc2e",
  "#cedbae",
  "#abed0f",
  "#d1e99f",
  "#8eb04f",
  "#d2f891",
  "#90d031",
  "#c8dbae",
  "#8cf013",
  "#c7e99f",
  "#84b253",
  "#c3f891",
  "#7ed135",
  "#c1dbae",
  "#72f018",
  "#bce99f",
  "#79b357",
  "#b5f891",
  "#6ad23a",
  "#bbdbae",
  "#56f11e",
  "#b2e99f",
  "#70b55a",
  "#a5f891",
  "#5ad33e",
  "#b5dbae",
  "#3ef123",
  "#a8e99f",
  "#66b75e",
  "#98f891",
  "#48d443",
  "#afdbae",
  "#27f127",
  "#9fe9a2",
  "#62b866",
  "#91f899",
  "#47d555",
  "#aedbb3",
  "#2cf24a",
  "#9fe9ac",
  "#66ba76",
  "#91f8a7",
  "#4bd66b",
  "#aedbba",
  "#31f267",
  "#9fe9b7",
  "#69bb84",
  "#91f8b7",
  "#4fd783",
  "#aedbc0",
  "#35f287",
  "#9fe9c1",
  "#6cbd93",
  "#91f8c4",
  "#52d897",
  "#aedbc7",
  "#39f2a2",
  "#9fe9cc",
  "#6fbea0",
  "#91f8d2",
  "#56d9ad",
  "#aedbcd",
  "#3df3bf",
  "#9fe9d6",
  "#71bfac",
  "#91f8e2",
  "#59d9bf",
  "#aedbd3",
  "#40f3d8",
  "#9fe9df",
  "#74c0b9",
  "#91f8ef",
  "#5bdad4",
  "#aedbd9",
  "#43f3f0",
  "#9fe8e9",
  "#76bfc1",
  "#91f1f8",
  "#5ed0da",
  "#aed5db",
  "#46dcf3",
  "#9fdee9",
  "#78b5c2",
  "#91e3f8",
  "#60bedb",
  "#aecfdb",
  "#48c6f3",
  "#9fd3e9",
  "#79acc3",
  "#91d4f8",
  "#62afdb",
  "#aecadb",
  "#4aadf3",
  "#9fc9e9",
  "#7ba1c4",
  "#91c6f8",
  "#639fdc",
  "#aec3db",
  "#4c97f4",
  "#9fbee9",
  "#7c99c4",
  "#91b8f8",
  "#648edc",
  "#aebddb",
  "#4d7ff4",
  "#9fb4e9",
  "#7c8fc4",
  "#91a9f8",
  "#657fdc",
  "#aeb6db",
  "#4d69f4",
  "#9fa9e9",
  "#7d85c4",
  "#919bf8",
  "#656ddc",
  "#aeb0db",
  "#4e50f4",
  "#9f9fe9",
  "#7e7dc5",
  "#9691f8",
  "#6d65dc",
  "#b3aedb",
  "#614df4",
  "#a99fe9",
  "#897dc4",
  "#a491f8",
  "#7f65dc",
  "#b8aedb",
  "#774df4",
  "#b49fe9",
  "#927cc4",
  "#b391f8",
  "#8e64dc",
  "#beaedb",
  "#8f4cf4",
  "#be9fe9",
  "#9c7bc4",
  "#c191f8",
  "#9d63dc",
  "#c5aedb",
  "#a54bf3",
  "#c99fe9",
  "#a57ac3",
  "#d091f8",
  "#af62db",
  "#cbaedb",
  "#bd49f3",
  "#d39fe9",
  "#af79c3",
  "#de91f8",
  "#be60db",
  "#d2aedb",
  "#d447f3",
  "#de9fe9",
  "#b877c2",
  "#ec91f8",
  "#d05eda",
  "#d8aedb",
  "#ed44f3",
  "#e89fe9",
  "#c175c1",
  "#f891f5",
  "#da5bd4",
  "#dbaed7",
  "#f341e1",
  "#e99fe1",
  "#c073b4",
  "#f891e7",
  "#d959bf",
  "#dbaed1",
  "#f33ec9",
  "#e99fd6",
  "#bf71a9",
  "#f891d7",
  "#d956ad",
  "#dbaeca",
  "#f23aac",
  "#e99fcc",
  "#be6e9b",
  "#f891ca",
  "#d85297",
  "#dbaec4",
  "#f23791",
  "#e99fc1",
  "#bc6b8e",
  "#f891ba",
  "#d74f83",
  "#dbaebe",
  "#f23272",
  "#e99fb7",
  "#bb687f",
  "#f891ac",
  "#d64b6e",
  "#dbaeb8",
  "#f22e55",
  "#e99fac",
  "#ba6471",
  "#f8919f",
  "#d54755",
  "#dbaeb2",
  "#f12933",
  "#e99fa2",
  "#b86163",
  "#f89291",
  "#d44843",
  "#dbb1ae",
  "#f13524",
  "#e9a89f",
  "#b6695d",
  "#f8a091",
  "#d35a3e",
  "#dbb7ae",
  "#f1501f",
  "#e9b29f",
  "#b5725a",
  "#f8b091",
  "#d26a3a",
  "#dbbeae",
  "#f0691a",
  "#e9bc9f",
  "#b37c55",
  "#f8bd91",
  "#d17e35",
  "#dbc4ae",
  "#f08215",
  "#e9c79f",
  "#b18652",
  "#f8cd91",
  "#d09031",
  "#dbcaae",
  "#efa10f",
  "#e9d19f",
  "#ae914e",
  "#f8db91",
  "#cda22e",
  "#dbd0ae",
  "#eabb0f",
  "#e9dc9f",
  "#aa9b4d",
  "#f8ea91",
  "#c7b52d",
  "#dbd6ae",
  "#e4d60f",
  "#e9e69f",
  "#a6a44b",
  "#f8f891",
  "#c1c42c",
  "#d8dbae",
  "#d6e40f",
  "#e2e99f",
  "#9ea94c",
  "#eaf891",
  "#afc92e",
  "#d2dbae",
  "#bbea0f",
];
