/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  delay,
  call,
  select,
  put,
  all,
  race,
  take,
  putResolve,
} from "typed-redux-saga";
import {
  selectIsRollingMode,
  selectFocusedMachine,
  selectStartAndEnd,
  selectFocusedMachineLastFetched,
  selectActiveJobTypes,
  selectActiveMeasurementDetails,
  selectActiveTimeSeriesTypes,
  selectLastFetchedDate,
  selectFocusedJob,
} from "../selectors";
import { data } from "./dataSaga";
import { DataFetchedType } from "@/store/state";
import { subMilliseconds } from "date-fns";
import { RootState, rootActions } from "@/store";
import { getGlobalConfigs } from "@/index";
import { TimeSeriesValueCompactValue } from "@centralwebteam/narwhal";

export function* liveRefreshWatcher({
  delayDuration,
}: {
  delayDuration: number;
}) {
  while (true) {
    // wait for the date mode to be rolling/live and the zoom state to be pristine.
    yield* all([call(waitForDateMode, "rolling")]);
    const { cancel } = yield* race({
      cancel: race([call(waitForDateMode, "static")]),
      fetchDelta: call(liveRefresh, delayDuration),
    });
    if (cancel) {
      console.info("live refresh cancelled");
    }
  }
}

/**
 * Terminates when date mode matches params.mode.
 */
function* waitForDateMode(mode: "static" | "rolling") {
  const isRollingMode: boolean = yield* select(selectIsRollingMode);
  if (
    (isRollingMode && mode === "rolling") ||
    (!isRollingMode && mode === "static")
  ) {
    return;
  }
  while (yield* take(rootActions.session.startEndUpdated)) {
    const isRollingMode: boolean = yield* select(selectIsRollingMode);
    if (
      (isRollingMode && mode === "rolling") ||
      (!isRollingMode && mode === "static")
    ) {
      return;
    }
  }
}

function* liveRefresh(delayDuration: number) {
  const config = getGlobalConfigs();
  while (true) {
    yield* delay(delayDuration);
    yield* put(
      rootActions.machineAnalysis.fetchingStarted({ loadingType: "data" })
    );
    const focusedMachine = yield* select(selectFocusedMachine);
    if (!focusedMachine) return;
    const [, to] = yield* select(selectStartAndEnd);
    const lastFetched = yield* select(selectFocusedMachineLastFetched);
    const now = new Date().toISOString();
    const activeJobTypes = yield* select(selectActiveJobTypes);
    const activeTimeSeries = yield* select(selectActiveTimeSeriesTypes);
    const activeMeasurementSeries = yield* select(
      selectActiveMeasurementDetails
    );

    const options = {
      from: lastFetched ?? to,
      to: now,
      machineId: focusedMachine.id,
    };

    const fromStates = yield* select(
      selectLastFetchedDate("states" as DataFetchedType)
    );
    const fromJobs = yield* select(
      selectLastFetchedDate("jobs" as DataFetchedType)
    );
    const fromAlerts = yield* select(
      selectLastFetchedDate("alerts" as DataFetchedType)
    );
    const fromTimeSeriesValues = yield* select(
      selectLastFetchedDate("timeSeriesValues" as DataFetchedType)
    );
    const fromMeasurementValues = yield* select(
      selectLastFetchedDate("measurementSeriesValues" as DataFetchedType)
    );
    const fromProcessActions = yield* select(
      selectLastFetchedDate("processActions" as DataFetchedType)
    );
    const fromUnitHints = yield* select(
      selectLastFetchedDate("unitHints" as DataFetchedType)
    );

    const optionsTimeSeriesValues = { ...options, from: fromTimeSeriesValues };
    const optionsMeasurementSeriesValues = {
      ...options,
      from: fromMeasurementValues,
    };
    const {
      jobs,
      alerts,
      processActions,
      states,
      uniqueMeasurements,
      uniqueTimeSeries,
      timeSeriesLimits,
      measurementValues,
      timeSeriesValues,
    } = yield* all({
      jobs: call(data.fetchJobSummaries, { ...options, from: fromJobs }),
      alerts: call(data.fetchAlerts, { ...options, from: fromAlerts }),
      processActions: call(data.fetchProcessActions, {
        ...options,
        from: fromProcessActions,
      }),
      states: call(data.fetchMachineStates, { ...options, from: fromStates }),
      uniqueMeasurements: call(data.fetchUniqueMeasurementsTypes, {
        ...options,
        jobs: activeJobTypes,
      }),
      uniqueTimeSeries: call(data.fetchUniqueTimeSeries, options),
      timeSeriesLimits: all(
        activeTimeSeries.map(({ id }) =>
          call(data.fetchTimeSeriesLimits, {
            ...options,
            id,
          })
        )
      ),
      measurementValues: all(
        activeMeasurementSeries.map(({ featureName, name }) =>
          call(data.fetchMeasurementValues, {
            ...optionsMeasurementSeriesValues,
            featureName,
            name,
            jobName: activeJobTypes,
          })
        )
      ),
      timeSeriesValues: all(
        activeTimeSeries.map(({ id, displayHints, displayHintSampleRate }) =>
          call(data.fetchTimeSeriesValues, {
            ...optionsTimeSeriesValues,
            id,
            sample: (displayHints?.sampleRate ?? displayHintSampleRate) as
              | "Raw"
              | "Hour"
              | "Day"
              | "Week"
              | "Month"
              | "Default"
              | undefined,
          })
        )
      ),
    });

    yield* all([
      putResolve(
        rootActions.machineAnalysis.measurementTypesDeltaFetched({
          types: uniqueMeasurements,
        })
      ),
      putResolve(
        rootActions.machineAnalysis.timeSeriesTypesDeltaFetched({
          types: uniqueTimeSeries,
        })
      ),
      putResolve(
        rootActions.machineAnalysis.jobsSummariesDeltaFetched({
          jobs,
          from: options.from,
          to: options.to,
        })
      ),
      putResolve(
        rootActions.machineAnalysis.statesDeltaFetched({
          states,
          from: options.from,
          to: options.to,
        })
      ),
      putResolve(
        rootActions.machineAnalysis.alertsDeltaFetched({
          alerts,
          from: options.from,
          to: options.to,
        })
      ),
      putResolve(
        rootActions.machineAnalysis.processActionsDeltaFetched({
          events: processActions,
          from: options.from,
          to: options.to,
        })
      ),
      all(
        timeSeriesLimits.map((data) =>
          putResolve(
            rootActions.machineAnalysis.timeSeriesLimitsDeltaFetched(data)
          )
        )
      ),
      all(
        timeSeriesValues.map(
          (data: {
            id: string;
            from: string;
            to: string;
            data: TimeSeriesValueCompactValue[];
          }) =>
            putResolve(
              rootActions.machineAnalysis.timeSeriesValuesDeltaFetched(data)
            )
        )
      ),
      all(
        measurementValues.map((data) =>
          putResolve(
            rootActions.machineAnalysis.measurementValuesDeltaFetched(data)
          )
        )
      ),
    ]);

    yield* put(rootActions.machineAnalysis.unitDisplayHintsRequired());

    const unitHints = yield* call(data.fetchUnitHints, {
      ...options,
      from: fromUnitHints,
    });
    yield* put(
      rootActions.machineAnalysis.unitDisplayHintsDeltaFetched({
        hints: unitHints,
        from: fromUnitHints,
        to: options.to,
      })
    );

    yield* put(rootActions.machineAnalysis.unitDisplayHintsFetchingFinished());

    const mainFocusedJob = yield* select(selectFocusedJob);
    if (mainFocusedJob && mainFocusedJob.end) {
      const reducedEndDate = yield* select(
        (state: RootState) => state.focusedJob.reducedEndDate
      );
      let date = undefined;
      if (reducedEndDate === undefined)
        date = subMilliseconds(
          new Date(mainFocusedJob.end),
          config.refreshRateLiveInMS
        );
      else date = subMilliseconds(reducedEndDate, config.refreshRateLiveInMS);
      yield* put(
        rootActions.machineAnalysis.setFocusedJobReducedEndDate({
          date,
        })
      );
    }

    // once other actions have resolved (note putResolve) we can say the live refresh loop is complete
    yield* put(
      rootActions.machineAnalysis.liveRefreshCompleted({
        date: now,
      })
    );
    yield* put(
      rootActions.machineAnalysis.fetchingFinished({
        to: now,
        loadingType: "data",
      })
    );
  }
}
