/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
// actions
import {
  Actions as SessionActions,
  actions as sessionActions,
} from "./session/actions";
import {
  Actions as ManageAssetsActions,
  actions as manageAssetsActions,
} from "./manageAssets/actions";
import {
  Actions as GlobalActions,
  actions as globalActions,
} from "./global/actions";
import {
  Actions as CurrentStatusActions,
  actions as currentStatusActions,
} from "./currentStatus/actions";
import {
  Actions as FilterActions,
  actions as filterActions,
} from "./filter/actions";
import {
  Actions as JobPerformanceActions,
  actions as jobPerformanceActions,
} from "./jobPerformance/actions";
import {
  Actions as ManageUsersActions,
  actions as manageUsersActions,
} from "./manageUsers/actions";
import {
  Actions as ManageMyAccountActions,
  actions as manageMyAccountActions,
} from "./manageMyAccount/actions";
import {
  Actions as ManageProvisioningActions,
  actions as manageProvisioningActions,
} from "./manageMachineProvisioning/actions";
import {
  MachineAnalysisActions,
  machineAnalysisActions,
} from "./machineAnalysis/actions";
import {
  machinePerformanceActions,
  MachinePerformanceActions,
} from "./machinePerformance/actions";
import {
  Actions as ManageClientsActions,
  actions as manageClientsActions,
} from "./manageClients/actions";
import {
  Actions as ManageNotificationsActions,
  actions as manageNotificationsActions,
} from "./manageNotifications/actions";
import {
  ProcessUpdatesActions,
  processUpdatesActions,
} from "./processUpdates/actions";
export const rootActions = {
  manageAssets: manageAssetsActions,
  global: globalActions,
  currentStatus: currentStatusActions,
  filter: filterActions,
  jobPerformance: jobPerformanceActions,
  manageUsers: manageUsersActions,
  manageClients: manageClientsActions,
  manageProvisioning: manageProvisioningActions,
  manageMyAccount: manageMyAccountActions,
  manageNotifications: manageNotificationsActions,
  session: sessionActions,
  machineAnalysis: machineAnalysisActions,
  machinePerformance: machinePerformanceActions,
  processUpdates: processUpdatesActions,
};

export type RootActions =
  | ManageAssetsActions
  | GlobalActions
  | CurrentStatusActions
  | FilterActions
  | JobPerformanceActions
  | ManageUsersActions
  | ManageProvisioningActions
  | ManageMyAccountActions
  | ManageClientsActions
  | ManageNotificationsActions
  | SessionActions
  | MachineAnalysisActions
  | MachinePerformanceActions
  | ProcessUpdatesActions;

/** Payload of an Action.
 * If we start using redux-toolkit, take PayloadAction from there:
 * https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/createAction.ts#L22C27-L22C27
 * */
export type PayloadAction<P = void, T extends string = string> = {
  payload: P;
  type: T;
};
