/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { takeRight, take as takeLeft } from "lodash/fp";

/**
 * Takes `take` items from the end of the `left` array and the start of the `right` array.
 * If one array doesn't have enough items, tries to take the difference from the other array.
 * @param take number of items to take from each array
 * @param left array
 * @param right array
 * @returns an array containing new left and right arrays, trimmed as appropriate.
 */
export function pickAround<T>(take: number, left: T[], right: T[]): [T[], T[]] {
  try {
    if (take < 1) return [[], []];
    const leftDiffBetweenLengthAndTake = left.length - take;
    const rightDiffBetweenLengthAndTake = right.length - take;
    return [
      takeRight(
        rightDiffBetweenLengthAndTake < 0
          ? take + Math.abs(rightDiffBetweenLengthAndTake)
          : take,
        left
      ),
      takeLeft(
        leftDiffBetweenLengthAndTake < 0
          ? take + Math.abs(leftDiffBetweenLengthAndTake)
          : take,
        right
      ),
    ];
  } catch (error) {
    console.error(error);
    return [[], []];
  }
}
