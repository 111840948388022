/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  ToolOffsetApplied,
  MasteringUpdate,
  ToolChangeDetected,
  ToolOffsetCalculation,
  ProcessActionDetails,
  instanceOfMasteringUpdate,
} from "@centralwebteam/narwhal";
import {
  AlertLevel,
  levelMapper,
  levelColours,
} from "@/presentation/Alert/level";
import constants from "@/constants";
import { JobPresentation } from "../Job";

const getLevelFromDetails = (details: ProcessActionDetails | undefined) => {
  if (!details) {
    return AlertLevel.offset;
  }
  if (details.wearPercentage! > constants.offsetErrorMin) {
    return AlertLevel.offsetError;
  }
  if (
    details.wearPercentage! >= constants.offsetWarningMin &&
    details.wearPercentage! <= constants.offsetWarningMax
  ) {
    return AlertLevel.offsetWarning;
  }
  return AlertLevel.offset;
};

/** Selects the ProcessActionPresentation from `processActions` to display for the MeasurementInfo `measurement` and JobPresentation `job` */
export const selectDisplayProcessAction = (
  processActions: ProcessActionPresentation[],
  measurement: { name?: string; featureName?: string },
  jobs: JobPresentation[]
) => {
  if (!measurement || !processActions?.length) return undefined;

  const matchingActions = processActions.filter(
    (pa) =>
      pa.featureName === measurement.featureName &&
      pa.measurementCharacteristicName === measurement.name &&
      jobs.some((j) => j.id === pa.jobId)
  );

  // Single or undefined ProcessAction
  if (matchingActions.length < 2) return matchingActions.at(0);

  // Return highest priority action
  return (
    matchingActions.find((pa) => pa.level === AlertLevel.offsetError) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offsetWarning) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offset) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offsetOther) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offsetInfo) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offsetOtherInfo) ||
    matchingActions.at(0)
  );
};

export class ProcessActionPresentation {
  id: string;
  name: string;
  level: AlertLevel;
  created: Date;
  fill: string;
  layer?: number | undefined;
  subType: string;
  updateMode: string | undefined;
  jobId: string | undefined;
  featureName?: string | undefined;
  jobName?: string | undefined;
  measurementCharacteristicName?: string | undefined;
  masterJobName?: string | undefined;

  constructor(
    event:
      | ToolOffsetApplied
      | MasteringUpdate
      | ToolChangeDetected
      | ToolOffsetCalculation
  ) {
    const { id, name, created, subType } = event;
    this.id = id;
    this.name = name;
    this.created = new Date(created);
    this.subType = subType;

    if (subType === "ToolChangeDetected") {
      this.level = AlertLevel.offsetOtherInfo;
      const { jobTrigger, characteristic } = event as ToolChangeDetected;
      if (jobTrigger) {
        this.jobId = jobTrigger.id;
        this.updateMode = jobTrigger.updateMode;
        this.jobName = jobTrigger.name;
      }
      if (characteristic) {
        const { featureName, jobName, measurementCharacteristicName } =
          characteristic;
        this.featureName = featureName;
        this.jobName = jobName;
        this.measurementCharacteristicName = measurementCharacteristicName;
      }
    } else if (
      subType === "ToolOffsetApplied" ||
      subType === "ToolOffsetCalculation"
    ) {
      const {
        featureName,
        measurementCharacteristicName,
        updateMode,
        details,
        jobId,
        jobName,
      } = event;
      this.jobId = jobId;
      this.jobName = jobName;
      this.featureName = featureName;
      this.measurementCharacteristicName = measurementCharacteristicName;
      this.updateMode = updateMode;

      if (subType === "ToolOffsetApplied") {
        // updateMode is usually "Set" or "Control", but can also be "Ignore"
        if (updateMode === "Set") this.level = AlertLevel.offsetOther;
        else this.level = getLevelFromDetails(details);
      } else {
        // ToolOffsetCalculation
        if (updateMode === "Set") this.level = AlertLevel.offsetOtherInfo;
        else this.level = AlertLevel.offsetInfo;
      }
    } else {
      // We're expecting a MasteringUpdate here, though it could also be an unknown type
      if (instanceOfMasteringUpdate(event)) {
        this.jobName = event.masterJobName;
        this.masterJobName = event.masterJobName;
      }
      this.level = levelMapper(subType);
    }

    this.fill = levelColours[this.level];
  }
}
