/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { call, all, put, delay, select, race, take } from "typed-redux-saga";
import { data } from "./dataSaga";
import { RootState, rootActions } from "..";
import { getGlobalConfigs } from "@/index";
import { toUTCISOString } from "../../modules/dateFormats/index";
import { selectStartAndEnd, selectIsRollingMode } from "@/store/selectors";
/**
 * Fetches all of the required static data for the current time range.
 */
export const allStaticData = function* () {
  try {
    yield* all([call(fetchStaticData)]);
  } catch (error) {
    console.log(error);
  }
};

export function* liveRefreshWatcher() {
  const config = yield* call(() => getGlobalConfigs());
  while (true) {
    yield* all([call(waitForDateMode, "rolling")]);
    console.info("live refresh started");
    const { cancel } = yield* race({
      cancel: race([call(waitForDateMode, "static")]),
      fetchDelta: call(
        fetchDeltaSupervisor,
        config.maxTake,
        config.refreshRateLiveInMS
      ),
    });
    if (cancel) {
      console.info("live refresh cancelled");
    }
  }
}

/**
 * Terminates when date mode matches params.mode.
 */
function* waitForDateMode(mode: "static" | "rolling") {
  const isRollingMode: boolean = yield* select(selectIsRollingMode);
  if (
    (isRollingMode && mode === "rolling") ||
    (!isRollingMode && mode === "static")
  ) {
    return;
  }
  while (yield* take(rootActions.session.startEndUpdated)) {
    const isRollingMode: boolean = yield* select(selectIsRollingMode);
    if (
      (isRollingMode && mode === "rolling") ||
      (!isRollingMode && mode === "static")
    ) {
      return;
    }
  }
}
/**
 * Controls when to call fetchDeltas saga
 */
function* fetchDeltaSupervisor(take: number, refreshRate: number) {
  while (true) {
    yield* call(fetchDeltas, take);
    yield* delay(refreshRate);
  }
}

function* fetchStaticData() {
  const config = yield* call(() => getGlobalConfigs());
  const [from, to] = yield* select(selectStartAndEnd);
  try {
    yield* put(rootActions.processUpdates.fetchingStarted());
    yield* call(fetchToolOffsetApplied, from, to, config.maxTake, "static");
  } catch (error) {
    console.info(
      "something went wrong fetching static process updates data",
      error
    );
  } finally {
    yield* put(
      rootActions.processUpdates.fetchingFinished({
        to,
      })
    );
  }
}

function* fetchDeltas(take: number) {
  const now = toUTCISOString(new Date());
  try {
    const lastFetched = yield* select(
      (state: RootState) => state.processUpdates.lastFetched
    );
    // comply with types
    if (!lastFetched) {
      return;
    }

    yield* call(fetchToolOffsetApplied, lastFetched, now, take, "delta");
  } catch (error) {
    console.log(error);
  }
}

function* fetchToolOffsetApplied(
  from: string,
  to: string,
  take: number,
  type: "static" | "delta"
) {
  try {
    const toolOffsetApplied = yield* call(
      (from, to, take) =>
        data.fetchToolOffsetApplied({
          from,
          to,
          take,
        }),
      from,
      to,
      take
    );
    const payload = {
      events: toolOffsetApplied,
      from: from,
      to: to,
    };
    if (type === "static") {
      yield* all([
        put(rootActions.processUpdates.toolOffsetAppliedFetched(payload)),
      ]);
    } else {
      yield* all([
        put(rootActions.processUpdates.toolOffsetAppliedDeltaFetched(payload)),
      ]);
    }
  } catch (error) {
    console.log(error);
  }
}
