/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import React from "react";
import { useDispatch } from "react-redux";
import { rootActions, useTypedSelector } from "@/store";
import { LoginForm } from "@centralwebteam/jellyfish";
import { styled } from "@/styled-components";
import { cover } from "polished";
import { useTranslation } from "react-i18next";
import { selectLoginView } from "@/store/selectors";
import { ViewState } from "@centralwebteam/jellyfish/src/Login/types";

const Wrapper = styled.div`
  flex: 1;
  ${cover()};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 calc(50% - 200px);
`;

export default () => {
  const { t } = useTranslation();
  const loginView = useTypedSelector(selectLoginView);

  const dispatch = useDispatch();

  return (
    <Wrapper>
      <LoginForm
        onViewChanged={(viewState: ViewState) => {
          void dispatch(
            rootActions.session.loginStateChanged({
              state: viewState,
            })
          );
        }}
        viewState={loginView}
        signInRequest={(data) =>
          void dispatch(
            rootActions.session.userLoginSubmitted({
              username: data.email.replace(new RegExp(/\//g), "\\"),
              password: data.password,
            })
          )
        }
        forgottenPasswordRequest={(email: string) => {
          const translationTexts = {
            resetSent: t("message-passwordResetSent"),
            resetSentEmail: t("message-passwordResetSentToEmail", {
              email,
            }),
            resetWritten: t("message-passwordResetWritten"),
            resetWrittenToDisk: t("message-passwordResetWrittenToDisk"),
            resetFailed: t("message-passwordResetFailed"),
          };
          void dispatch(
            rootActions.session.userRequestedPasswordResetLink({
              email,
              translationTexts,
            })
          );
        }}
      />
    </Wrapper>
  );
};
