/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { call, race, take } from "typed-redux-saga";
import { getType } from "typesafe-actions";
import { allStaticData, liveRefreshWatcher } from "./saga";
import { rootActions } from "../rootActions";

export function* processActionsRootSaga() {
  try {
    while (true) {
      try {
        yield* call(allStaticData);
        yield* race({
          liveRefreshWatcher: call(liveRefreshWatcher),
          cancel: take([getType(rootActions.session.startEndUpdated)]),
        });
      } catch (error) {
        console.warn(`process updates root saga: ${error}`);
      }
    }
  } catch (error) {
    console.log(error);
  }
}
