/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import memo from "memoize-one";
import { createSelector } from "reselect";
import { RootState } from "..";
import { getFilteredResourceByKey, selectAllLocations } from "../selectors";
import {
  LocationRequiredProperties,
  getActiveLocations,
} from "@/presentation/Location";

/** Creates a memoized filter function.
 * If the filter strings include "Unknown", includes results from `resource` where `key` is falsy */
export function getFilteredResourceByKeyMapUndefinedToUnknown(key: string) {
  return memo(function <T extends { [key: string]: any }>(
    resource: T[],
    filters: string[]
  ): T[] {
    if (!filters || !filters.length) return resource;
    return resource.filter((r) => {
      return filters.some((filter) =>
        !r[key] && filter === "Unknown" ? true : r[key] === filter
      );
    });
  }) as <T>(resource: T[], filters: string[]) => T[];
}

type SelectionType =
  /** This and all descendants are not selected */
  | "none"
  /** This and all descendants are selected */
  | "all"
  /** This is not selected; a descendant is selected */
  | "inner"
  /** This is selected; a descendant is not selected */
  | "outer";

const getLocationSelectionTypes = (
  locations: LocationRequiredProperties[],
  activeLocations: string[]
): Mappy<SelectionType> => {
  if (!activeLocations?.length) return {};
  return locations
    .map((l) => {
      return {
        [l.id]: (activeLocations.includes(l.id)
          ? l.descendants.every((d) => activeLocations.includes(d))
            ? "all"
            : "outer"
          : l.descendants.some((d) => activeLocations.includes(d))
            ? "inner"
            : "none") as SelectionType,
      };
    })
    .reduce((a, b) => {
      return { ...a, ...b };
    }, {});
};

const selectActiveFilters = (s: RootState) => s.filter.active;
const selectDraftFilters = (s: RootState) => s.filter.draft;

/** Filtered location list */
export const selectLocations = createSelector(
  [selectAllLocations, selectActiveFilters],
  (locations, f) => getFilteredResourceByKey("id")(locations, f.locationId)
);

/**
 * Active locations, with all descendants active, for the location filter component
 */
export const selectActiveLocations = createSelector(
  [selectAllLocations, selectDraftFilters],
  (locations, f) => getActiveLocations(locations, f.locationId)
);

/**
 * Selection type (none, all, inner, or outer) for locations in the location filter component
 */
export const selectLocationSelectionTypes = createSelector(
  [selectAllLocations, selectDraftFilters],
  (locations, f) => getLocationSelectionTypes(locations, f.locationId)
);

/** Filtered machine list */
export const selectMachines = createSelector(
  [(s: RootState) => s.global.machines, selectActiveFilters],
  (gm, f) =>
    gm.filter(
      (m) =>
        !f.locationId.length ||
        (f.locationId.includes(m.locationId ?? "") &&
          (!f.machineType.length || f.machineType.includes(m.type)))
    )
);

/** Is the filtered machine list shorter than the global list? */
export const selectAreMachinesFiltered = createSelector(
  [(s: RootState) => s.global.machines, selectMachines],
  (gm, sm) => gm.length > sm.length
);
