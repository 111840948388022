/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import ReduxThunk, { ThunkDispatch, ThunkAction } from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import { TypedUseSelectorHook, useSelector, GetProps } from "react-redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { CMSClient } from "@/cms-api";
// reducers
import { reducer } from "./reducer";
import { State } from "./state";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createSwitchFetch } from "@/axios/cancellable";
import { rootSaga } from "./rootSaga";
import { RootActions } from "./rootActions";

export * from "./rootActions";
export type RootState = State;

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage: storage,
  whitelist: ["filter"],
};
const persistedReducer = persistReducer(persistConfig, reducer);

const sagaMiddleware = createSagaMiddleware({
  context: {
    client: CMSClient,
  },
});

const compose = composeWithDevTools({
  trace: true,
});

// TODO: replace deprecated createStore with configureStore
export const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(
      ReduxThunk.withExtraArgument({
        client: CMSClient,
        switchFetch: createSwitchFetch(),
      }),
      sagaMiddleware
    )
  )
);

export const persistor = persistStore(store);

export const startStore = () => {
  sagaMiddleware.run(rootSaga);
};

// TYPES
export type ExtraArguments = {
  client: typeof CMSClient;
  switchFetch: ReturnType<typeof createSwitchFetch>;
};
export type TypedDispatch = ThunkDispatch<
  RootState,
  ExtraArguments,
  RootActions
>;
export type GetState = () => RootState;
export type MappedProps<C, K extends keyof GetProps<C>> = Pick<GetProps<C>, K>;
export type ACResult<R = void> = ThunkAction<
  R,
  RootState,
  ExtraArguments,
  RootActions
>;
export type ACResultAsync<R = void> = ACResult<Promise<R>>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
