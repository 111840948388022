/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import { Client, ClientPatch } from "@centralwebteam/narwhal";

const clientsFetched = createStandardAction("[manage clients] clients fetched")<
  Client[]
>();

const clientListItemClicked = createStandardAction(
  "[manage clients] client list item clicked"
)<string>();

const addNewClientButtonClicked = createStandardAction(
  "[manage clients] add new client button clicked"
)<void>();

const confirmButtonClicked = createStandardAction(
  "[manage clients] delete confirmation box confirm button clicked"
)();
const cancelButtonClicked = createStandardAction(
  "[manage client] delete confirmation box cancel button clicked"
)();

const deleteClientButtonClicked = createStandardAction(
  "[manage clients] delete client button clicked"
)<Client>();

const confirmDeletion = createStandardAction(
  "[manage clients] delete confirmation requested (saga)"
)();

const activateClientButtonClicked = createStandardAction(
  "[manage clients] activate client button clicked"
)<Client>();

const deactivateClientButtonClicked = createStandardAction(
  "[manage clients] deactivate client button clicked"
)<Client>();

const createNewClientButtonClicked = createStandardAction(
  "[manage clients] create new client button clicked"
)<{ newClient: Partial<ClientPatch>; selectedConnectionType: string }>();

const clientCreated = createStandardAction(
  "[manage clients][saga] client created"
)<{
  clientId: string;
  name: string;
  clientSecret: string | undefined;
}>();

const saveChangesButtonClicked = createStandardAction(
  "[manage clients] save changes button clicked"
)<{ client: Client; selectedConnectionType: string }>();

export const actions = {
  clientsFetched,
  clientListItemClicked,
  addNewClientButtonClicked,
  confirmButtonClicked,
  cancelButtonClicked,
  deleteClientButtonClicked,
  confirmDeletion,
  activateClientButtonClicked,
  deactivateClientButtonClicked,
  createNewClientButtonClicked,
  clientCreated,
  saveChangesButtonClicked,
};
export type Actions = ActionType<typeof actions>;
