/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { t } from "@/modules/string/translate";

/** Type-safe handling of an error which is probably an AxiosError. Returns the best error message string available, attempting translation. */
export const getErrorMessage = (error: any) => {
  if (error?.response?.data) {
    return t`${String(error.response.data)}`;
  }
  if (error.message) {
    return t`${String(error.message)}`;
  }
  if (error.request) {
    return t`message-No response from server`;
  }
  return t`${String(error)}`;
};

/** Type-safe handling of an error which is probably an AxiosError. Returns true if it has a response status of 400 or greater, otherwise false. */
export const isHttpError = (error: any) =>
  Number(error?.response?.status ?? 0) >= 400;

/** Type-safe handling of an error which is probably an AxiosError. Returns true if it has a response status of 401, otherwise false. */
export const isHttpAuthError = (error: any) =>
  Number(error?.response?.status ?? 0) === 401;
