/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { format } from "d3-format";
import { clamp } from "lodash/fp";
import constants from "@/constants";

/**
 * Makes a number into a relatively short and readable string
 * @param num Number to shorten
 * @param decPlace Number of decimal places for rounding. Clamped to an integer 0 to 20.
 */
export function tidyNumber(num: number | undefined, decPlace = 3) {
  if (!num || !Number.isFinite(num)) {
    return formatNonNumber(num);
  }

  decPlace = clamp(0, 20, Math.floor(decPlace));
  const sigFig = Math.max(decPlace, 3);

  // Limits for numbers we can display normally
  // We can't sensibly shorten numbers under 1k, so fix the upper limit there. Otherwise, tidyNumber(123, 0) is "100".
  const upper = Math.pow(10, sigFig);
  const lower = Math.pow(10, -decPlace);

  // Tiny numbers that round to 0 should be shown like "0.000" to distinguish them from actual zeroes
  if (Math.abs(num) < lower) {
    return num.toFixed(decPlace);
  }

  // d3-format gets almost everything right, but makes 0.1 into "100m" and 50.047 into "50"
  if (Math.abs(num) < upper) {
    return formatMediumSizeNumber(num, decPlace);
  }

  // Use d3-format for big numbers.
  // Minimum of 3 sf here so that we get "123k" instead of "100k" for 123456
  return format(`.${sigFig}~s`)(num);
}

// Trim trailing zeroes
function formatMediumSizeNumber(num: number, decPlace: number) {
  let s = num.toFixed(decPlace);
  if (decPlace === 0) return s;
  while (s.endsWith("0")) {
    s = s.substr(0, s.length - 1);
  }
  if (s.endsWith(".")) {
    s = s.substr(0, s.length - 1);
  }
  return s;
}

// Convert number-like values to user-friendly strings
export function formatNonNumber(num: any) {
  switch (num) {
    case 0:
      return "0";
    case Infinity:
      return "∞";
    case -Infinity:
      return "-∞";
    default:
      return constants.emptyState;
  }
}
