/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  all,
  call,
  put,
  getContext,
  select,
  delay,
  take,
} from "typed-redux-saga";
import { getGlobalConfigs } from "@/index";
import constants from "@/constants";
import { rootActions, RootState } from "@/store/";
import { last24Hours, last28Days } from "@/modules/dateFormats";
import createAlertsExpensive from "@/presentation/Alert/create.worker";
import { chunk } from "lodash/fp";
import {
  selectLocations,
  selectMachines,
  selectAreMachinesFiltered,
} from "@/store/filter/selectors";
import {
  selectIsMicroView,
  selectLastFetchedCarouselData,
  selectLastFetchedCycleTime,
} from "@/store/currentStatus/selectors";
import {
  createJobPresentation,
  mapStringToVerdict,
  mapStringToStatus,
  JobPresentation,
} from "@/presentation/Job";
import {
  Machine,
  MachineType,
  Alert,
  MasteringEvent,
  TimeSeriesValueCompactValue,
  JobSummary,
} from "@centralwebteam/narwhal";
import { CMSClientType } from "@/cms-api";
import uniqBy from "lodash/fp/uniqBy";
import orderBy from "lodash/fp/orderBy";

const GUID_QUERY_CHUNK_AMOUNT = 50;
// time in ms to give the api a break...
const RELIEF_TIME = 1000;

export function* currentStatusMountedBG() {
  const config = yield* call(() => getGlobalConfigs());
  while (true) {
    yield* all([
      fetchAllMachineAlertsSaga(),
      fetchAllMachineJobsSaga(),
      fetchMachineStatusUpdates(),
      fetchCarouselData(),
    ]);
    yield* delay(config.refreshRateLiveInMS);
  }
}

/** Fetches extra data immediately when the viewMode switches to large */
export function* watchViewMode() {
  while (true) {
    const mode = yield* take(rootActions.currentStatus.setViewMode);
    if (mode.payload === "NORMAL") {
      yield* all([fetchCarouselData(), fetchMachineJobCycleTimeSaga()]);
    }
  }
}

function* fetchAllMachineJobsSaga() {
  try {
    const machines = yield* select(selectMachines);
    const chunkedMachines = chunk(GUID_QUERY_CHUNK_AMOUNT, machines);
    yield* all(
      chunkedMachines.map((machines) => call(fetchMachineJobs, machines))
    );
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMachineJobs(machines: Machine[]) {
  try {
    const client: CMSClientType = yield getContext("client");
    const { from, to } = yield* call(last24Hours);
    const limit = 11;
    const allMachineIds = machines.map((machine) => machine.id);
    let jobs = {} as Mappy<JobSummary[]>;
    try {
      jobs = yield* call(
        () =>
          client.jobs.tempRawSummary({
            machines: machines.map((machine) => machine.id),
            from,
            to,
            take: limit,
          }).promise
      );
    } catch (ex) {
      console.warn(ex);
    }

    if (!jobs) return;

    const allMasters: MasteringEvent[] = yield* call(
      () =>
        client.events.query<any[]>(
          `$filter=(type eq 'Mastering')and(created ge ${from})and(created lt ${to})&$top=${
            limit * 2
          }&$orderBy=created desc`
        ).promise
    );
    if (Object.keys(jobs).length === 0 && allMasters && allMasters.length > 0) {
      const machineIds = allMasters.map((masterItems) => masterItems.machineId);
      let data: JobPresentation[] = [];
      for (const machineId of machineIds) {
        const type = machines.find((m) => m.id === machineId)?.type;
        if (type === MachineType.Equator) {
          const masters = allMasters.filter((f) => f.machineId === machineId);
          const masterStarts = masters
            .filter((job) => job.active)
            .sort((a, b) => a.created.localeCompare(b.created));
          const masterEnds = masters
            .filter((job) => !job.active)
            .sort((a, b) => a.created.localeCompare(b.created));
          const pairedMasters: JobPresentation[] = masterStarts.map(
            (start, index) => {
              const nextStart = masterStarts[index + 1];
              let end: MasteringEvent | undefined;
              // We don't always have a sequenceExecutionId, but use it if we do.
              if (start.sequenceExecutionId) {
                end = masterEnds.find(
                  (j) => j.sequenceExecutionId === start.sequenceExecutionId
                );
              } else {
                end = masterEnds.find(
                  (m) =>
                    m.created > start.created &&
                    (!nextStart?.created || m.created < nextStart.created)
                );
              }

              if (!end) {
                return {
                  type: "mastering",
                  id: start.id,
                  name: start.name,
                  machineId: start.machineId,
                  start: start.created,
                  end: nextStart?.created,
                  status: "No Master End",
                  verdict: "No Verdict",
                };
              }
              return {
                type: "mastering",
                id: start.id,
                name: start.name,
                machineId: start.machineId,
                start: start.created,
                end: end.created || undefined,
                passes: end.characteristicsPassed ?? 0,
                failures: end.characteristicsFailed ?? 0,
                warnings: end.characteristicsWarning ?? 0,
                notToleranced: end.characteristicsNotToleranced ?? 0,
                status: mapStringToStatus("mastering", end.status),
                verdict: mapStringToVerdict(end.verdict),
              };
            }
          );
          let allJobs = uniqBy("start", [...pairedMasters]);
          allJobs = orderBy(["start", "end"], ["desc"], [...allJobs]);
          data = allJobs.length <= 10 ? allJobs : allJobs.slice(0, 10);
        }
        yield* put(
          rootActions.currentStatus.fetchJobSummariesForMachine.success({
            id: machineId,
            data,
          })
        );
      }
    }
    if (Object.keys(jobs).length > 0) {
      for (const machineId of allMachineIds) {
        let data =
          jobs[machineId] !== undefined
            ? jobs[machineId]!.map((job) => createJobPresentation(job))
            : [];
        const type = machines.find((m) => m.id === machineId)?.type;
        if (type === MachineType.Equator) {
          let masters: MasteringEvent[] = yield* call(
            () =>
              client.events.query<any[]>(
                `$filter=(machineId eq ${machineId})and(type eq 'Mastering')and(created ge ${from})and(created lt ${to})&$top=${
                  limit * 2
                }&$orderBy=created desc`
              ).promise
          );
          if (!masters) masters = [];
          const masterStarts = masters
            .filter((job) => job.active)
            .sort((a, b) => a.created.localeCompare(b.created));
          const masterEnds = masters
            .filter((job) => !job.active)
            .sort((a, b) => a.created.localeCompare(b.created));
          const pairedMasters: JobPresentation[] = masterStarts.map(
            (start, index) => {
              const nextStart = masterStarts[index + 1];
              let end: MasteringEvent | undefined;
              // We don't always have a sequenceExecutionId, but use it if we do.
              if (start.sequenceExecutionId) {
                end = masterEnds.find(
                  (j) => j.sequenceExecutionId === start.sequenceExecutionId
                );
              } else {
                end = masterEnds.find(
                  (m) =>
                    m.created > start.created &&
                    (!nextStart?.created || m.created < nextStart.created)
                );
              }

              if (!end) {
                return {
                  type: "mastering",
                  id: start.id,
                  name: start.name,
                  machineId: start.machineId,
                  start: start.created,
                  end: nextStart?.created,
                  status: "No Master End",
                  verdict: "No Verdict",
                };
              }
              return {
                type: "mastering",
                id: start.id,
                name: start.name,
                machineId: start.machineId,
                start: start.created,
                end: end.created || undefined,
                passes: end.characteristicsPassed ?? 0,
                failures: end.characteristicsFailed ?? 0,
                warnings: end.characteristicsWarning ?? 0,
                notToleranced: end.characteristicsNotToleranced ?? 0,
                status: mapStringToStatus("mastering", end.status),
                verdict: mapStringToVerdict(end.verdict),
              };
            }
          );
          let allJobs = uniqBy("start", [...pairedMasters, ...data]);
          allJobs = orderBy(["start", "end"], ["desc"], [...allJobs]);
          data = allJobs.length <= 10 ? allJobs : allJobs.slice(0, 10);
        }
        if (data && data.length > 0)
          yield* put(
            rootActions.currentStatus.fetchJobSummariesForMachine.success({
              id: machineId,
              data,
            })
          );
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* fetchMachineJobCycleTimeSaga() {
  // by adding the delay we increase the chances that jobs have finished fetching for ALL machines
  yield* delay(RELIEF_TIME);

  // Don't load expensive cycle time data if we aren't showing it
  const micro = yield* select(selectIsMicroView);
  if (micro) return;

  // Call this less often than the other page data because it's slow
  const lastFetched = yield* select(selectLastFetchedCycleTime);
  const config = yield* call(() => getGlobalConfigs());
  if (
    lastFetched !== null &&
    Date.now() - Date.parse(lastFetched) < 4 * config.refreshRateLiveInMS
  )
    return;

  try {
    const client: CMSClientType = yield getContext("client");
    const machines = yield* select(selectMachines);
    const { from, to } = yield* call(last28Days);
    const jobs = yield* select(
      (state: RootState) => state.currentStatus.machineJobSummaries
    );

    yield* all(
      machines.map(function* (machine) {
        const machineJobs = jobs[machine.id];
        if (!machineJobs) return;
        const latestJob = machineJobs[0];
        if (!latestJob) return;
        const cycleTime = yield* call(
          () =>
            client.machines.cycleTime({
              from,
              to,
              machineIds: [machine.id],
              jobName: latestJob.name,
            }).promise
        );
        if (cycleTime[0]) {
          yield* put(
            rootActions.currentStatus.fetchedMachineCycleData({
              machineId: machine.id,
              cycleTime: cycleTime[0],
            })
          );
        }
      })
    );
  } catch (error) {
    console.error(error);
  }
}

function* fetchAllMachineAlertsSaga() {
  try {
    const locations = yield* select(selectLocations);
    const machines = yield* select(selectMachines);
    const { from, to } = yield* call(last24Hours);
    for (const location of locations) {
      yield* all(
        machines
          .filter((machine) => machine.locationId === location.id)
          .map((machine) => call(fetchMachineAlertsSaga, machine.id, from, to))
      );
      yield* delay(RELIEF_TIME);
    }
    yield* all(
      machines
        .filter((machine) => !machine.locationId)
        .map((machine) => call(fetchMachineAlertsSaga, machine.id, from, to))
    );
  } catch (error) {
    console.error(error);
  }
}

function* fetchMachineAlertsSaga(
  machineId: string,
  start: string,
  end: string
) {
  try {
    const client: CMSClientType = yield getContext("client");
    const limit = 50;
    const alerts = yield* call(
      () =>
        client.events.query<Alert[]>(
          `$filter=(type eq 'Alert')and(machineId eq ${machineId})and(created gt ${start})and(created lt ${end})&$top=${limit}&$orderBy=created desc`
        ).promise
    );

    if (alerts) {
      const data = yield* call(() => createAlertsExpensive(alerts));
      yield* put(
        rootActions.currentStatus.fetchAlertsForMachine.success({
          id: machineId,
          data,
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* fetchCarouselData() {
  // Don't load expensive carousel data if we aren't showing it
  const micro = yield* select(selectIsMicroView);
  if (micro) return;

  const lastFetched = yield* select(selectLastFetchedCarouselData);

  const client: CMSClientType = yield getContext("client");
  yield* put(rootActions.currentStatus.fetchMachineCarouselData.request("All"));

  try {
    // get all sensors with special "showOnCurrentStatus" property
    const machines = yield* select(selectMachines);
    const filtered = yield* select(selectAreMachinesFiltered);
    // TODO: Some time after March 2023, change the query to "$filter=displayHints__showOnCurrentStatus eq true".
    // By then, showOnCurrentStatus will have moved from the top level to the displayHints child object.
    let q =
      "$filter=showOnCurrentStatus eq true or displayHints__showOnCurrentStatus eq true";
    if (filtered && machines.length) {
      q += ` and(machineId eq ${machines
        .map((m) => m.id)
        .join(" or machineId eq ")})`;
    }
    const sensors = yield* call(
      (query) => client.timeSeries.query(query).promise,
      q
    );
    if (!sensors.length) {
      yield* put(
        rootActions.currentStatus.fetchMachineCarouselData.success({
          id: "All",
          data: [],
        })
      );
      return;
    }

    const chunkedTimeSeries = chunk(GUID_QUERY_CHUNK_AMOUNT, sensors);
    const { from: start, to } = last24Hours();
    const from = lastFetched || start;

    const sensorValues = yield* all(
      chunkedTimeSeries.map((timeSeries) => {
        const params = new URLSearchParams();
        params.append("from", from);
        params.append("to", to);
        params.append("sample", constants.sample.Day);
        for (const sensor of timeSeries) {
          params.append("timeSeriesId", sensor.id);
        }
        return call(
          (params) =>
            client.timeSeries.compactValues({
              query: params,
            }).promise,
          params
        );
      })
    );

    const sensorLimits = yield* all(
      chunkedTimeSeries.map((timeSeries) => {
        const params = new URLSearchParams();
        params.append("from", from);
        params.append("to", to);
        for (const sensor of timeSeries) {
          params.append("timeSeriesId", sensor.id);
        }
        return call(
          (params) =>
            client.timeSeries.limits({
              query: params,
            }).promise,
          params
        );
      })
    );

    const sensorValuesMap = sensorValues.reduce<
      MappyAbsolute<TimeSeriesValueCompactValue[]>
    >((obj, next) => ({ ...obj, ...next }), {});

    const data = Object.keys(sensorValuesMap)
      .filter((id) => {
        return sensors.find((s) => s.id === id);
      })
      .map((id) => {
        return {
          sensor: sensors.find((s) => s.id === id)!,
          values: sensorValuesMap[id],
          limits: sensorLimits[0]
            .map((limitRow) => limitRow)
            .filter((l) => l.timeSeriesId === id)!,
        };
      });

    yield* put(
      rootActions.currentStatus.fetchMachineCarouselData.success({
        id: "All",
        data,
      })
    );
  } catch (ex) {
    console.warn(ex);
  }
}

function* fetchMachineStatusUpdates() {
  const config = yield* call(() => getGlobalConfigs());
  const client: CMSClientType = yield getContext("client");
  const lastFetched = yield* select((state) => state.global.lastFetched);
  const from = new Date(
    lastFetched || Date.now() - config.refreshRateCoreInMS
  ).toISOString();
  try {
    const machines = yield* select(selectMachines);
    const filtered = yield* select(selectAreMachinesFiltered);
    const machineList = filtered ? machines.map((m) => m.id) : [];
    const statusUpdates = yield* call(
      (from) =>
        client.events.statusUpdates.all(machineList, { query: { from } })
          .promise,
      from
    );
    yield* put(rootActions.global.setMachineStatusUpdates(statusUpdates));
  } catch (e) {
    console.log(e);
  }
}
