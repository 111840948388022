/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
/**
 * @description Identical to Object.keys except it is typed correctly.
 * That means that the passed object can be accessed using any of its defined properties.
 */
export function typedObjectKeys<T extends Record<string, unknown>>(
  o: T
): (keyof T)[] {
  return Object.keys(o) as (keyof T)[];
}
