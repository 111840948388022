/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import React from "react";
import { styled } from "@/styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#f75a5a, #a01515);
  color: white;
  text-shadow: 0 0 1px black;
`;
const Inner = styled.div`
  max-width: 800px;
`;
const Title = styled.div`
  font-size: 2rem;
`;
export const App = () => (
  <Wrapper>
    <Inner>
      <Title>Critical error!</Title>
      <br />
      <Title>Application failed to start.</Title>
      <br />
      <Title>
        Please clear and refresh your browser and try again later or contact
        your system adminstrator if the problem persists.
      </Title>
    </Inner>
  </Wrapper>
);
