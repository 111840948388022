/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import { ToolOffsetApplied, SortDirection } from "@centralwebteam/narwhal";
import {
  ProcessUpdatesSortColumn,
  OffsetAdjustmentTableSortColumn,
} from "@/store/state";
export const processUpdatesActions = {
  fetchingStarted: createStandardAction("[process updates] fetching data")(),
  fetchingFinished: createStandardAction(
    "[process updates] finished fetching data"
  )<{
    to: string;
  }>(),
  toolOffsetAppliedFetched: createStandardAction(
    "[process updates][saga] tool offset applied fetched"
  )<{
    events: ToolOffsetApplied[];
    from: string;
    to: string;
  }>(),
  toolOffsetAppliedDeltaFetched: createStandardAction(
    "[process updates][saga] tool offset applied delta fetched"
  )<{
    events: ToolOffsetApplied[];
    from: string;
    to: string;
  }>(),
  headerCellClicked: createStandardAction(
    "[process updates] header cell clicked"
  )<{
    sortColumn: ProcessUpdatesSortColumn;
    sortDirection: SortDirection;
  }>(),
  toolOffsetTypeRowClicked: createStandardAction(
    "[process updates] tool offset type row clicked"
  )<{ name: string }>(),
  expandAllClicked: createStandardAction(
    "[process updates] expand all clicked"
  )<string[]>(),
  collapseAllClicked: createStandardAction(
    "[process updates] collapse all clicked"
  )<void>(),
  offsetAdjustmentTableHeaderClicked: createStandardAction(
    "[process updates] offset adjustment table header clicked"
  )<[OffsetAdjustmentTableSortColumn, SortDirection]>(),
};

export type ProcessUpdatesActions = ActionType<typeof processUpdatesActions>;
