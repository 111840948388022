/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import { ProvisionMachine, GUID } from "@centralwebteam/narwhal";
import { ProvisioningSchemaType } from "@/presentation/Machine/provision";

export const actions = {
  provisionRequestsFetched: createStandardAction(
    "[manage machine provisioning] fetched machines that need provision (saga)"
  )<ProvisionMachine[]>(),
  deviceListItemClicked: createStandardAction(
    "[manage machine provisioning] device clicked"
  )<GUID>(),
  approveMachineButtonClicked: createStandardAction(
    "[manage machine provisioning] approve machine button clicked"
  )<{
    provisionRequest: ProvisioningSchemaType;
    selectedMachineId: string | undefined;
    selectedOption: string;
    deletedLinkedMachineId: string | undefined;
    notificationMessage: any;
  }>(),
  deleteMachineButtonClicked: createStandardAction(
    "[manage machine provisioning] delete machine button clicked"
  )(),
  machineApproved: createStandardAction(
    "[manage machine provisioning] machine approved (saga)"
  )<string>(),
  machineApprovalDeleted: createStandardAction(
    "[manage machine provisioning] machine approval deleted (saga)"
  )<string>(),
};

export type Actions = ActionType<typeof actions>;
