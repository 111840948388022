/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;

export const actions = {
  setPreferences: createStandardAction("[manage my account] set preferences")<{
    pages: string[];
  }>(),
  updatePasswordButtonClicked: createStandardAction(
    "[manage my account] update password button clicked"
  )<{
    currentPassword: string;
    newPassword: string;
  }>(),
  updateEmailButtonClicked: createStandardAction(
    "[manage my account] update email button clicked"
  )<{
    currentPassword: string;
    newEmailAddress: string;
  }>(),
  updateEmailSuccess: createStandardAction(
    "[manage my account][saga] update email success"
  )<string>(),
  activeTabChanged: createStandardAction(
    "[manage my account] active tab changed"
  )<string>(),
  updatePreferencesPageUpdateButtonClicked: createStandardAction(
    "[default view] update preferences page submit button clicked"
  )<{ pages: string[] }>(),
  updatePreferencesPageSuccess: createStandardAction(
    "[default view] update preferences page success"
  )<{ pages: string[] }>(),
};
export type Actions = ActionType<typeof actions>;
