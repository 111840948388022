/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  isAfter,
  isBefore,
  setMinutes,
  setSeconds,
  setHours,
  setMilliseconds,
  parseISO,
} from "date-fns";
import findLast from "lodash/fp/findLast";
import { AlertPresentation } from ".";
import { AlertState } from "./state";
import { AlertLevel } from "./level";
import { Alert } from "@centralwebteam/narwhal";

/**
 *  Attempt to find linked future alert (matching on on alert name)
 */
export function findLinkedAlertFuture(
  alerts: (Alert | AlertPresentation)[],
  alert: AlertPresentation
) {
  return findLast((x) => {
    if (x instanceof AlertPresentation) {
      return (
        x.id !== alert.id &&
        x.name === alert.name &&
        x.state === AlertState.unset &&
        isAfter(x.created, alert.created)
      );
    } else {
      return (
        x.id !== alert.id &&
        x.name === alert.name &&
        !x.active &&
        isAfter(parseISO(x.created), alert.created)
      );
    }
  }, alerts);
}

/**
 *  Attempt to find linked past alert (matching on on alert name)
 */
export function findLinkedAlertPast(
  alerts: AlertPresentation[],
  alert: AlertPresentation
) {
  return alerts.find(
    (x) =>
      x.id !== alert.id &&
      x.name === alert.name &&
      isBefore(x.created, alert.created)
  );
}

export function getActiveNotClearedAlerts(
  alerts: AlertPresentation[] | undefined
) {
  if (!alerts) return [];
  return alerts.filter(
    (alert) => alert.state === AlertState.set && alert.cleared === false
  );
}

/**
 * Returns the count and level of the highest level alert
 */
export function getTopLevelAlertCount(alerts: AlertPresentation[] | undefined) {
  if (!alerts)
    return {
      level: AlertLevel.unknown,
      count: 0,
    };
  let errorCount = 0;
  let warningCount = 0;

  for (const alert of alerts) {
    if (alert.state === AlertState.unset) continue;
    if (alert.level === AlertLevel.error) errorCount++;
    else if (alert.level === AlertLevel.warning) warningCount++;
  }
  return errorCount === 0 && warningCount === 0
    ? {
        level: AlertLevel.unknown,
        count: 0,
      }
    : errorCount > 0
      ? {
          level: AlertLevel.error,
          count: errorCount,
        }
      : {
          level: AlertLevel.warning,
          count: warningCount,
        };
}

/**
 * Returns unique dates (Date)
 */
export function getUniqueDates(alerts: AlertPresentation[] | undefined) {
  if (!alerts) return [];
  return Object.keys(
    alerts.reduce(
      (a, b) => {
        const setToDate = setHours(
          setMinutes(setSeconds(setMilliseconds(b.created, 0), 0), 0),
          0
        );
        if (a[setToDate.toISOString()]) return a;
        else
          return {
            ...a,
            [setToDate.toISOString()]: true,
          };
      },
      {} as { [index: string]: boolean }
    )
  ).map((dateString) => new Date(dateString));
}

/**
 * Returns the "set" alerts (i.e. the starts, not the ends) for the specified levels, or all levels if none specified.
 * @param alerts List of alerts to process
 * @param levels list of alert levels
 */
export function getAlertsByLevel(
  alerts: AlertPresentation[],
  levels: string[]
) {
  try {
    return !alerts?.length
      ? []
      : alerts.filter((alert) => {
          return (
            alert.state === AlertState.set &&
            (!levels.length || levels.find((level) => level === alert.level))
          );
        });
  } catch (ex) {
    console.error(ex);
    return [];
  }
}
