/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { call, select, put, all, getContext } from "typed-redux-saga";
import {
  selectFocusedMachine,
  selectStartAndEnd,
  selectActiveJobTypes,
  selectActiveMeasurementDetailsWithoutData,
  selectActiveTimeSeriesTypesWithoutData,
} from "@/store/selectors";
import { rootActions } from "..";
import { getGlobalConfigs } from "@/index";
import { data } from "./dataSaga";
import { CMSClientType } from "@/cms-api";
import { toUTCISOString } from "@/modules/dateFormats/index";
import { TimeSeriesValueCompactValue } from "@centralwebteam/narwhal";
import { subMonths } from "date-fns";

/**
 * Fetches all of the required static data for the current timerange.
 */
export const allStaticData = function* () {
  const now = toUTCISOString(new Date());
  try {
    yield* put(
      rootActions.machineAnalysis.fetchingStarted({ loadingType: "data" })
    );
    yield* call(seriesTypesStaticData);
    yield* all([
      call(timeSeriesLimitsStaticData),
      call(focusedMachineStateSummaryData),
      call(timeSeriesValuesStaticData),
      call(timelineStaticData),
      call(measurementSeriesValuesStaticData),
      call(unitHintsStaticData),
    ]);
  } catch (error) {
    console.log(error);
  } finally {
    yield* put(
      rootActions.machineAnalysis.fetchingFinished({
        to: now,
        loadingType: "data",
      })
    );
  }
};

/**
 * Fetches machine state summary for current timerange.
 */
function* focusedMachineStateSummaryData() {
  try {
    const client: CMSClientType = yield getContext("client");
    const [from, to] = yield* select(selectStartAndEnd);
    const machine = yield* select(selectFocusedMachine);
    if (!machine) return;
    const stateSummary = yield* call(
      (params) => client.machines.stateSummary(params).promise,
      {
        from,
        to,
        machineIds: [machine.id],
      }
    );
    yield* put(rootActions.machineAnalysis.statesSummaryFetched(stateSummary));
  } catch (error) {
    console.error(error);
  }
}

/**
 * Fetches timeline (jobs, alerts & states) data for current timerange.
 */
function* timelineStaticData() {
  const config = yield* call(() => getGlobalConfigs());
  const focusedMachine = yield* select(selectFocusedMachine);
  if (!focusedMachine) return;
  const [from, to] = yield* select(selectStartAndEnd);
  const options = {
    from,
    to,
    machineId: focusedMachine.id,
  };
  const { jobs, states, alerts, processActions } = yield* all({
    jobs: call(data.fetchJobSummaries, options),
    states: call(data.fetchMachineStates, options),
    alerts: call(data.fetchAlerts, options),
    processActions: call(data.fetchProcessActions, options),
  });
  // display a warning if a large amount of data is returned
  if (
    [jobs, states, alerts, processActions].reduce(
      (total, arr) => total + arr.length,
      0
    ) >= config.warningLimit
  ) {
    yield* put(
      rootActions.machineAnalysis.timelineStaticDataWarningLimitBreached()
    );
  }
  yield* all([
    put(
      rootActions.machineAnalysis.jobSummariesFetched({
        jobs,
        from: options.from,
        to: options.to,
      })
    ),
    put(
      rootActions.machineAnalysis.statesFetched({
        states,
        from: options.from,
        to: options.to,
      })
    ),
    put(
      rootActions.machineAnalysis.alertsFetched({
        alerts,
        from: options.from,
        to: options.to,
      })
    ),
    put(
      rootActions.machineAnalysis.processActionsFetched({
        events: processActions,
        from: options.from,
        to: options.to,
      })
    ),
  ]);
}

/**
 * Fetches series static data (measurements and timeseries) for current timerange.
 */
export function* seriesTypesStaticData() {
  const focusedMachine = yield* select(selectFocusedMachine);
  if (!focusedMachine) return;
  const [from, to] = yield* select(selectStartAndEnd);
  const activeJobTypes = yield* select(selectActiveJobTypes);
  const options = {
    from,
    to,
    machineId: focusedMachine.id,
    jobs: activeJobTypes,
  };
  const { uniqueMeasurements, uniqueTimeSeries } = yield* all({
    uniqueMeasurements: call(data.fetchUniqueMeasurementsTypes, options),
    uniqueTimeSeries: call(data.fetchUniqueTimeSeries, options),
  });
  yield* all([
    put(
      rootActions.machineAnalysis.measurementTypesFetched({
        types: uniqueMeasurements,
      })
    ),
    put(
      rootActions.machineAnalysis.timeSeriesTypesFetched({
        types: uniqueTimeSeries,
      })
    ),
  ]);
}

export function* timeSeriesLimitsStaticData() {
  const focusedMachine = yield* select(selectFocusedMachine);
  if (!focusedMachine) return;
  const [from, to] = yield* select(selectStartAndEnd);
  const activeTimeSeries = yield* select(
    selectActiveTimeSeriesTypesWithoutData
  );
  const timeSeriesLimits = yield* all(
    activeTimeSeries.map(({ id }) =>
      call(data.fetchTimeSeriesLimits, {
        from,
        to,
        id,
      })
    )
  );
  yield* all(
    timeSeriesLimits.map((limit) =>
      put(rootActions.machineAnalysis.timeSeriesLimitsFetched(limit))
    )
  );
}

export function* measurementSeriesValuesStaticData() {
  const now = toUTCISOString(new Date());
  yield* put(
    rootActions.machineAnalysis.fetchingStarted({
      loadingType: "measurementSeriesValues",
    })
  );
  const focusedMachine = yield* select(selectFocusedMachine);
  if (!focusedMachine) return;
  const [from, to] = yield* select(selectStartAndEnd);
  const activeJobTypes = yield* select(selectActiveJobTypes);
  const emptySeries = yield* select(selectActiveMeasurementDetailsWithoutData);
  const measurements = yield* all(
    emptySeries.map(({ featureName, name }) =>
      call(data.fetchMeasurementValues, {
        featureName,
        name,
        from,
        to,
        machineId: focusedMachine.id,
        jobName: activeJobTypes,
      })
    )
  );
  yield* all(
    measurements.map((measurementData) =>
      put(rootActions.machineAnalysis.measurementValuesFetched(measurementData))
    )
  );
  yield* put(
    rootActions.machineAnalysis.fetchingFinished({
      to: now,
      loadingType: "measurementSeriesValues",
    })
  );
}

export function* timeSeriesValuesStaticData() {
  const now = toUTCISOString(new Date());
  yield* put(
    rootActions.machineAnalysis.fetchingStarted({
      loadingType: "timeSeriesValues",
    })
  );
  const focusedMachine = yield* select(selectFocusedMachine);
  if (!focusedMachine) return;
  const [from, to] = yield* select(selectStartAndEnd);
  const activeTimeSeries = yield* select(
    selectActiveTimeSeriesTypesWithoutData
  );
  const timeSeriesValues = yield* all(
    activeTimeSeries.map(({ id, displayHints, displayHintSampleRate }) =>
      call(data.fetchTimeSeriesValues, {
        id,
        from,
        to,
        sample: (displayHints?.sampleRate ?? displayHintSampleRate) as
          | "Raw"
          | "Hour"
          | "Day"
          | "Week"
          | "Month"
          | "Default"
          | undefined,
      })
    )
  );
  yield* all(
    timeSeriesValues.map(
      (values: {
        id: string;
        from: string;
        to: string;
        data: TimeSeriesValueCompactValue[];
      }) => put(rootActions.machineAnalysis.timeSeriesValuesFetched(values))
    )
  );
  yield* put(
    rootActions.machineAnalysis.fetchingFinished({
      to: now,
      loadingType: "timeSeriesValues",
    })
  );
}

/**
 * Fetches the latest unit hints from the current end time to 6 months previous.
 */
function* unitHintsStaticData() {
  try {
    const machine = yield* select(selectFocusedMachine);
    if (!machine) return;

    const client: CMSClientType = yield getContext("client");
    const [, to] = yield* select(selectStartAndEnd);
    const fromSixMonthsAgo = subMonths(new Date(to), 6).toISOString();

    const latestHint = yield* call(
      (fromSixMonthsAgo, to) =>
        client.events.jobProperties.displayHintUnitsLatest(
          fromSixMonthsAgo,
          to,
          machine.id
        ).promise,
      fromSixMonthsAgo,
      to
    );

    yield* put(
      rootActions.machineAnalysis.unitDisplayHintsFetched({
        hints: latestHint,
        from: fromSixMonthsAgo,
        to,
      })
    );
  } catch (error) {
    console.error(error);
  }
}
