/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { Location } from "@centralwebteam/narwhal";

export type LocationUI = {
  id: string;
  name: string;
  parentId?: string | null;
  descendants: string[];
  ancestors: string[];
  children: string[];
  trail: string;
  timeZone: string;
};

/**
 * Create a LocationUI array from a Location array
 * @param locations - All locations. Important! This should be flat.
 */
export function create(locations: Location[]): LocationUI[] {
  return locations
    .map((l) => createLocation(l, locations))
    .sort((a, b) => a.trail.localeCompare(b.trail));
}

/**
 * Create a LocationUI object from a Location object
 * @param location
 * @param locations - All locations. Important! This should be flat.
 */
export function createLocation(location: Location, locations: Location[]) {
  const { id, name, parentId, timeZone } = location;
  const locationWithContext = {
    id,
    name,
    parentId,
    timeZone,
    children: getChildren(id, locations),
    ancestors: getAncestors(location, locations),
  };
  const descendants = getDescendants(locationWithContext, locations);
  const trail = getLocationTrail(locationWithContext, locations);
  return { ...locationWithContext, descendants, trail };
}

function tooDeep(depth: number) {
  if (depth > 99) {
    console.error("Location tree depth limit exceeded");
    return true;
  }
  return false;
}

function getChildren(id: string, locations: Location[]) {
  if (!locations?.length || !id) return [];
  return locations.filter((child) => child.parentId === id).map(({ id }) => id);
}

export function getAncestors(
  root: { id: string; parentId?: string | null },
  locations: Location[],
  ancestors: string[] = [],
  depth = 0
): string[] {
  if (!root || tooDeep(depth)) return ancestors;
  if (depth > 0) ancestors.push(root.id);
  if (!root.parentId) {
    return ancestors;
  } else {
    return getAncestors(
      locations.find((l) => l.id === root.parentId)!,
      locations,
      ancestors,
      depth + 1
    );
  }
}

function getDescendants(
  root: { id: string },
  locations: Location[],
  descendants: string[] = [],
  depth = 0
) {
  if (!root || tooDeep(depth)) return descendants;
  if (depth > 0) descendants.push(root.id);
  for (const child of getChildren(root.id, locations)) {
    descendants = getDescendants(
      { id: child },
      locations,
      descendants,
      depth + 1
    );
  }
  return descendants;
}

function getLocationTrail(
  location: { name: string; ancestors: string[] },
  locations: { id: string; name: string }[]
) {
  const ascLocations = [
    location.name,
    ...location.ancestors
      .map((id) => locations.find((l) => l.id === id))
      .filter((l) => l !== undefined)
      .map((l) => l!.name),
  ];
  return ascLocations.reverse().join(" — ");
}

export type LocationRequiredProperties = {
  id: string;
  name: string;
  descendants: string[];
};

/**
 * For the location filter component
 * Determine which locations are active.
 * Locations that have descendants are only active when they and all their descendants are active
 */
export const getActiveLocations = (
  locations: LocationRequiredProperties[],
  activeLocations: string[]
) => {
  if (!activeLocations?.length) return [];
  return locations
    .filter((location) => {
      return (
        activeLocations.includes(location.id) &&
        location.descendants.every((descendants) =>
          activeLocations.includes(
            locations.find((l) => l.id === descendants)!.id
          )
        )
      );
    })
    .map((l) => l.id);
};
