/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { fork, take, cancel } from "typed-redux-saga";
import { getType } from "typesafe-actions";
import { rootActions } from "..";
import { fetchStaticData, liveRefreshWatcher } from "./sagas";
import { Task } from "redux-saga";

export function* machinePerformanceRootSaga() {
  // start initial data fetching - fork so we can listen for actions / cancel whilst it is in progress.
  let staticDataTask = yield* fork(fetchStaticData);
  let liveRefreshTask: Task | undefined = undefined;
  // each time date changes
  while (yield* take([getType(rootActions.session.startEndUpdated)])) {
    // if still running initial task cancel it
    if (staticDataTask) yield* cancel(staticDataTask);
    // cancel live task
    if (liveRefreshTask) yield* cancel(liveRefreshTask);
    // refork static/live effects
    staticDataTask = yield* fork(fetchStaticData);
    liveRefreshTask = yield* fork(liveRefreshWatcher);
  }
}
