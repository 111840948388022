/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  MeasurementCharacteristic,
  FeatureGroup,
  units,
} from "@centralwebteam/narwhal";
import { Verdict, mapStringToVerdict } from "../Job";
import { toleranceTypeMapper } from "../ToleranceType";

export type MeasurementCharacteristicPresentation = {
  // info
  id: string;
  type?: string;
  created: string;
  featureName?: string;
  name: string;
  toleranceType: string | undefined;
  unit?: string;
  featureGroups?: FeatureGroup[];
  // results
  actual: number;
  deviation?: number;
  normalisedDeviation?: number;
  error?: number;
  nominal?: number;
  verdict: Verdict;
  inTolerance?: boolean;
  // limits
  upperWarnLimit?: number;
  lowerWarnLimit?: number;
  upperLimit?: number;
  lowerLimit?: number;
};

export function createMeasurementCharacteristicPresentation(
  measurementCharacteristic: MeasurementCharacteristic
): MeasurementCharacteristicPresentation {
  return {
    ...measurementCharacteristic,
    unit: units.standardise(measurementCharacteristic.unit),
    toleranceType: toleranceTypeMapper(measurementCharacteristic.toleranceType),
    verdict: mapStringToVerdict(measurementCharacteristic.verdict),
  };
}

export function createUniqueMeasurementID({
  featureName,
  name,
}: {
  featureName?: string;
  name: string;
}) {
  return `${featureName ?? "no-feature"}/${name}`;
}
