/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import "core-js";
import "regenerator-runtime/runtime";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import App from "./App";
import "@/i18n";
import { App as Error } from "./App/Error";
import axios from "axios";
import { setBase } from "./axios";
import { startStore } from "./store";
import { isSupported } from "./browserSupport";
import defaultConfig from "@/config/index.json";
import defaultBuildConfig from "@/config/buildinfo.json";
import { updateLocale } from "@centralwebteam/jellyfish";
import i18n from "i18next";

export type Config = typeof defaultConfig;
let config: Config = defaultConfig;
let buildConfig: typeof defaultBuildConfig = defaultBuildConfig;
let apiBase = "";

// These config getters cause problems in some situations, such as in selectors and in workers.
// If you need configured values there, you need to either inject them in props or use the store.
// Add to the reducer for the `configLoaded` action if you need more config in the store.
export const getBaseURL = () => apiBase;
export const getGlobalConfigs = () => config.globalConfigs;
export const getBuildConfigs = () => buildConfig;

async function start() {
  const container = document.getElementById("app");
  try {
    if (!isSupported) return;
    config = await loadConfig();
    buildConfig = await loadBuildConfig();
    apiBase = config.api || "../api/";
    setBase(apiBase);
    updateLocale(i18n.language);
    startStore();
    ReactDOMClient.createRoot(container!).render(<App config={config} />);
  } catch (error: any) {
    console.error(error);
    // Build server throws this error from unit test "Renders error message if status change is in the future".
    if (error.message?.includes("Target container")) return;
    ReactDOMClient.createRoot(container!).render(<Error />);
  }
}

start();

async function loadConfig() {
  try {
    const base = window.location.href.split(/[#?]/)[0];
    return await axios.get(`${base}config/index.json`).then((res) => res.data);
  } catch (error) {
    console.log("Error", error);
    // This happens in unit test context
    return defaultConfig;
  }
}

async function loadBuildConfig() {
  try {
    const base = window.location.href.split(/[#?]/)[0];
    return await axios
      .get(`${base}config/buildinfo.json`)
      .then((res) => res.data);
  } catch (error) {
    console.log("Error", error);
    return { buildNumber: "unknown" };
  }
}

// We have promise cancellation which won't neccesarily get handled.
// Match on the event.reason.message if it contains "cancelled" supress it (we don't care!).
window.addEventListener("unhandledrejection", function (promiseRejectionEvent) {
  if (
    promiseRejectionEvent.reason.message &&
    !promiseRejectionEvent.reason.message.toLowerCase().includes("cancelled")
  )
    console.error(promiseRejectionEvent);
  else promiseRejectionEvent.preventDefault();
});
