/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getContext } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { CMSClientType } from "@/cms-api";

export const data = {
  fetchToolOffsetApplied: function* fetchToolOffsetApplied({
    from,
    to,
    take,
  }: {
    from: string;
    to: string;
    take: number;
  }) {
    const client: CMSClientType = yield getContext("client");
    const toolOffsetApplied = yield* call(
      (from, to) => client.events.toolOffsetApplied.all(from, to, take).promise,
      from,
      to
    );
    const toolOffsetCalculation = yield* call(
      (from, to) =>
        client.events.toolOffsetCalculation.all(from, to, take).promise,
      from,
      to
    );
    return (toolOffsetApplied || []).concat(toolOffsetCalculation);
  },
};
