/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { Alert } from "@centralwebteam/narwhal";
import { AlertLevel, levelMapper, levelColours } from "./level";
import { AlertState, stateMapper } from "./state";

/**
 * AlertPresentation maps a raw Alert from the API to a presentation focused class.
 * Intensive operations such as linking alerts are not done during construction,
 * instead they are pure functions which each take a list of alerts and a subject alert.
 * These can be found in ./functions.
 */
export class AlertPresentation {
  id: string;
  name: string;
  level: AlertLevel;
  created: Date;
  state: AlertState;
  layer?: number;
  fill: string;
  alerts?: AlertPresentation[];
  cleared?: boolean;
  duration?: number;

  constructor(alert: Alert & { alerts?: AlertPresentation[] }) {
    const { id, name, created, active, level, layer, cleared, alerts } = alert;
    this.id = id;
    this.name = name;
    this.level = levelMapper(level);
    this.created = new Date(created);
    this.state = stateMapper(active);
    this.layer = layer;
    this.fill = levelColours[this.level];
    this.alerts = alerts;
    this.cleared = cleared;
  }
}
