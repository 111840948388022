/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { RootState } from "..";

export const getSelectedNode = (s: RootState) =>
  s.manageAssets.singleSelectedNode;

export const getMultiSelectedNodes = (s: RootState) =>
  s.manageAssets.multiSelectedNodes;

export const getSelectionMode = (s: RootState) => s.manageAssets.mode;
