/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import { CentralUser, NewAccount } from "@centralwebteam/narwhal";

const usersFetched = createStandardAction("[manage users] users fetched")<
  CentralUser[]
>();

const userListItemClicked = createStandardAction(
  "[manage users] user list item clicked"
)<string>();

const addNewUserButtonClicked = createStandardAction(
  "[manage users] add new user button clicked"
)<void>();

const deleteUserButtonClicked = createStandardAction(
  "[manage users] delete user button clicked"
)<CentralUser>();

const activateUserButtonClicked = createStandardAction(
  "[manage users] activate user button clicked"
)<CentralUser>();

const deactivateUserButtonClicked = createStandardAction(
  "[manage users] deactivate user button clicked"
)<CentralUser>();

const createNewUserButtonClicked = createStandardAction(
  "[manage users] create new user button clicked"
)<NewAccount>();

const saveChangesButtonClicked = createStandardAction(
  "[manage users] save changes button clicked"
)<CentralUser>();

const confirmDeletion = createStandardAction(
  "[manage users] delete confirmation requested (saga)"
)();
const confirmButtonClicked = createStandardAction(
  "[manage users] delete confirmation box confirm button clicked"
)();
const cancelButtonClicked = createStandardAction(
  "[manage users] delete confirmation box cancel button clicked"
)();

const userCreated = createStandardAction("[manage users][saga] user created")<{
  userId: string;
}>();

export const actions = {
  usersFetched,
  userListItemClicked,
  addNewUserButtonClicked,
  deleteUserButtonClicked,
  activateUserButtonClicked,
  deactivateUserButtonClicked,
  createNewUserButtonClicked,
  saveChangesButtonClicked,
  confirmDeletion,
  confirmButtonClicked,
  cancelButtonClicked,
  userCreated,
};
export type Actions = ActionType<typeof actions>;
