/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState, TypedDispatch } from "@/store";
import { NotificationPresentation } from "@centralwebteam/jellyfish";
import { dismissNotification } from "@/store/global/thunks";

const mapStateToProps = (state: RootState) => state.global.notification;

const mapDispatchToProps = (dispatch: TypedDispatch) =>
  bindActionCreators(
    {
      dismiss: dismissNotification,
    },
    dispatch
  );

export const Notification = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationPresentation);
