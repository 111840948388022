/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { expose } from "@/modules/comlink";
import { AlertPresentation } from "@/presentation/Alert";
import { Alert } from "@centralwebteam/narwhal";
import { AlertState } from "./state";
import { findLinkedAlertFuture } from "./functions";
import { differenceInSeconds, parseISO } from "date-fns";

function createAlertsExpensive(alerts: (Alert | AlertPresentation)[]) {
  return alerts.map((alert) => {
    const presentation = Object.prototype.hasOwnProperty.call(alert, "state")
      ? (alert as AlertPresentation)
      : new AlertPresentation(alert as Alert);
    if (presentation.state === AlertState.unset) {
      presentation.cleared = true;
    } else {
      const end = findLinkedAlertFuture(alerts, presentation);
      presentation.cleared = end !== undefined;
      if (end !== undefined) {
        presentation.duration = differenceInSeconds(
          typeof end.created === "string" ? parseISO(end.created) : end.created,
          presentation.created
        );
      }
    }
    return presentation;
  });
}

export default createAlertsExpensive;

const exposed = {
  createAlertsExpensive,
};

export type WorkerType = typeof exposed;

expose(exposed);
