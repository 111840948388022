/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getType } from "typesafe-actions";
import { rootActions, RootState } from "../";
import {
  takeLatest,
  all,
  getContext,
  call,
  put,
  select,
} from "typed-redux-saga";
import { setNotification } from "../global/thunks";
import constants from "@/constants";
import { selectUsername } from "../selectors";
import { CMSClientType } from "@/cms-api";
import { getErrorMessage, isHttpAuthError } from "@/axios/errors";
import i18n from "@/i18n";

// Get all translations in advance - can't use promises inline here
let pwChanged = "",
  pwChangedBody = "",
  pwChangeFailed = "",
  pwChangeFailedBody = "",
  emailChanged = "",
  emailChangedBody = "",
  emailChangeFailed = "",
  emailChangeFailedBody = "",
  pageVisChanged = "",
  pageVisChangedBody = "",
  pageVisChangeFailed = "",
  incorrectPassword = "";
i18n.then((t) => {
  pwChanged = t("message-Password Changed");
  pwChangedBody = t("message-passwordChangedConfirm");
  pwChangeFailed = t("message-passwordChangeFailed");
  pwChangeFailedBody = t("message-passwordChangeNotRecognised");
  emailChanged = t("message-Email Updated");
  emailChangedBody = t("message-emailUpdatedConfirm");
  emailChangeFailed = t("message-emailUpdateFailed");
  emailChangeFailedBody = t("message-invalidCredentials");
  pageVisChanged = t("message-pageVisibilityChanged");
  pageVisChangedBody = t("message-pageVisibilityChangedDetail");
  pageVisChangeFailed = t("message-pageVisibilityChangeFailed");
  incorrectPassword = t("message-incorrectPassword");
});

export function* manageMyAccountRootSaga() {
  // initially load preferences
  yield* getPreferencesSaga();
  yield* all([
    takeLatest(
      getType(rootActions.manageMyAccount.updatePasswordButtonClicked),
      changePasswordSaga
    ),
    takeLatest(
      getType(rootActions.manageMyAccount.updateEmailButtonClicked),
      changeEmailSaga
    ),
    takeLatest(
      getType(rootActions.manageMyAccount.updateEmailSuccess),
      updatedEmailWatcherSaga
    ),
    yield* takeLatest(
      getType(
        rootActions.manageMyAccount.updatePreferencesPageUpdateButtonClicked
      ),
      changePreferencesPagesSaga
    ),
  ]);
}

export function* changePasswordSaga({ payload }: any) {
  try {
    const client: CMSClientType = yield getContext("client");
    yield* call(
      (passwords) => client.users.changePassword(passwords).promise,
      payload
    );
    // @ts-ignore
    yield* put(setNotification(pwChanged, [pwChangedBody], "success"));
  } catch (error) {
    let notificationBody = isHttpAuthError(error)
      ? pwChangeFailedBody
      : getErrorMessage(error);
    // @ts-ignore
    if (error?.response?.data === "Password validation failed") {
      notificationBody = incorrectPassword;
    }

    yield* put(
      // @ts-ignore
      setNotification(
        pwChangeFailed,
        notificationBody === "" ? [] : [notificationBody],
        "error"
      )
    );
  }
}

export function* changeEmailSaga({ payload }: any) {
  try {
    const client: CMSClientType = yield getContext("client");
    yield* call(
      (userDetails) => client.users.changeEmail(userDetails).promise,
      payload
    );
    yield* put(
      rootActions.manageMyAccount.updateEmailSuccess(payload.newEmailAddress)
    );
    // @ts-ignore
    yield* put(setNotification(emailChanged, [emailChangedBody], "success"));
  } catch (error) {
    let notificationBody = isHttpAuthError(error)
      ? emailChangeFailedBody
      : getErrorMessage(error);

    // @ts-ignore
    if (error?.response?.data === "Password validation failed") {
      notificationBody = incorrectPassword;
    }

    yield* put(
      // @ts-ignore
      setNotification(
        emailChangeFailed,
        notificationBody === "" ? [] : [notificationBody],
        "error"
      )
    );
  }
}

/**
 * When the email changes we want to update the persisted auth object (username prop.) in local storage.
 */
export function* updatedEmailWatcherSaga() {
  const username = yield* select(selectUsername);
  const auth = JSON.parse(localStorage.getItem(constants.authLocalStorageKey)!);
  localStorage.setItem(
    constants.authLocalStorageKey,
    JSON.stringify({
      ...auth,
      username,
    })
  );
}

function* changePreferencesPagesSaga({ payload: { pages } }: any) {
  const client = yield* getContext<CMSClientType>("client");
  try {
    const preferences = yield* select(
      (state: RootState) => state.global.preferences
    );
    yield* call((pages) => client.preferences.updatePages(pages).promise, {
      ...preferences,
      pages: pages,
    });

    yield* put(
      rootActions.manageMyAccount.updatePreferencesPageSuccess({ pages })
    );

    yield* put(
      // @ts-ignore
      setNotification(pageVisChanged, [pageVisChangedBody], "success")
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(pageVisChangeFailed, [getErrorMessage(error)], "error")
    );
  } finally {
    yield* getPreferencesSaga();
  }
}

function* getPreferencesSaga() {
  try {
    const client = yield* getContext<CMSClientType>("client");
    // Get preferences
    const preferences = yield* call(() => client.preferences.all().promise);
    yield* put(rootActions.manageMyAccount.setPreferences(preferences));
  } catch (e) {
    console.log(e);
  }
}
