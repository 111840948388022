/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { RootState, RootActions, rootActions, store } from "./index";
import { createHashHistory, Location } from "history";
import { selectPath } from "./selectors";
import { Store } from "redux";

export const history = createHashHistory({
  hashType: "hashbang",
});

/**
 * Syncs the URL and store.
 */
export function startStoreAndLocationSync() {
  // we use this flag (puke) so we can set the store based on the URL to begin with.
  let storeStartup = false;
  syncParamsToStore(history.location, store);
  syncPathToStore(history.location, store);
  const cancelStore = store.subscribe(() => {
    if (storeStartup === false) {
      storeStartup = true;
      return;
    }
    syncStoreToLocation(store);
  });
  const cancelHistory = history.listen((location: Location) => {
    syncParamsToStore(location, store);
    syncPathToStore(location, store);
  });
  return () => {
    cancelHistory();
    cancelStore();
  };
}

function syncStoreToLocation(store: Store<RootState, RootActions>) {
  const path = encodeURI(selectPath(store.getState()));
  // Important! If the current path is the same as the location path do NOT update the location (infinite loop protection)
  if (history.location.pathname + history.location.search !== path) {
    history.push(path);
  }
}

function syncParamsToStore(
  location: Location,
  store: Store<RootState, RootActions>
) {
  // update search params
  const params = new URLSearchParams(location.search);
  const {
    params: { start, end, focusedMachineId, focusedJobId, token, umrt },
  } = store.getState();
  if (
    (params.has("start") && params.get("start") !== start) ||
    (params.has("end") && end !== params.get("end"))
  ) {
    store.dispatch(
      rootActions.session.startEndUpdated([
        params.get("start")!,
        params.get("end"),
      ])
    );
  }
  if (params.get("focusedMachineId") !== focusedMachineId) {
    store.dispatch(
      rootActions.session.focusedMachineIdUpdated(
        params.get("focusedMachineId")
      )
    );
  }
  if (params.get("focusedJobId") !== focusedJobId) {
    store.dispatch(
      rootActions.session.focusedJobIdUpdated(params.get("focusedJobId"))
    );
  }
  if (params.has("token") && params.get("token") !== token) {
    store.dispatch(
      rootActions.session.passwordResetTokenUpdated(params.get("token")!)
    );
  }
  // For Minotaur Use url token (umrt) to authenticate user
  if (params.has("umrt") && params.get("umrt") !== umrt) {
    store.dispatch(rootActions.session.umrtUpdated(params.get("umrt")!));
  }
  // get language from local storage
  const lngInStorage = localStorage.getItem("i18nextLng");
  const hasLngParamsInURL = params.has("lng");

  if (hasLngParamsInURL && params.get("lng") !== lngInStorage) {
    store.dispatch(
      rootActions.session.languageUpdated(params.get("lng")!.toLowerCase())
    );
    window.location.reload();
  }
}

function syncPathToStore(
  location: Location,
  store: Store<RootState, RootActions>
) {
  // previous selected page pathname and search before navigate to ipc page
  if (location.pathname !== "/ipc")
    localStorage.setItem(
      "location-pathname-search",
      JSON.stringify({
        search: history.location.search,
        pathname: history.location.pathname,
      })
    );
  if (JSON.stringify(location.pathname) === undefined) return;
  // update view
  store.dispatch(rootActions.session.routeChanged(location));
}
