/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { expose } from "@/modules/comlink";
import { JobAggregate, JobStatsAggregate } from "@/store/state";
import {
  JobPresentation,
  mapVerdictToGroup,
  mapStatusToGroup,
} from "@/presentation/Job";
import { assertUnreachable } from "@/@types/assertUnreachable";
import { groupBy, orderBy } from "lodash/fp";
import { t } from "@/modules/string/translate";

const newJobAggregate = (): JobAggregate => {
  return {
    completed: 0,
    incomplete: 0,
    fail: 0,
    passAndWarn: 0,
    noVerdict: 0,
    total: 0,
  };
};

const newTopJobStatsAggregate = (): JobStatsAggregate => {
  return {
    name: "",
    completed: 0,
    incomplete: 0,
    fail: 0,
    passAndWarn: 0,
    noVerdict: 0,
    total: 0,
  };
};
/** @returns map of machineId to status and verdict counts
 * This function is only exported for testing. Use the exposed Comlink channel to run this as a worker.
 */
export const getMachineJobAggregate = (
  jobs: JobPresentation[]
): JobAggregate => {
  return jobs.reduce<JobAggregate>((result, job) => {
    const verdictGroup = mapVerdictToGroup(job);
    const statusGroup = mapStatusToGroup(job);
    switch (verdictGroup) {
      case "Pass": {
        result.passAndWarn++;
        break;
      }
      case "Fail": {
        result.fail++;
        break;
      }
      case "No Verdict": {
        result.noVerdict++;
        break;
      }
      default: {
        assertUnreachable(verdictGroup);
      }
    }
    switch (statusGroup) {
      case "Completed": {
        result.completed++;
        result.total++;
        break;
      }
      case "Incomplete": {
        result.incomplete++;
        result.total++;
        break;
      }
      default: {
        assertUnreachable(statusGroup);
      }
    }
    return result;
  }, newJobAggregate());
};

export const getMachineTopJobAggregate = (
  jobs: JobPresentation[],
  top: number
) => {
  const machineJobNames: MappyAbsolute<JobStatsAggregate> = {};
  const groups = groupBy((job) => job.name, jobs);
  const jobNames = Object.keys(groups);
  for (const jobName of jobNames) {
    const jobsByName = Object.values(groups[jobName]);
    machineJobNames[jobName] = calculateAggregate(jobsByName);
  }
  const uniqueTopJobsList = orderBy(
    ["total"],
    ["desc"],
    Object.values(machineJobNames)
  );
  if (uniqueTopJobsList.length <= top) return uniqueTopJobsList;
  const otherAggregatedJobs: JobStatsAggregate = uniqueTopJobsList
    .slice(top)
    .reduce<JobStatsAggregate>((result, job) => {
      result.name = t`message-Other`;
      result.total += job.total;
      result.completed += job.completed;
      result.incomplete += job.incomplete;
      result.passAndWarn += job.passAndWarn;
      result.fail += job.fail;
      return result;
    }, newTopJobStatsAggregate());
  return [...uniqueTopJobsList.slice(0, top), otherAggregatedJobs];
};

function calculateAggregate(jobsByName: JobPresentation[]) {
  const result = newTopJobStatsAggregate();
  for (const job of jobsByName) {
    const verdictGroup = mapVerdictToGroup(job);
    const statusGroup = mapStatusToGroup(job);
    result.name = job.name;
    switch (verdictGroup) {
      case "Pass": {
        result.passAndWarn++;
        break;
      }
      case "Fail": {
        result.fail++;
        break;
      }
      case "No Verdict": {
        result.noVerdict++;
        break;
      }
    }
    switch (statusGroup) {
      case "Completed": {
        result.completed++;
        result.total++;
        break;
      }
      case "Incomplete": {
        result.incomplete++;
        result.total++;
        break;
      }
    }
  }
  return result;
}
const exposed = {
  getMachineJobAggregate,
  getMachineTopJobAggregate,
};
export type Exposed = typeof exposed;
expose(exposed);
