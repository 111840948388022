/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
export enum AlertLevel {
  critical = "Critical",
  error = "Error",
  errorGrouped = "Error (grouped)",
  warning = "Warning",
  warningGrouped = "Warning (grouped)",
  info = "Info",
  infoGrouped = "Info (grouped)",
  unknown = "Unknown",
  grouped = "grouped",
  mastering = "Mastering",
  masteringGrouped = "Mastering (grouped)",
  offset = "Offset",
  offsetError = "OffsetError",
  offsetWarning = "OffsetWarning",
  offsetGrouped = "Offset (grouped)",
  offsetErrorGrouped = "OffsetError (grouped)",
  offsetWarningGrouped = "OffsetWarning (grouped)",
  /** For subType-'ToolOffsetCalculation' updateMode-'Control' */
  offsetInfo = "OffsetInfo",
  offsetInfoGrouped = "OffsetInfo (grouped)",
  /** For subType-'ToolOffsetApplied' updateMode-'Set' */
  offsetOther = "OffsetOther",
  offsetOtherGrouped = "OffsetOther (grouped)",
  /** For subType 'ToolChangeDetected' & subType-'ToolOffsetCalculation', updateMode-'Set' */
  offsetOtherInfo = "OffsetOtherInfo",
  offsetOtherInfoGrouped = "OffsetOtherInfo (grouped)",
}

export const levelMapper = (level: string | undefined) => {
  try {
    switch (level!.toLowerCase()) {
      case "critical":
      case "error":
        return AlertLevel.error;
      case "error (grouped)":
      case "errorgrouped":
        return AlertLevel.errorGrouped;
      case "warning":
        return AlertLevel.warning;
      case "warning (grouped)":
      case "warninggrouped":
        return AlertLevel.warningGrouped;
      case "info":
        return AlertLevel.info;
      case "info (grouped)":
      case "infogrouped":
        return AlertLevel.infoGrouped;
      case "mastering":
      case "masteringupdate":
        return AlertLevel.mastering;
      case "masteringgrouped":
      case "mastering (grouped)":
        return AlertLevel.masteringGrouped;
      case "offset":
      case "ipctooloffsetupdate":
      case "tooloffsetapplied":
        return AlertLevel.offset;
      case "offseterror":
        return AlertLevel.offsetError;
      case "offsetwarning":
        return AlertLevel.offsetWarning;
      case "offset (grouped)":
      case "offsetgrouped":
        return AlertLevel.offsetGrouped;
      case "offseterrorgrouped":
      case "offseterror (grouped)":
        return AlertLevel.offsetErrorGrouped;
      case "offsetwarninggrouped":
      case "offsetwarning (grouped)":
        return AlertLevel.offsetWarningGrouped;
      // For subType-'ToolOffsetCalculation' updateMode-'Control'
      case "offsetinfo":
        return AlertLevel.offsetInfo;
      case "offsetinfogrouped":
      case "offsetinfo (grouped)":
        return AlertLevel.offsetInfoGrouped;
      // For subType-'ToolOffsetApplied' updateMode-'Set'
      case "offsetother":
        return AlertLevel.offsetOther;
      case "offsetother (grouped)":
        return AlertLevel.offsetOtherGrouped;
      // For subType 'ToolChangeDetected' & subType-'ToolOffsetCalculation', updateMode-'Set'
      case "offsetotherinfo":
        return AlertLevel.offsetOtherInfo;
      case "offsetotherinfo (grouped)":
        return AlertLevel.offsetOtherInfoGrouped;
      case "grouped":
        return AlertLevel.grouped;
      default:
        return AlertLevel.unknown;
    }
  } catch (error) {
    return AlertLevel.unknown;
  }
};

export const levelColours: Dictionary<string> = {
  [AlertLevel.error]: "#ff626d",
  [AlertLevel.errorGrouped]: "#ff626d",
  [AlertLevel.warning]: "#f93",
  [AlertLevel.warningGrouped]: "#f93",
  [AlertLevel.info]: "#84e3ff",
  [AlertLevel.infoGrouped]: "#84e3ff",
  [AlertLevel.unknown]: "#aaa",
  [AlertLevel.grouped]: "#E6E6E6",
  [AlertLevel.mastering]: "#F9EE80",
  [AlertLevel.masteringGrouped]: "#F9EE80",
  [AlertLevel.offset]: "#8EE43F",
  [AlertLevel.offsetGrouped]: "#8EE43F",
  [AlertLevel.offsetError]: "#ff626d",
  [AlertLevel.offsetErrorGrouped]: "#ff626d",
  [AlertLevel.offsetWarning]: "#f93",
  [AlertLevel.offsetWarningGrouped]: "#f93",
  // For subType-'ToolOffsetCalculation' updateMode-'Control'
  [AlertLevel.offsetInfo]: "#84e3ff",
  [AlertLevel.offsetInfoGrouped]: "#84e3ff",
  // For subType-'ToolOffsetApplied' updateMode-'Set'
  [AlertLevel.offsetOther]: "#8EE43F",
  [AlertLevel.offsetOtherGrouped]: "#8EE43F",
  // For subType 'ToolChangeDetected' & subType-'ToolOffsetCalculation', updateMode-'Set'
  [AlertLevel.offsetOtherInfo]: "#84e3ff",
  [AlertLevel.offsetOtherInfoGrouped]: "#84e3ff",
};

// Return list of verdicts for a verdict group
export const mapAlertGroupToLevels = (levelGroup: string): any => {
  switch (levelGroup) {
    case "Critical":
    case "Error": {
      return [AlertLevel.critical, AlertLevel.error];
    }
    case "Warning": {
      return [AlertLevel.warning];
    }
    case "Info": {
      return [AlertLevel.info];
    }
  }
};
